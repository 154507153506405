import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import _, { map } from "underscore";
import { API_CONFIGURATIONS } from "../apiConfigurations";
const { Map, default: Immutable } = require("immutable");

let baseUrl =API_CONFIGURATIONS.GET_PARENT_NAVIGATOR_TO_FUNCTIONAL_AREAS;
const updateUrl = API_CONFIGURATIONS.UPDATE_NAVIGATOR_TO_FUNCTIONAL_AREAS;
 
const apiEndpoint = baseUrl;
 
const accessToken = localStorage.getItem("userToken");

let config = {
  mode: "no-cors",
  headers: {
    "Access-Control-Allow-Origin": "http://localhost:3000",
    xAccessToken: accessToken,
  },
};

export const initialState = {
  navigatorToFunctionalAreas: [],
  status: "idle",
  error: null,
};

function pathForUpdate(ipath) {
  var path = ipath.toJS();

  var keys = _.range(path.length).map(() => "type");

  return _.flatten(_.zip(path, keys));
}

export const fetchNavigatorToFunctionalAreas = createAsyncThunk("navigatorToFunctionalAreas/fetchNavigatorToFunctionalAreas",async () => {
    try {
      const grantIdForFetch = JSON.parse(localStorage.getItem("grantIdForFetch-NFA"));
      const grantNodeId = grantIdForFetch?.grantNodeId;
      const url = `${apiEndpoint + "-2"}&grantNodeId=${grantNodeId}`;

      const response = await axios.get(url, config);

      localStorage.removeItem("grantIdForFetch-NFA");

      return response.data;
    } catch (error) {
      localStorage.removeItem("grantIdForFetchFunctionalAraes");

      return {
        error: true,
        message: error.response?.data || error.message,
      };
    }
  }
);

export const fetchMultiChildNodes = createAsyncThunk("navigatorToFunctionalAreas/fetchMultiChildNodes",async ({ expanded, record }) => {
    const childUrl = apiEndpoint + record.id; 
    const response = await axios.get(childUrl, config); 
    return { data: response.data, expanded: expanded, record: record };
  }
);

export const updateNavigatorToFunctionalAreas = createAsyncThunk("navigatorToFunctionalAreas/update",async ({ request, onCloseUpdateDialog }) => {
    const response = await axios.post(updateUrl, request, config);  
    
    onCloseUpdateDialog();
  }
); 

const navigatorToFunctionalAreasSlice = createSlice({
  name: "navigatorToFunctionalAreas",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchNavigatorToFunctionalAreas.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchNavigatorToFunctionalAreas.fulfilled, (state, action) => {
        state.status = "succeeded";
        const data = dataFormatter(action.payload);
        state.navigatorToFunctionalAreas =
          state.navigatorToFunctionalAreas.concat(data);
      })
      .addCase(fetchNavigatorToFunctionalAreas.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchMultiChildNodes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMultiChildNodes.fulfilled, (state, action) => {
        const { data, expanded, record } = action.payload;
        state.status = "succeeded";
        var path = pathForUpdate(Immutable.fromJS(record.path));

        var areaToUpdate = Immutable.fromJS(state.navigatorToFunctionalAreas);
        var updatedArea = areaToUpdate
          .updateIn(path, (node) => {
            return dataFormatter(data, record.path);
          })
          .toJS();
        state.navigatorToFunctionalAreas = updatedArea;
      })
      .addCase(fetchMultiChildNodes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateNavigatorToFunctionalAreas.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateNavigatorToFunctionalAreas.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateNavigatorToFunctionalAreas.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const dataFormatter = (areas, path) => {
  const formattedAreas = [];
  areas.forEach((area, idx) => {
     
    formattedAreas.push({
      id: area.id,
      label: area.label,
      projection: area.projection,
      type: area.type,
      roles: area.roles,
      consolidatedNodes: area.consolidatedNodes,
      child: area.child,
      expanded: false,
      path: path ? [...path, idx] : [idx],
      state: area.state,
      parentId: area.parentId,
    });
  });

  return formattedAreas;
};
 

export default navigatorToFunctionalAreasSlice.reducer;

export const selectAllNavigatorToFunctionalAreas = (state) => state.navigatorToFunctionalAreas.navigatorToFunctionalAreas;
