import { Stack, IconButton, Grid } from "@mui/material";
import { Delete, ContentCopy, ModeEdit } from "@mui/icons-material";
import DataTable from "./DataTable";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllEndUserRoles,
  fetchEndUserRoles,
  deleteEndUserRole,
  fetchAssignedUsers,
} from "../../../redux/reducers/endUserRoleSlice";
import moment from "moment";
import { fetchPermissionSetCategories } from "../../../redux/reducers/permissionSetCategorySlice";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import Swal from "sweetalert2";

const PermissionView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const endUserRoles = useSelector(selectAllEndUserRoles);
  const endUserRoleStatus = useSelector((state) => state.endUserRoles.status);
  const permissionSetCategoryStatus = useSelector(
    (state) => state.permissionSetCategories.status
  );
  const [deleteActive, setDeleteActive] = useState();

  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem("previousPath", currentPath);
  }, [location.pathname]);

  useEffect(() => {
    if (endUserRoleStatus === "idle") {
      dispatch(fetchEndUserRoles());
    }
    if (permissionSetCategoryStatus === "idle") {
      dispatch(fetchPermissionSetCategories());
    }
  }, [endUserRoleStatus, dispatch]);

  const deleteRole = (id) => {
    Swal.fire({
      title: "Delete End-User Roles",
      text: "Are you sure you want to delete this end-user role?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  const onDeleteConfirm = async (id) => {
    try {
      const endUserRolesAction = dispatch(fetchAssignedUsers(id));
      const endUserRolesAssigned = await endUserRolesAction;
      if (endUserRolesAssigned?.payload === true) {
        const result = await Swal.fire({
          title: "Delete End-User Roles",
          text: "The End-User Role has already been assigned to one or more Users. Are you sure you want to delete this End-User Role?",
          icon: "warning",
          iconColor: "red",
          showCancelButton: true,
          cancelButtonText: "CANCEL",
          confirmButtonText: "CONFIRM DELETION",
          confirmButtonColor: "red",
        });

        if (result.isConfirmed) {
          await dispatch(deleteEndUserRole({ id }));
          Swal.fire(
            "Deleted",
            "The End-User Role has been deleted.",
            "success"
          );
          dispatch(fetchEndUserRoles());
        } else {
          Swal.fire(
            "Deletion Cancelled",
            "The End-User Role was not deleted.",
            "info"
          );
          dispatch(fetchEndUserRoles());
        }
      } else {
        await dispatch(deleteEndUserRole({ id }));
        Swal.fire("Deleted", "The End-User Role has been deleted.", "success");
        dispatch(fetchEndUserRoles());
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        "Error",
        "An error occurred while processing the request.",
        "error"
      );
      dispatch(fetchEndUserRoles());
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 2 },
    { field: "description", headerName: "Description", flex: 3 },
    { field: "category", headerName: "Category", flex: 1 },
    {
      field: "dateCreated",
      headerName: "Created Date",
      align: "center",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (data) => moment(data.value).format("MM-DD-YYYY"),
    },
    {
      field: "lastModifiedDate",
      headerName: "Modified Date",
      align: "center",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (data) => moment(data.value).format("MM-DD-YYYY"),
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (data) => {
        return (
          <div>
            <Stack direction="row" spacing={1}>
              <Link
                className="custom-link"
                to={"/end-user-roles/duplicate/" + data.id}
              >
                <IconButton
                  id="5d49b0e0-0476-4b2d-bbd3-524db28ca061"
                  color="primary"
                  size="small"
                  sx={{ borderRadius: 1, border: "1px solid" }}
                  variant="outlined"
                >
                  <ContentCopy sx={{ fontSize: 16 }} />
                </IconButton>
              </Link>
              <Link className="custom-link" to={"/end-user-roles/" + data.id}>
                <IconButton
                  id="a9a3d1dc-075c-4a74-bd1d-e7842990dd07"
                  color="warning"
                  size="small"
                  sx={{ borderRadius: 1, border: "1px solid" }}
                  variant="outlined"
                >
                  <ModeEdit sx={{ fontSize: 16 }} />
                </IconButton>
              </Link>
              <IconButton
                id="10f2c1d1-413d-4800-8ab7-3cd02c05de7e"
                onClick={() => deleteRole(data.id)}
                color="error"
                size="small"
                sx={{ borderRadius: 1, border: "1px solid" }}
              >
                <Delete sx={{ fontSize: 16 }} />
              </IconButton>
            </Stack>
          </div>
        );
      },
    },
  ];

  return (
    <Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
        <h2 className="page-title">End-User Roles</h2>
      </Grid>
      <DataTable
        id="e3075d47-a9b5-43be-828b-9e9274a7f01b"
        columns={columns}
        data={endUserRoles}
        loading={endUserRoleStatus}
      />
    </Grid>
  );
};

export default PermissionView;
