import { Search, FilterAlt, Close as CloseIcon } from "@mui/icons-material";
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Autocomplete,
  Chip,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const DataTable = ({ columns, data, loading }) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({
    filterTextAreaConflicts: "",
  });
  const [advancedFilter, setAdvancedFilter] = useState({});
  // Function to calculate the page size based on available height
  const calculatePageSize = () => {
    const rowHeight = 52; // typical row height for DataGrid
    const headerHeight = 56; // height of DataGrid header
    const searchBarHeight = 64; // height of the search bar and button row
    const padding = 143; // extra padding
    const availableHeight =
      window.innerHeight - headerHeight - searchBarHeight - padding;
    return Math.floor(availableHeight / rowHeight);
  };

  const getFilteredData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    if (
      filter?.filterTextAreaConflicts &&
      filter?.filterTextAreaConflicts !== ""
    ) {
      const filterTextAreaConflicts =
        filter.filterTextAreaConflicts.toLowerCase();
      data = data?.filter((v) =>
        v.name.toLowerCase().includes(filterTextAreaConflicts)
      );
    }

    return data;
  };

  const filteredData = getFilteredData(data);

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const clear = () => {
    setFilter({ filterTextAreaConflicts: "" });
    setAdvancedFilter({});
  };

  const onFilterUpdate = (type, value) => {
    switch (type) {
      case "filterTextAreaConflicts": {
        setFilter({ ...filter, filterTextAreaConflicts: value });
        return;
      }
      default: {
        console.log("No type defined");
      }
    }
  };

  const onClickFilter = (filter) => {
    getFilteredData(data);
  };

  useEffect(() => {
    const handleResize = () => {
      setPageSize(calculatePageSize());
    };

    // Set initial page size
    handleResize();

    // Adjust page size on window resize
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
            size="small"
            fullWidth
            value={filter.filterTextAreaConflicts}
            label="Search by Name"
            onChange={(e) =>
              onFilterUpdate("filterTextAreaConflicts", e.target.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "#000000" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}></Grid>

        <Grid item lg={2}>
          <Button
            onClick={() => clear()}
            fullWidth
            disableElevation
            variant="outlined"
            startIcon={<FilterAlt />}
          >
            Clear Filter
          </Button>
        </Grid>

        <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
          <Link className="custom-link" to={"/functional-area-conflict/new"}>
            <Button
              fullWidth
              disableElevation
              variant="contained"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 0, height: "calc(100vh - 200px)" }}>
        <DataGrid
          columns={columns}
          rows={filteredData}
          checkboxSelection={false}
          hideFooterPagination
          loading={loading === "loading"}
          getRowId={(row) => row.name}
          disableColumnMenu
          headerHeight={56}
          rowHeight={52}
          sx={{
            border: "none",
          }}
        />
      </Box>
    </div>
  );
};
export default DataTable;
