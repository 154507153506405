import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import swal from "sweetalert2";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.APPS10_CONVERSION;
const apiEndpoint = baseUrl;
const syncUrl = API_CONFIGURATIONS.APPS10_DATA_PUSH_TO_CLOUD;
const apps10LogUrl = API_CONFIGURATIONS.EXISTING_APPS10_DATA_LOG;
const cloudLogUrl = API_CONFIGURATIONS.EXISTING_CLOUD_DATA_LOG;

const accessToken = localStorage.getItem("userToken");

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const initialState = {
    app10Conversion: [],
    cloudLog: [],
    status: 'idle',
    error: null
}

export const fetchApp10Conversions = createAsyncThunk('app10Conversion/fetchApp10Conversions', async() => {

    const response = await axios.get(apiEndpoint, config)
    return response.data;
})

export const syncDataOnCloud = createAsyncThunk('app10Conversion/syncDataOnCloud', async() => {

    const response = await axios.get(syncUrl, config)
    return response.data;
})

export const syncSelectedDataOnCloud = createAsyncThunk('app10Conversion/syncSelectedDataOnCloud', async({ pressObjects,setSelectedRows}) => {  
    const response = await axios.post(apiEndpoint, pressObjects, config); 
    setSelectedRows([]);
    return response.data;
})

export const fetchExistingApps10DataLog = createAsyncThunk('app10Conversion/fetchExistingApps10DataLog', async() => {
    const response = await axios.get(apps10LogUrl, config);
    return response.data;
})

export const fetchExistingCloudDataLog = createAsyncThunk('app10Conversion/fetchExistingCloudDataLog', async() => {
    const response = await axios.get(cloudLogUrl, config);
    return response.data;
})


const app10ConversionSlice = createSlice({
    name: "app10Conversion",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(fetchApp10Conversions.pending, (state) => {
            state.status = "loading";
          })
          .addCase(fetchApp10Conversions.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.app10Conversion = action.payload;
          })
          .addCase(fetchApp10Conversions.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
          })
          .addCase(syncDataOnCloud.pending, (state) => {
            state.status = "loading";
          })
          .addCase(syncDataOnCloud.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.cloudLog = action.payload;
          })
          .addCase(syncDataOnCloud.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
          })
          .addCase(syncSelectedDataOnCloud.pending, (state) => {
            state.status = "loading";
          })
          .addCase(syncSelectedDataOnCloud.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.cloudLog = action.payload;
          })
          .addCase(syncSelectedDataOnCloud.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
          })
          .addCase(fetchExistingApps10DataLog.pending, (state) => {
            state.status = "loading";
          })
          .addCase(fetchExistingApps10DataLog.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.app10Conversion = action.payload;
          })
          .addCase(fetchExistingApps10DataLog.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
          })
          .addCase(fetchExistingCloudDataLog.pending, (state) => {
            state.status = "loading";
          })
          .addCase(fetchExistingCloudDataLog.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.cloudLog = action.payload;
          })
          .addCase(fetchExistingCloudDataLog.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
          });
    }
});

export default app10ConversionSlice.reducer

export const selectAllApp10Conversions = state => state.app10Conversion.app10Conversion;
export const selectAllCloudLog = state => state.app10Conversion.cloudLog;
export const selectApp10ConversionStatus = (state) => state.app10Conversion.status;