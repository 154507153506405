import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useState } from "react";

const UpdateConfirmationDialog = ({
  updatedData,
  closeDialog,
  onCancelUpdateDialog,
  onSave, 
  onClick,
}) => {
  const { navigatorEntryTitle, role, action} = updatedData;

  const onCloseDialog = () => {
    closeDialog();
  };

  const onCancelDialog = () => {
    onCancelUpdateDialog();
  };

  return (
    <Dialog
      id="561ad2f6-be26-4d93-a8dd-5b67d158e48d"
      open={updatedData.openConfirmation === true}
      //maxWidth={"xs"}
      fullWidth
      onClose={onCloseDialog}
      justifyContent="center"
      alignItems="center"
    >
      {(action === "add" || action === "remove") && (
        <>
          <DialogTitle>
            {action === "add" ? "Granting access, Are you sure?" : "Removing access, Are you sure?"}
          </DialogTitle>

          <DialogContent>
            <Grid
              item
              md={12}
              sx={{ marginBottom: 5 }}
              justifyContent="center"
              alignItems="center"
            >
              <Chip
                label={ (action === "add" ? "To: " : "From: ") + navigatorEntryTitle }
                variant="outlined"
                sx={{ borderRadius: 2 }}
              />
              <Chip
                label={role}
                color={action === "add" ? "success" : "error"}
                sx={{ borderRadius: 2, marginLeft: 2 }}
              />
            </Grid> 
          </DialogContent>

          {action === "add" && (
            <DialogActions>
              <DialogActions>
                <Button id="bb925568-a8b4-437f-a92d-3bc850972057" variant="outlined" onClick={() => onCancelDialog()}>
                  Cancel
                </Button>
                <Button
                  //disabled={action === "add"}
                  variant="contained"
                  onClick={() => onSave()}
                >
                  Save
                </Button>
              </DialogActions>
            </DialogActions>
          )}

          {action === "remove" && (
            <DialogActions>
              <DialogActions>
                <Button id="a8cc0178-7979-4ceb-b52b-4f353aa33a82" variant="outlined" onClick={() => onCancelDialog()}>
                  Cancel
                </Button>
                <Button
                  // disabled={action === "add"}
                  id="b1be7285-a76c-4b53-ac00-d974f48b5a8d"
                  variant="contained"
                  color="error"
                  onClick={() => onSave()}
                >
                  Remove
                </Button>
              </DialogActions>
            </DialogActions>
          )}
        </>
      )}

      {action === "select" && (
        <DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item md={2} textAlign="center">
                <Button
                  id="4f057f5e-80ad-4b41-9413-c5d0ef994686"
                  variant="outlined"
                  fullWidth
                  onClick={() => onCancelDialog()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item md={5} textAlign="center">
                <Button
                  // disabled={action === "add" }
                  id="779bd8fc-8521-44ed-a054-945b2c96171a"
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={() => onClick( updatedData.data, updatedData.functionalArea, "add", 10 )
                  }
                >
                  Granting Access
                </Button>
              </Grid>
              <Grid item md={5} textAlign="center">
                <Button
                  // disabled={action === "add" }
                  id="d100cd5a-036e-40be-ae1a-2bccf59342e0"
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={() => onClick( updatedData.data, updatedData.functionalArea, "remove",  1 ) }
                >
                  Removing Access
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default UpdateConfirmationDialog;
