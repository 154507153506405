import {
  Alert,
  Autocomplete,
  Button,
  Grid,
  LinearProgress,
  Snackbar,
  Stack,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchFunctionalAreas,
  addNewFunctionalArea,
  updateFunctionalArea,
  deleteFunctionalArea,
  selectFunctionalAreaByName,
  selectAllFunctionalAreas,
} from "../../../redux/reducers/functionalAreasSlice";
import {
  fetchFunctionalAreaConflicts,
  addNewFunctionalAreaConflict,
  updateFunctionalAreaConflict,
  deleteFunctionalAreaConflict,
  selectFunctionalAreaConflictByName,
  selectAllFunctionalAreaConflicts,
  selectFunctionalAreaConflictsById,
  fetchAssignedConflicts,
} from "../../../redux/reducers/functionalAreaConflictSlice";

const ConflictUpdate = () => {
  const { areaName, action } = useParams(); 
  const [areaData, setAreaData] = useState({});  
  const [snakebarOps, setSnakebarOps] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const duplicateArea = useSelector((state) =>selectFunctionalAreaConflictByName(state, areaName));
  const functionalAreaConflictsStatus = useSelector(state => state.functionalAreaConflicts.status);
  const functionalAreas = useSelector(selectAllFunctionalAreas);
  const functionalAreaStatus = useSelector(state => state.functionalAreas.status);

  const coflictTypes = ["Not Allowed", "Warning"];

  useEffect(() => {
    if (functionalAreaConflictsStatus === "idle") {
      dispatch(fetchFunctionalAreaConflicts());
    }
    if (functionalAreaStatus === "idle") {
      dispatch(fetchFunctionalAreas());
    }
    if (action === "duplicate" && duplicateArea) {
       
      const data = {
        ...duplicateArea,
        name: duplicateArea.name + "_COPY",
      };
      setAreaData(data);
    } else if (areaName && areaName !== "new") {
      setAreaData(duplicateArea);
    }
  }, [areaName, functionalAreaConflictsStatus,functionalAreaStatus, dispatch, action, duplicateArea]); 

  useEffect(() => {
    const isDisabled = !areaData?.name || !areaData?.functionalArea || !areaData?.conflictArea || !areaData?.conflictType 
    setIsSaveDisabled(isDisabled);
  }, [areaData]);

  const handleChange = (field, value) => {
    const updatedValue = {};
    updatedValue[field] = value;

    switch (field) {
      case "name":
        setAreaData({
          ...areaData,
          ...updatedValue,
        });
        return;
      case "functionalArea":
        setAreaData({
          ...areaData,
          ...updatedValue,
        });
        return;
      case "conflictArea":
        setAreaData({
          ...areaData,
          ...updatedValue,
        });
        return;
      case "conflictType":
        setAreaData({
          ...areaData,
          ...updatedValue,
        });
        return;
      default:
        console.log("No case defined");
        return;
    }
  };

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const onSubmit = () => {
     
    return areaName === "new"
      ? createFunctionalAreaConflict()
      : action === "duplicate"
      ? createFunctionalAreaConflict()
      : updateOldFunctionalAreaConflict();
  };

  const createFunctionalAreaConflict = async () => {
    const newArea = [
      {
        name: areaData.name,
        functionalArea: areaData.functionalArea,
        conflictArea: areaData.conflictArea,
        conflictType: areaData.conflictType,
      },
    ];
 
    dispatch(addNewFunctionalAreaConflict({ data: newArea, showStatus: setSnakebarOps })).then((response) => {
      if (response.type === "functionalAreaConflicts/addNewFunctionalAreaConflict/fulfilled") {
        navigate(-1);
      }
    });
  };

  const updateOldFunctionalAreaConflict = async () => {
    const updatedArea = [
      {
        name: areaData.name,
        functionalArea: areaData.functionalArea,
        conflictArea: areaData.conflictArea,
        conflictType: areaData.conflictType,
      },
    ];

    dispatch(updateFunctionalAreaConflict({ data: updatedArea, showStatus: setSnakebarOps })).then((response) => {
      if (response.type === "functionalAreaConflicts/updateFunctionalAreaConflict/fulfilled") {
         
        navigate(-1);
      }
    });
  };

  return (
    <div>
      <Snackbar
        open={snakebarOps.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        severity={snakebarOps.severity}
        autoHideDuration={2000}
        onClose={() => setSnakebarOps({ open: false })}
      >
        <Alert
          onClose={() => setSnakebarOps({ open: false })}
          severity={snakebarOps.severity}
          sx={{ width: "100%" }}
        >
          {snakebarOps.message}
        </Alert>
      </Snackbar>
      {functionalAreaConflictsStatus === "loading" ? (
        <LinearProgress sx={{ m: 50 }} color="inherit" />
      ) : (
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
            <h2 className="page-title">
              {areaName === "new" ? "Create New" : action === "duplicate"  ? "Duplicate" : "Edit"}{" "} Conflict Area
            </h2>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} gutter={4}>
            <Stack spacing={2}>
              <TextField
                required={true}
                value={areaData?.name || ""}
                fullWidth
                size="small"
                label="Name" 
                name="Name"
                onChange={(e) => handleChange("name", e.target.value)}
              />
              <Autocomplete
                  required={true}
                  value={areaData?.functionalArea || ""}
                  id="functionalArea"
                  size="small"
                  //disableClearable
                  disabled={areaName !== "new"}
                  options={functionalAreas?.map((area) => area.name)}
                  onChange={(event, value) => handleChange("functionalArea", value)}
                  renderInput={(params) => (
                    <TextField 
                      {...params}
                      label="Functional Area"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
                <Autocomplete
                required={true}
                  value={areaData?.conflictArea || ""}
                  id="conflictArea"
                  size="small"
                  //disableClearable
                  disabled={areaName !== "new" || (areaData?.functionalArea ? false : true)}
                  options={functionalAreas?.filter((v)=>v.name != areaData?.functionalArea).map((area) => area.name)}
                  onChange={(event, value) => handleChange("conflictArea", value)}
                  renderInput={(params) => (
                    <TextField 
                      {...params}
                      label="Conflict Area"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
                <Autocomplete
                required={true}
                  value={areaData?.conflictType || ""}
                  id="conflictType"
                  size="small"
                  //disableClearable
                  options={coflictTypes}
                  onChange={(event, value) => handleChange("conflictType", value)}
                  renderInput={(params) => (
                    <TextField 
                      {...params}
                      label="Conflict Type"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                /> 
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() => navigate(-1)}
                  sx={{ flex: 1 }}
                  disableElevation
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{ flex: 1 }}
                  disableElevation
                  onClick={() => onSubmit()}
                  disabled={isSaveDisabled}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ConflictUpdate;
