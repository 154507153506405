import { Stack, IconButton, Grid, Card, Button, Tabs, Tab, Box, Dialog, DialogContent, DialogActions ,LinearProgress, Backdrop, Typography} from "@mui/material";
import { ModeEdit, ContentCopy, Delete, Close as CloseIcon } from '@mui/icons-material';
import Apps10DataTable from "./Apps10DataTable";
import CloudDataTable from "./CloudDataTable"
import Apps10PermissionSetDataTable from "./Apps10PermissionSetDataTable";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchApp10Conversions,syncDataOnCloud,syncSelectedDataOnCloud, selectAllApp10Conversions,selectAllCloudLog, selectApp10ConversionStatus } from "../../../redux/reducers/app10ConversionSlice";
import SignIn from './Apps10ToAurenaSignIn';
import {fetchPermissionSetCategories,selectAllPermissionSetCategories} from "../../../redux/reducers/permissionSetCategorySlice";
import { selectAllFunctionalPermissionSets, fetchFunctionalPermissionSets, deleteFunctionalPermissionSet,fetchAssignEndUserRoles } from "../../../redux/reducers/functionalPermissionSetSlice";
import { selectAllEndUserRoles, fetchEndUserRoles, deleteEndUserRole,fetchAssignedUsers } from "../../../redux/reducers/endUserRoleSlice";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Apps10ToAurenaView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const app10Conversions = useSelector(selectAllApp10Conversions);
    const permissionSetCategories = useSelector(selectAllPermissionSetCategories);
    const permissionSetCategoryStatus = useSelector((state) => state.permissionSetCategories.status);
    const functionalPermissionSets = useSelector(selectAllFunctionalPermissionSets);
    const functionalPermissionSetStatus = useSelector(state => state.functionalPermissionSets.status);
    const endUserRoles = useSelector(selectAllEndUserRoles);
    const endUserRoleStatus = useSelector((state) => state.endUserRoles.status);
    const cloudLogs = useSelector(selectAllCloudLog);
    const app10ConversionStatus = useSelector(state => state.app10Conversion.status);
    const [openSignInDialog, setOpenSignInDialog] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem("previousPath", currentPath);
    }, [location.pathname]);

    useEffect(() => {
        if (app10ConversionStatus === 'idle') {
            // Temporarily comented ---
            //dispatch(fetchApp10Conversions());
        }
        if (permissionSetCategoryStatus === "idle") {
            dispatch(fetchPermissionSetCategories());
        }
        if (functionalPermissionSetStatus === 'idle') {
            dispatch(fetchFunctionalPermissionSets())
        }
        if (endUserRoleStatus === "idle") {
          dispatch(fetchEndUserRoles());
        }
    }, [app10ConversionStatus,permissionSetCategoryStatus,functionalPermissionSetStatus,endUserRoleStatus, dispatch]);

     const handleStartConversion = async () => {
        setIsProcessing(true);
        setProgress(0);
 
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    return 100; 
                }
                return prev + (100 / 480);  
            });
        }, 1000);  
 
        try {
            await dispatch(fetchApp10Conversions());
        } catch (error) {
            console.error("Error during conversion:", error);
        } finally {
            clearInterval(interval);  
            setIsProcessing(false);  
            setProgress(100);  
        }
    };

    const handlePushData = () => { 
        // Temporarily commented ---
        //dispatch(syncDataOnCloud());   
        dispatch(syncSelectedDataOnCloud({pressObjects:selectedRows,setSelectedRows}));  
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
        setOpenSignInDialog(false);
    };

    const apps10Columns = [
      { field: "role", headerName: "Permission Set", flex: 2 },
      { field: "roleType", headerName: "Permission Set Type", flex: 2 },
      { field: "pressObject", headerName: "Presentation Object", flex: 2 },
      { field: "projection", headerName: "Projection", flex: 2 },
      { field: "grantType", headerName: "Grant Type", flex: 2 },
      { field: "category", headerName: "Permission Set Category", flex: 2 },
      { field: "status", headerName: "Status", flex: 2 },
    ];

    const cloudColumns = [
      { field: "role", headerName: "Permission Set", flex: 2 },
      { field: "roleDescription", headerName: "Permission Set Status", flex: 2 },
      { field: "projection", headerName: "Projection", flex: 2 },
      { field: "status", headerName: "Grant Status", flex: 2 },
    ];

    const apps10PermissionSetsColumns = [
    { field: "role", headerName: "Permission Set", flex: 1 },
    { field: "roleType", headerName: "Permission Set Type", flex: 1 },
    { field: "roleDescription", headerName: "Permission Set Description", flex: 2 },
    { field: "category", headerName: "Permission Set Category", flex: 1 },
    { 
        field: "rate", 
        headerName: "Conversion Success Rate", 
        flex: 1,
        valueGetter: (params) => parseFloat(params.value), 
        sortComparator: (v1, v2) => v1 - v2,
        renderCell: (params) => {
            const percentage = parseFloat(params.value);
 
            let barColor;
            if (percentage === 100) {
                barColor = 'green';
            } else if (percentage >= 50 && percentage < 100) {
                barColor = 'yellow';
            } else if (percentage >= 1 && percentage < 50) {
                barColor = 'orange';
            } else {
                barColor = 'red';
            }

            return (
                <div style={{ width: '100%', position: 'relative', height: '20px', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
                    <div 
                        style={{
                            width: `${percentage}%`,
                            backgroundColor: barColor,
                            height: '100%',
                            borderRadius: '5px'
                        }}
                    />
                    <span style={{ position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)', fontSize: '12px', color: 'black' }}>
                        {`${percentage}%`}
                    </span>
                </div>
            );
        }
    },
];


    return (
        <Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
                <h2 className="page-title">APPS10 To Aurena Conversion</h2>
            </Grid>
            <br></br>

            <Card 
                sx={{ 
                    backgroundColor: "#dbbcf5", 
                    padding: "24px",
                    borderRadius: "12px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",

                }}
            >
                <Grid container spacing={3} alignItems="center">

                    <Grid item lg={2} md={4} sm={12} xs={12}>
                        <Button
                            id="b7424abe-78d3-4c29-92a1-10e38924b5f2"
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: "#1976d2",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                padding: "12px",
                                borderRadius: "8px",
                                textTransform: "none",
                                transition: "background-color 0.3s",
                                "&:hover": {
                                    backgroundColor: "#2c62b8",
                                }
                            }}
                            onClick={() => setOpenSignInDialog(true)}
                        >
                            Cloud Login
                        </Button>
                        
                        <Dialog id="4ecbbb79-e01a-43cd-bf8b-db22644ec434" open={openSignInDialog} onClose={() => setOpenSignInDialog(false)}>
                            <DialogContent>
                            <DialogActions>
                                <IconButton
                                aria-label="close"
                                size="small"
                                onClick={() => setOpenSignInDialog(false)}
                                sx={{
                                    size: 'small',
                                    position: 'absolute',
                                    right: 6,
                                    top: 6,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                                >
                                <CloseIcon />
                                </IconButton>
                            </DialogActions>
                                <SignIn  onLoginSuccess={handleLoginSuccess}/>
                            </DialogContent>
                        </Dialog>             
                    </Grid> 
                    <Grid item lg={3} md={4} sm={12} xs={12}>
                        <Button 
                            hidden={!(localStorage.getItem("conversionUserToken"))}
                            fullWidth
                            variant="outlined" 
                            style={{ 
                                textTransform: "none", 
                                cursor: "default"  
                            }}
                        >
                            Logged in to - {localStorage.getItem("conversionAppUrl")}
                        </Button>

                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12}></Grid>
                    <Grid item lg={2} md={4} sm={12} xs={12}>
                        <Button
                            id="47604104-fa16-411b-b085-81c3d231581b"
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: "#8a54ba",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                padding: "12px",
                                borderRadius: "8px",
                                textTransform: "none",
                                transition: "background-color 0.3s",
                                "&:hover": {
                                    backgroundColor: "#672a9c",
                                },
                                "&:disabled": {
                                    backgroundColor: "#cccccc",
                                    color: "#666666",
                                }
                            }}
                            onClick={handleStartConversion}
                            disabled={!isLoggedIn && !(localStorage.getItem("conversionUserToken"))}
                        >
                            Start Conversion
                        </Button>
                    </Grid>
                    <Grid item lg={2} md={4} sm={12} xs={12}>
                        <Button
                            id="76be0914-709a-4f8e-8ab4-2588ca9a3421"
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: "#8a54ba",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                padding: "12px",
                                borderRadius: "8px",
                                textTransform: "none",
                                transition: "background-color 0.3s",
                                "&:hover": {
                                    backgroundColor: "#672a9c",
                                },
                                "&:disabled": {
                                    backgroundColor: "#cccccc",
                                    color: "#666666",
                                }
                            }}
                            onClick={handlePushData}
                            disabled={!isLoggedIn && !(localStorage.getItem("conversionUserToken"))}
                        >
                            Import to Cloud
                        </Button>
                    </Grid>
                </Grid>
            </Card> 

            {isProcessing && (
                <div style={{ marginTop: '20px' }}>
                    <Typography variant="h6">Processing... Please wait.</Typography>
                    <LinearProgress 
                        variant="determinate" 
                        value={progress} 
                        sx={{
                            height: '10px',  
                            borderRadius: '5px',  
                            backgroundColor: '#e0e0e0',  
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: '#81c784',  
                            },
                        }} 
                    />
                </div>
            )}

            <br></br>
            <br></br>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="conversion tabs">
                    <Tab label="Apps10 Permission Sets" sx={{
                        fontWeight: activeTab === 0 ? 'bold' : 'normal',
                        backgroundColor: activeTab === 0 ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                        borderRadius: '4px',  
                        }} />
                    <Tab label="Apps10 Data Log" />
                    <Tab label="Cloud Data Log" /> 
                </Tabs>
            </Box>
            <TabPanel value={activeTab} index={0}>
                <Apps10PermissionSetDataTable columns={apps10PermissionSetsColumns} data={app10Conversions} loading={app10ConversionStatus} setSelectedRows={setSelectedRows} isLoggedIn={isLoggedIn} permissionSetCategories={permissionSetCategories} functionalPermissionSets={functionalPermissionSets} endUserRoles={endUserRoles}/>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
                <Apps10DataTable columns={apps10Columns} data={app10Conversions} loading={app10ConversionStatus} setSelectedRows={setSelectedRows} isLoggedIn={isLoggedIn} permissionSetCategories={permissionSetCategories} functionalPermissionSets={functionalPermissionSets} endUserRoles={endUserRoles}/>
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
                <CloudDataTable columns={cloudColumns} data={cloudLogs} loading={app10ConversionStatus} isLoggedIn={isLoggedIn}/>
            </TabPanel>
        </Grid>
    );
}

export default Apps10ToAurenaView;
