import { Grid, TextField, Button, InputAdornment,LinearProgress,Typography, InputLabel,Card ,CircularProgress  } from "@mui/material"
import DataTable from "./DataTable";
import { selectAllUsers, fetchUsers } from "../../../../redux/reducers/userSlice";
import { fetchUsersToEndUserRoleMapping, selectAllUsersToEndUserRole, updateMappingMatrix } from "../../../../redux/reducers/usersToEndUserRoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Search, ExitToApp } from "@mui/icons-material";
import ExcelJS from "exceljs"; 

const UserListView = () => {
    const dispatch = useDispatch();
    const users = useSelector(selectAllUsers);
    const usersToEndUserRoles = useSelector(selectAllUsersToEndUserRole);
    const usersStatus = useSelector(state => state.users.status);
    const usersToEndUserRoleStatus = useSelector(state => state.usersToEndUserRole.status);
    const [loading, setLoading] = useState(false);
    
    console.log("Users ", users)
 
    const columns = [
      { field: "userId", headerName: "UserName" },
      { field: "firstName", headerName: "First Name" },
      { field: "lastName", headerName: "Last Name" },
      { field: "name", headerName: "Complete Name" },
      //{ field: "company", headerName: "Company" },
      //{ field: "siteOfUser", headerName: "Site of User" },
      { field: "userCreationDate", headerName: "User Creation Date" },
      //{ field: "deactivationDate", headerName: "User Deactivation Date" },
      //{ field: "lastLoginDate", headerName: "Last Login Date" },
      { field: "inactive", headerName: "Status" },
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); 
            await Promise.all([
                dispatch(fetchUsers()),
                dispatch(fetchUsersToEndUserRoleMapping())
            ]);
            setLoading(false); 
        };

        if (usersStatus === "idle" || usersToEndUserRoleStatus === "idle") {
            fetchData();
        }
    }, [usersStatus, usersToEndUserRoleStatus, dispatch]);
 

    const [filter, setFilter] = useState('');
    const [exporting, setExporting] = useState(false);
    const [exportingUserRoles, setExportingUserRoles] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [progressValueUR, setProgressValueUR] = useState(0);
 
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const simulateAsyncOperation = async () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(); 
            }, 2000);
        });
    };

    const getAllUserData = async() => {
        setExporting(true);
        setProgressValue(10);  
        
        let progress = 10;
            while (progress < 100) {
                await simulateAsyncOperation();
                progress += 10; 
                setProgressValue(progress);
            }
        handleOnExportUsers();    
        setProgressValue(100);
        setExporting(false);    
     
    }; 

    const getAllUserDataWithRoles = async() => {
        setExportingUserRoles(true);
        setProgressValueUR(10);  
        
        let progress = 10;
            while (progress < 100) {
                await simulateAsyncOperation();
                progress += 10; 
                setProgressValueUR(progress);
            }
        handleOnExportUserRoles();    
        setProgressValueUR(100);
        setExportingUserRoles(false);    
     
    };  

    const handleOnExportUsers = async () => {
     const workbook = new ExcelJS.Workbook();
     const sheet = workbook.addWorksheet("User List");
 
     const headers = columns.map((column) => column.headerName);
     const headerRow = sheet.addRow(headers);
 
     headerRow.eachCell((cell) => {
       cell.font = { bold: true };
       cell.fill = {
         type: "pattern",
         pattern: "solid",
         fgColor: { argb: "FFEDD2FA" },  
       };
       cell.alignment = { horizontal: "center", vertical: "middle" };
       cell.border = {
         top: { style: "thin" },
         left: { style: "thin" },
         bottom: { style: "thin" },
         right: { style: "thin" },
       };
     }); 

     users.forEach((userData) => {
       const rowData = columns.map((column) => {
         const field = column.field;
         return userData[field] !== undefined ? userData[field] : "";
       });
       const row = sheet.addRow(rowData);
 
       row.eachCell((cell) => {
         cell.border = {
           top: { style: "thin" },
           left: { style: "thin" },
           bottom: { style: "thin" },
           right: { style: "thin" },
         };
         cell.fill = {
           type: "pattern",
           pattern: "solid",
           fgColor: {
             argb: row.number % 2 === 0 ? "FFE0E0E0" : "FFFFFF",
           },
         };
       });
     });
 
     sheet.columns.forEach((col, colIndex) => {
       let maxLength = col.values
         .slice(1)  
         .reduce((max, curr) => Math.max(max, curr?.toString().length || 0), 0);
       maxLength = Math.max(maxLength, headers[colIndex]?.length || 0);  
       col.width = maxLength + 2; 
     });
 
     const buffer = await workbook.xlsx.writeBuffer();
     const blob = new Blob([buffer], {
       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
     });
     const url = URL.createObjectURL(blob);
 
     const link = document.createElement("a");
     link.href = url;
     link.download = "User_List.xlsx";
     link.click();
    };

    const handleOnExportUserRoles = async () => {
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("User with End-User Roles");
      
          const headers = columns.map((column) => column.headerName);
          headers.push("End-User role");
          const headerRow = sheet.addRow(headers);
      
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFEDD2FA" }, 
            };
            cell.alignment = { horizontal: "center", vertical: "middle" };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
      
          let currentColor = "FFE0E0E0";  
          let previousUserId = null;

          users.forEach((userData) => {
            const matchingGrant = usersToEndUserRoles.find(
              (grant) => grant.user === userData.userId
            );

            if (matchingGrant) {
              matchingGrant.endUserPermissionSets.forEach((permissionSet) => {
                const rowData = columns.map((column) => {
                  const field = column.field;
                  return userData[field] !== undefined ? userData[field] : "";
                });

                rowData.push(permissionSet.name);
                const row = sheet.addRow(rowData);
      
                if (userData.userId !== previousUserId) {
                  
                  currentColor = currentColor === "FFE0E0E0" ? "FFFFFF" : "FFE0E0E0";
                  previousUserId = userData.userId;
                }

                row.eachCell((cell) => {
                  cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: currentColor },
                  };
                  cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                  };
                });
              });
            } else {
              const rowData = columns.map((column) => {
                const field = column.field;
                return userData[field] !== undefined ? userData[field] : "";
              });

              rowData.push("");
              const row = sheet.addRow(rowData);
      
              if (userData.userId !== previousUserId) {
                currentColor = currentColor === "FFE0E0E0" ? "FFFFFF" : "FFE0E0E0";
                previousUserId = userData.userId;
              }

              row.eachCell((cell) => {
                cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: currentColor },
                };
                cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
              });
            }
          });
      
        sheet.columns.forEach((col, colIndex) => {
          let maxLength = col.values
            .slice(1)  
            .reduce((max, curr) => Math.max(max, curr?.toString().length || 0), 0);
          maxLength = Math.max(maxLength, headers[colIndex]?.length || 0);  
          col.width = maxLength + 2;  
        });
      
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
      
        const link = document.createElement("a");
        link.href = url;
        link.download = "User_List_End-User_Roles.xlsx";
        link.click();
    };

   return (
     <Grid>
       <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
         <h2 className="page-title">User Management and Permissions </h2>
       </Grid>
       <br />
       <br />
       <Card sx={{ backgroundColor: "#FFEFD5", padding: "16px" }}>
         <Grid container spacing={2}>
           <Grid item lg={3} md={8} sm={12} xs={12}>
             <InputLabel sx={{ color: "black", textAlign: "left" }}>
               ✔ User list
             </InputLabel>
           </Grid>
           <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
             <Button
                id="ada24ccf-e12c-4914-9e03-abdc5b51abdb"
                fullWidth
                onClick={() => {
                  getAllUserData(); 
               }}
               disableElevation
               variant="contained"
               startIcon={<ExitToApp />}
             >
               Export
             </Button>
           </Grid>
           <Grid item lg={7} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
             {exporting && (
               <div style={{ width: "100%", marginTop: "20px", height: "20%" }}>
                 <LinearProgress
                   id="91527922-b6d3-449f-bc3c-d5ff45414386"
                   variant="determinate"
                   value={progressValue > 100 ? 100 : progressValue}
                   sx={{ height: 8 }}
                 />
                 <Typography variant="body2">
                    {`${parseInt(progressValue > 100 ? 100 : progressValue + "")}%`}
                </Typography>
               </div>
             )}
           </Grid>
         </Grid>
       </Card>
       <br />
       <Card sx={{ backgroundColor: "#FFEFD5", padding: "16px" }}>
         <Grid container spacing={2}>
           <Grid item lg={3} md={8} sm={12} xs={12}>
             <InputLabel sx={{ color: "black", textAlign: "left" }}>
               ✔ User list with End-User Roles
             </InputLabel>
           </Grid>
           <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
             <Button
               id="abdbf247-6e6a-44d4-b0b0-fb6512994f7c"
               fullWidth
               onClick={() => {
                 getAllUserDataWithRoles(); 
               }}
               disableElevation
               variant="contained"
               startIcon={<ExitToApp />}
             >
               Export
             </Button>
           </Grid>
           <Grid item lg={7} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
             {exportingUserRoles && (
               <div style={{ width: "100%", marginTop: "20px", height: "20%" }}>
                 <LinearProgress
                   id="bda2c209-2b10-4ef5-9033-c326bde90f70"
                   variant="determinate"
                   value={progressValueUR > 100 ? 100 : progressValueUR}
                   sx={{ height: 8 }}
                 />
                 <Typography variant="body2">
                    {`${parseInt(progressValueUR > 100 ? 100 : progressValueUR + "" )}%`}
                </Typography>
               </div>
             )}
           </Grid>
         </Grid>
       </Card>
       {loading && (
         <div
           style={{
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
             height: "10vh",
           }}
         >
           <CircularProgress />
         </div>
       )}
     </Grid>
   );

}

export default UserListView;