import {
  Grid,
  Button,
  Autocomplete,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
} from "@mui/material";
import { Search, FilterAlt, Close as CloseIcon} from "@mui/icons-material";
import { useEffect, useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import Legend from "./Legend";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material';


const searchOptions = [
  { value: "navigatorEntries", label: "Navigator Entries" },
  { value: "functionalPermissions", label: "Functional Permission Set" },
];

const AdvancedFilter = ({
  onFilter,
  permissionSetCategories = [],
  onClearFilter,
  advancedFilter,
  isDirectNavigation,
  filter,
  setFilter,
}) => {
  const { type, name } = useParams();
  // const [filter, setFilter] = useState(() => {
  //   const storedFilter = JSON.parse(localStorage.getItem("filter")) || {
  //     filterTextPermissions: "",
  //     filterTextNavigators: "",
  //     activePermissionMappings: false,
  //     activeNavigatorMappings: false,
  //     functionalCat: [],
  //   };
  //   return storedFilter;
  // });

  const [isLegendOpen, setIsLegendOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("filter", JSON.stringify(filter));
  }, [filter]);

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const clear = () => {
    setFilter({
      filterTextPermissions: "",
      filterTextNavigators: "",
      activePermissionMappings: false,
      activeNavigatorMappings: false,
      functionalCat: [],
    });
    onClearFilter();
  };

  const onFilterUpdate = (type, value) => {
    switch (type) {
      case "filterTextPermissions":
      case "filterTextNavigators":
      case "activePermissionMappings":
      case "activeNavigatorMappings":
      case "functionalCat": {
        setFilter({ ...filter, [type]: value });
        return;
      }
      default: {
        console.log("No type defined");
      }
    }
  };

  const handleDeleteFunctionalCat = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      functionalCat: [],
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={4}>
        <TextField
          id="813a6c16-7326-4e55-9ce4-fd14b220ea09"
          size="small"
          fullWidth
          value={filter.filterTextPermissions}
          label={"Search by Functional Permission Sets"}
          onChange={(e) =>
            onFilterUpdate("filterTextPermissions", e.target.value)
          }
          onInput={toInputUppercase}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "#000000" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {/* <Grid item lg={3.5}>
        <FormControl fullWidth size="small">
          <InputLabel id="func-cat-label">
            Functional Permission Sets Category
          </InputLabel>
          <Select
            //multiple
            fullWidth
            label="Functional Permission Sets Category"
            labelId="func-cat-label"
            input={
              <OutlinedInput label="Functional Permission Sets Category" />
            }
            value={filter.functionalCat}
            style={{ textAlign: "left" }}
            onChange={(e) => onFilterUpdate("functionalCat", e.target.value)}
            //disabled={filter.filterType === searchOptions[0]?.label}
          >
            {permissionSetCategories &&
              permissionSetCategories.map((category) => (
                <MenuItem key={category.name} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid> */}

      <Grid item lg={3.5}>
        <FormControl fullWidth size="small">
          <Autocomplete
            id="4d40c661-dd9a-4356-8696-62f2315ca1fe"
            size="small"
            options={permissionSetCategories.map((category) => category.name)}
            value={filter.functionalCat}
            onChange={(event, newValue) => {
              onFilterUpdate("functionalCat", newValue);
            }}
            renderTags={(value, getTagProps) =>
              value && (
                <Chip
                  label={value}
                  {...getTagProps({ index: 0 })}
                  deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                  onDelete={handleDeleteFunctionalCat}
                />
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Functional Permission Sets Category"
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item lg={4.5} style={{ textAlign: "left" }}>
        <FormControlLabel
          id="fbb0d3af-3feb-43d5-9163-875935893666"
          onChange={(e) =>
            onFilterUpdate("activePermissionMappings", e.target.checked)
          }
          size="small"
          control={<Switch checked={filter.activePermissionMappings} />}
          label="Active mappings for Functional Permission Sets"
        />
      </Grid>

      <Grid item lg={4}>
        <TextField
          id="5435ddf0-2341-4663-8af7-ed1a4cc7d10e"
          size="small"
          fullWidth
          value={filter.filterTextNavigators}
          label={"Search by Navigator Entries"}
          onChange={(e) =>
            onFilterUpdate("filterTextNavigators", e.target.value)
          }
          onInput={toInputUppercase}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "#000000" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item lg={3.5}></Grid>
      <Grid item lg={4.5} style={{ textAlign: "left" }}>
        <FormControlLabel
          id="889a2df5-b597-4aa0-aec5-b86264cc16a9"
          onChange={(e) =>
            onFilterUpdate("activeNavigatorMappings", e.target.checked)
          }
          size="small"
          control={<Switch checked={filter.activeNavigatorMappings} />}
          label="Active mappings for Navigator Entries"
        />
      </Grid>
      <Grid item lg={6}></Grid>
      <Dialog
        id="96847794-d2e1-4af2-97e6-0974cdf594e2"  
        justifyContent="center"
        alignItems="center"
        open={isLegendOpen} 
        onClose={() => setIsLegendOpen(false)}>

          <DialogActions>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => setIsLegendOpen(false)}
              sx={{
                size: 'small',
                position: 'absolute',
                right: 6,
                top: 6,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>

          <DialogContent>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Legend />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogTitle sx={{ textAlign: "center" }}/>
      </Dialog>

      <Grid item container spacing={12} justifyContent="space-between" alignItems="center">
        <Grid item lg={2} md={2} sm={2} xs={2}>
        <Button
          id="2fc659d8-f5ca-4644-936e-97bea992e10d"
          fullWidth
          disableElevation
          variant="outlined"
          color="secondary"
          onClick={() => {
            setIsLegendOpen(true);
          }}>
          Legend
        </Button>
      </Grid>

      <Grid item>
        <Grid container spacing={2}>
        <Grid item>
        <Button
          id="f132d87d-a2b2-4960-9c7d-08f366e338b1"
          onClick={() => clear()}
          fullWidth
          disableElevation
          variant="outlined"
          startIcon={<FilterAlt />}
        >
          Clear Filter
        </Button>
      </Grid>

      <Grid item>
        <Button
          id="13133673-28a0-4911-85c1-ced891de902d"
          onClick={() => onFilter(filter)}
          fullWidth
          disableElevation
          variant="contained"
          startIcon={<FilterAlt />}
        >
          Apply Filter
        </Button>
      </Grid>
    </Grid>
    </Grid>
  </Grid>
</Grid>
  );
};

export default AdvancedFilter;
