import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
} from "@mui/material"; 
import logo from "../../../static/images/logo-colored.png";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; 
import { conversionUserLogin } from "../../../redux/reducers/authUserSlice";
import { useState, useEffect } from "react";

const SignIn = ({ onLoginSuccess }) => {
  const { userInfo } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    conversionUsername: "",
    conversionPassword: "",
    conversionAppUrl: "",
    conversionAppVersion: "CLOUD",
  });

  const [error, setError] = useState(""); 

  const onSignIn = async () => {
    try {
      const result = await dispatch(conversionUserLogin(formData)).unwrap(); 

      if (result.token) {
        onLoginSuccess();   
      }
    } catch (err) {
      setError(
        "Invalid credentials. Please enter the correct username, password, and application URL to successfully connect to the IFS environment."
      );
      console.error("Login failed:", err); 
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem("previousPath", currentPath);
  }, [location.pathname]);

  useEffect(() => {
    if (userInfo) {
      navigate("/apps10-to-aurena");
    }
  }, [navigate, userInfo]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Stack
          alignItems="center"
          spacing={2}
          mx={"5vw"}
          height="60vh"
          justifyContent="center"
        >
          <Box mb={2}>
            <img src={logo} height="50px" alt="Logo" />
          </Box>
          <TextField
            onChange={(e) =>
              setFormData({ ...formData, conversionAppUrl: e.target.value })
            }
            sx={{ marginTop: 2 }}
            value={formData.conversionAppUrl}
            placeholder="Application URL"
            variant="outlined"
            label="Application URL"
            size="small"
            fullWidth
            error={!!error}
          />
          <TextField
            onChange={(e) =>
              setFormData({ ...formData, conversionUsername: e.target.value })
            }
            sx={{ marginTop: 2 }}
            variant="outlined"
            label="Username"
            size="small"
            fullWidth
            error={!!error}
          />
          <TextField
            onChange={(e) =>
              setFormData({ ...formData, conversionPassword: e.target.value })
            }
            sx={{ marginTop: 2 }}
            variant="outlined"
            label="Password"
            type="password"
            size="small"
            fullWidth
            error={!!error}
            helperText={error} 
          />
          <Button
            onClick={onSignIn}
            variant="contained"
            fullWidth
            size="medium"
            sx={{ marginTop: 2 }}
          >
            Sign In
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SignIn;
