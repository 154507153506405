import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from 'axios';
import swal from "sweetalert2";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.FUNCTIONAL_AREA_CONFLICTS;
let conflictUrl = API_CONFIGURATIONS.ASSIGNED_CONFLICTS;
let segUrl = API_CONFIGURATIONS.SEG_DUTIES;
const apiEndpoint = baseUrl;
const accessToken = localStorage.getItem("userToken");

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const initialState = {
    functionalAreaConflicts: [],
    status: 'idle',
    error: null
}

export const fetchFunctionalAreaConflicts = createAsyncThunk('functionalAreaConflicts/fetchFunctionalAreaConflicts', async() => {
    const response = await axios.get(apiEndpoint, config)
    return response.data;
})

export const addNewFunctionalAreaConflict = createAsyncThunk('functionalAreaConflicts/addNewFunctionalAreaConflict', async({ data, showStatus }) => {
    try {

        const response = await axios.post(apiEndpoint, data, config);
        if (response.status === 200) {
            showStatus({ open: true, severity: "success", message: "Functional Area Conflict added successfully" })
            return response.data;
        } else {
            showStatus({ open: true, severity: "error", message: "Functional Area Conflict adding failed" })
        }
    } catch (error) {
        swal.fire({
            title: 'This functional area conflict already exists',
            text: "The functional area conflict you're trying to create already exists in the system. Please choose a different name for your functional area conflict.",
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d33',
        });

        throw error;
    }
});

export const updateFunctionalAreaConflict = createAsyncThunk('functionalAreaConflicts/updateFunctionalAreaConflict', async({ data, showStatus }) => {
    try {
        const response = await axios.put(apiEndpoint, data, config);
        if (response.status === 200) {
            showStatus({ open: true, severity: "success", message: "Functional Area Conflict updated successfully" })
            return response.data;
        } else {
            showStatus({ open: true, severity: "error", message: "Functional Area Conflict update failed" })
        }
    } catch (error) {
        swal.fire({
            title: 'This functional area conflict already exists',
            text: "The functional area conflict you're trying to create already exists in the system. Please choose a different name for your functional area conflict.",
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d33',
        });

        throw error;
    }
})

export const deleteFunctionalAreaConflict = createAsyncThunk('functionalAreaConflicts/deleteFunctionalAreaConflict', async({ id, showStatus }) => {
   const response = await axios.delete(`${apiEndpoint}/?functionalAreaId=${id.row.functionalArea}&conflictAreaId=${id.row.conflictArea}`,config);
    if (response.status === 200) {
        showStatus({ open: true, severity: "success", message: "Fuctional Area Conflict deleted successfully" })
    } else {
        showStatus({ open: true, severity: "error", message: "Fuctional Area Conflict delete failed" })
    }
    return response.data;
})

export const fetchAssignedConflicts = createAsyncThunk('functionalAreaConflicts/fetchAssignedConflicts', async(record) => {
    const childUrl = conflictUrl + record;
    const response = await axios.get(childUrl, config);

    return response.data;
})

export const fetchSegregationOfDutiesUserAnalysisReportData = createAsyncThunk("functionalAreaConflicts/fetchSegregationOfDutiesUserAnalysisReportData", async () => {
   
    const response = await axios.get(segUrl, config);
   
  return response.data;
});

export const selectFunctionalAreaConflictByName = (state, areaName) => {
    const area = state.functionalAreaConflicts.functionalAreaConflicts.find(area => area.name === areaName);
     
    return area
}

const functionalAreaConflictSlice = createSlice({
    name: 'functionalAreaConflicts',
    initialState,
    reducers: {
        addFunctionalAreaConflicts: {
            reducer(state, action) {
                state.functionalAreaConflicts.push(action.payload);
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFunctionalAreaConflicts.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchFunctionalAreaConflicts.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.functionalAreaConflicts = action.payload
            })
            .addCase(fetchFunctionalAreaConflicts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addNewFunctionalAreaConflict.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(addNewFunctionalAreaConflict.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.functionalAreaConflicts = state.functionalAreaConflicts
                    .concat(...action.payload)
                    .sort((a, b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
            })
            .addCase(addNewFunctionalAreaConflict.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateFunctionalAreaConflict.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateFunctionalAreaConflict.fulfilled, (state, action) => {
                
                state.status = 'succeeded'
                 
                const areas = [...current(state.functionalAreaConflicts)];
                const index = areas.findIndex(perm => (perm.functionalArea === action.payload[0].functionalArea) && (perm.conflictArea === action.payload[0].conflictArea));
                areas[index] = action.payload[0];
                state.functionalAreaConflicts = areas;
            })
            .addCase(updateFunctionalAreaConflict.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deleteFunctionalAreaConflict.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(deleteFunctionalAreaConflict.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(deleteFunctionalAreaConflict.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchAssignedConflicts.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchAssignedConflicts.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(fetchAssignedConflicts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchSegregationOfDutiesUserAnalysisReportData.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchSegregationOfDutiesUserAnalysisReportData.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(fetchSegregationOfDutiesUserAnalysisReportData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
});

export default functionalAreaConflictSlice.reducer;

export const selectAllFunctionalAreaConflicts = state => state.functionalAreaConflicts.functionalAreaConflicts;

export const selectFunctionalAreaConflictsById = (state, id) => state.functionalAreaConflicts.functionalAreaConflicts.find(functionalArea => functionalArea.id === id);