import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  CircularProgress,
  LinearProgress,
  Typography,
  Card,
  Checkbox,
} from "@mui/material";
import {
  selectAllUsers,
  fetchUsers,
} from "../../../../redux/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Search, ExitToApp } from "@mui/icons-material";
import {
  fetchNavigatorToFunctionalPermissionSets,
  selectAllNavigatorToFunctionalPermissionSets,
  fetchNavigatorHierarchy,
  fetchOmitedNavigatorHierarchy,
} from "../../../../redux/reducers/navigatorToFunctionalPermissionSetsMultiSlice";
import {
  fetchFunctionalPermissionSets,
  selectAllFunctionalPermissionSets,
} from "../../../../redux/reducers/functionalPermissionSetSlice";
import ExcelJS from "exceljs";
var XLSX = require("xlsx");

const PermissionListView = () => {
  const dispatch = useDispatch();
  const navigatorToFunctionalPermissionSetsStatus = useSelector(
    (state) => state.navigatorToFunctionalPermissionSetsMulti.status
  );
  const navigatorToFunctionalPermissionSets = useSelector(
    selectAllNavigatorToFunctionalPermissionSets
  );
  const functionalPermissionSetStatus = useSelector(
    (state) => state.functionalPermissionSets.status
  );
  const functionalPermissionSets = useSelector(
    selectAllFunctionalPermissionSets
  );

  const [navigatorId, setNavigatorId] = useState("");
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
   const [omitNonGrantedAreas, setOmitNonGrantedAreas] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(fetchNavigatorToFunctionalPermissionSets()),
        dispatch(fetchFunctionalPermissionSets()),
      ]);
      setLoading(false);
    };

    if (
      navigatorToFunctionalPermissionSetsStatus === "idle" ||
      functionalPermissionSetStatus === "idle"
    ) {
      fetchData();
    }
  }, [
    navigatorToFunctionalPermissionSetsStatus,
    functionalPermissionSetStatus,
    dispatch,
  ]);

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const simulateAsyncOperation = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  const getAllNavDataHierarchy = (record) => {
    setExporting(true);
    setProgressValue(10);
    if (omitNonGrantedAreas) {
          dispatch(fetchOmitedNavigatorHierarchy(record)).then(async (res) => {
            const navchildData = res.payload;

            let progress = 10;
            while (progress < 100) {
              await simulateAsyncOperation();
              progress += 10;
              setProgressValue(progress);
            }

            handleOnExport(navchildData);

            setProgressValue(100);
            setExporting(false);
          });
    }else{
          dispatch(fetchNavigatorHierarchy(record)).then(async (res) => {
            const navchildData = res.payload;

            let progress = 10;
            while (progress < 100) {
              await simulateAsyncOperation();
              progress += 10;
              setProgressValue(progress);
            }

            handleOnExport(navchildData);

            setProgressValue(100);
            setExporting(false);
          });
          }
    
  };

  //With all styles
  const handleOnExport = async (navData) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Navigator to FPS Report");

    const dataRows = [];
    const colorData = []; // Array to store color information

    // Palette of colors for different levels
    const levelColors = [
      "FFEDD2FA", // Light Pinkish Purple
      "FFDBA3FA", // Pink Shade 1 (brighter pink)
      "FFE8C5FF", // Pink Shade 2 (soft lavender)
      "FFD7C8F9", // Light Purple 1 (medium purple)
      "FFC5A8FA", // Light Purple 2 (brighter lavender)
      "FFEABFFF", // Light Pinkish Purple (slightly bolder)
      "FFBFA1FB", // Stronger Purple
      "FFD59FFF", // Light Purple Pink Mix (higher contrast)
    ];

    const processItem = (item, level = 0) => {
      const row = [item.label];
      const colors = []; // Array to store colors for this row

      functionalPermissionSets.forEach((permissionSet) => {
        const state = item?.state?.find(
          (stateItem) =>
            stateItem?.role === permissionSet.name &&
            (stateItem?.state === "1" || stateItem?.state === "0")
        );

        let grantType = "";
        let grantColor = "";

        if (state) {
          grantType =
            state.state === "0" ? "◉" : state.state === "1" ? "✓" : "";
          grantColor =
            state.stateColor === "F"
              ? "RW"
              : state.stateColor === "R"
              ? "R"
              : "";
        }

        row.push(grantType); // Push grantType to the row
        colors.push(grantColor); // Store grantColor in the colors array
      });

      dataRows.push({ level, row });
      colorData.push(colors); // Store colors for this row

      if (item.child && item.child.length > 0) {
        item.child.forEach((childItem) => {
          processItem(childItem, level + 1);
        });
      }
    };

    navData.forEach((item) => processItem(item));

    // Set title cell
    const titleCell = sheet.getCell("A1");
    titleCell.value =
      "Permission Matrix - Navigator to Functional Permission Sets";
    titleCell.alignment = {
      horizontal: "left",
      vertical: "middle",
    };
    titleCell.font = {
      size: 10,
      bold: true,
      name: "Arial",
      color: { argb: "FF000000" },
    };

    // Set header row
    const headerRow = sheet.addRow([
      "Navigator Structure",
      ...functionalPermissionSets.map((set) => set.name),
    ]);
    headerRow.height = 200;

    const firstHeaderCell = headerRow.getCell(1);
    firstHeaderCell.alignment = {
      vertical: "top",
      horizontal: "left",
    };
    firstHeaderCell.font = {
      bold: true,
    };
    firstHeaderCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE0E0E0" },
    };
    firstHeaderCell.border = {
      top: { style: "thin", color: { argb: "FF000000" } },
      bottom: { style: "thin", color: { argb: "FF000000" } },
      left: { style: "thin", color: { argb: "FF000000" } },
      right: { style: "thin", color: { argb: "FF000000" } },
    };

    // Set the remaining header cells
    for (let i = 2; i <= functionalPermissionSets.length + 1; i++) {
      const headerCell = headerRow.getCell(i);
      headerCell.alignment = {
        textRotation: 90,
        vertical: "bottom",
        horizontal: "center",
      };
      sheet.getColumn(i).width = 5;
      headerCell.font = {
        bold: false,
      };
    }

    // Add data rows with indentation and dynamic color coding for hierarchical levels
    dataRows.forEach(({ level, row }, rowIndex) => {
      const indentedRow = row.map((cell, index) => {
        if (index === 0) {
          return " ".repeat(level * 4) + cell; // Simulate the expand-collapse hierarchy with spaces
        }
        return cell;
      });

      const excelRow = sheet.addRow(indentedRow);

      // Apply the dynamic purple-pink mix color based on the level
      const rowColor = levelColors[level % levelColors.length]; // Rotate through the colors

      excelRow.eachCell((cell, colNumber) => {
        if (colNumber === 1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: rowColor },
          };
        }
      });

      // Collapse/expand grouping based on levels
      if (level > 0) {
        sheet.getRow(rowIndex + 3).outlineLevel = level;
        sheet.getRow(rowIndex + 3).hidden = level > 0; // Initially collapse all except level 0
      }
    });

    // Add borders between levels
    sheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: { style: "thin" },
        };
      });
    });

    // Apply icons and background colors
    for (let r = 3; r <= dataRows.length + 2; r++) {
      for (let c = 1; c <= functionalPermissionSets.length; c++) {
        const cellRef = sheet.getCell(r, c + 1);
        const grantType = dataRows[r - 3].row[c];
        const grantColor = colorData[r - 3][c - 1]; // Get the color for this cell

        if (grantType === "◉" || grantType === "✓") {
          cellRef.value = grantType;
          cellRef.alignment = { horizontal: "center", vertical: "middle" };
          cellRef.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: grantColor === "R" ? "FF4461D4" : "FF000000", // Use the color logic you specified
            },
          };
          cellRef.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
          cellRef.font = {
            color: { argb: "FFFFFFFF" },
          };
        }
      }
    }

    // Enable grouping and collapse/expand in Excel
    sheet.properties.outlineLevelRow = 2; // Allow collapse/expand to level 2
    sheet.getColumn(1).width = 60;

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    // Trigger download
    const link = document.createElement("a");
    link.href = url;
    link.download = "Navigator_to_Functional_Permission_Sets.xlsx";
    link.click();
  };


  return (
    <Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
        <h2 className="page-title">
          Navigator to Functional Permission Sets Reports.
        </h2>
      </Grid>
      <br></br>
      <br></br>
      <Grid container spacing={2}> 
        <Grid item lg={4} sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            id="e69738ba-7a00-492a-8173-729da7ff81e5"
            name="omitNonGrantedAreas"
            onChange={(e) => {
              setOmitNonGrantedAreas(e.target.checked);
            }}
          />
          <InputLabel sx={{ color: "black", textAlign: "left", marginLeft: 1 }}>
            Omit Non-Granted Areas
          </InputLabel>
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <Card sx={{ backgroundColor: "#D3D3D3", padding: "16px" }}>
        <Grid container spacing={2}>
          {/* <Grid item lg={4}>
                      <FormControl fullWidth size='small'>
                          <InputLabel id="func-cat-label">Reports by Navigator Entries</InputLabel>
                          <Select
                              //multiple
                              fullWidth
                              label="Reports by Navigator Entries"
                              labelId="func-cat-label"
                              input={<OutlinedInput label="Reports by Navigator Entries" />} 
                              style={{ textAlign: 'left' }}
                              onChange={(e) => {
                                setNavigatorId(e.target.value) 
                              }} 
                          >
                              {navigatorToFunctionalPermissionSets && navigatorToFunctionalPermissionSets.map((category) => (
                                  <MenuItem
                                      key={category.label}
                                      value={category.id}
                                  >
                                      {category.label}
                                  </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                  </Grid>  */}
          <Grid item lg={4} md={8} sm={12} xs={12}>
            <InputLabel sx={{ color: "black", textAlign: "left" }}>
              ✔ Navigator to Functional Permission Sets
            </InputLabel>
          </Grid>
          <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
            <Button
              id="1d999bb3-1882-420f-8c5d-1340cc486662"
              fullWidth
              onClick={() => {
                getAllNavDataHierarchy();
              }}
              disableElevation
              variant="contained"
              startIcon={<ExitToApp />}
            >
              Export
            </Button>
          </Grid>
          <Grid item lg={6} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
            {exporting && (
              <div style={{ width: "100%", marginTop: "20px", height: "20%" }}>
                <LinearProgress
                  id="bb81742e-79d3-42d3-a7fc-f4fdfe513d4d"
                  variant="determinate"
                  value={progressValue > 100 ? 100 : progressValue}
                  sx={{ height: 8 }}
                />
                <Typography variant="body2">{`${parseInt(
                  progressValue > 100 ? 100 : progressValue + ""
                )}%`}</Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Grid>
  );
};

export default PermissionListView;
