import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, TextField, InputAdornment, Button, Chip, Autocomplete, FormControl } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { Search, Close as CloseIcon } from "@mui/icons-material";
import { fetchExistingApps10DataLog } from "../../../redux/reducers/app10ConversionSlice";
import RoleModal from "./RoleModal";  

const Apps10PermissionSetDataTable = ({ columns, data = [], loading, setSelectedRows, isLoggedIn, permissionSetCategories, functionalPermissionSets, endUserRoles }) => {
    const [filter, setFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState(''); 
    const [selectAll, setSelectAll] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalData, setModalData] = useState([]);

    const dispatch = useDispatch();

    const getFilteredData = (data, filter, categoryFilter) => {
        let filteredData = data;

        const uniqueRolesAndTypes = new Map();
        filteredData = filteredData.filter(item => {
            const key = `${item.role}-${item.roleType}`;
            if (uniqueRolesAndTypes.has(key)) {
                return false;
            }
            uniqueRolesAndTypes.set(key, true);
            return true;
        });

        const roleCount = {};
        const conversionSuccessCount = {};

        data.forEach(item => {
            const key = `${item.role}-${item.roleType}`;
            if (!roleCount[key]) roleCount[key] = 0;
            if (!conversionSuccessCount[key]) conversionSuccessCount[key] = 0;
            roleCount[key]++;
            if (item.status === 'CONVERSION_SUCCESS') {
                conversionSuccessCount[key]++;
            }
        });

        filteredData = filteredData.map(item => {
            const key = `${item.role}-${item.roleType}`;
            const totalRecords = roleCount[key];
            const successfulRecords = conversionSuccessCount[key];
            const successRate = totalRecords > 0 ? (successfulRecords / totalRecords) * 100 : 0;
            return {
                ...item,
                rate: successRate.toFixed(2)
            };
        });

        filteredData = filteredData.map(item => {
            const funRole = functionalPermissionSets;
            const endRole = endUserRoles;
            const combinedRoles = [...funRole, ...endRole];
  
            const category = combinedRoles.find((role) => role.name === item.role)?.category;
   
            return {
              ...item,
              category: category,
            };
        });

        if (filter && filter.length > 0) {
            filteredData = filteredData.filter(item => 
                item.role && item.role.toLowerCase().includes(filter.toLowerCase())
            );
        }

        if (categoryFilter && categoryFilter.length > 0) {  
           filteredData = filteredData.filter((role) => role.category === categoryFilter); 
        }
 
        return filteredData;
    };

    const filteredData = getFilteredData(data, filter, categoryFilter);

    const selectableRowIds = data.filter(row => row.status === 'CONVERSION_SUCCESS').map(row => row.id);

    const handleSelectionChange = (newSelectionModel) => {
        const selectedRows = data.filter(row => newSelectionModel.includes(row.id)).filter(row => row.status === 'CONVERSION_SUCCESS');
        const selectedKeys = new Set(selectedRows.map(row => `${row.role}-${row.roleType}`));
        const updatedSelection = data.filter(row => selectedKeys.has(`${row.role}-${row.roleType}`)).filter(row => row.status === 'CONVERSION_SUCCESS');
        setSelectionModel(newSelectionModel);
        setSelectedRows(updatedSelection);
    };

    const handleSelectAllClick = () => {
        if (selectAll) {
            setSelectionModel([]);
            setSelectedRows([]);
        } else {
            const allSelectableIds = data.filter(row => row.status === 'CONVERSION_SUCCESS').map(row => row.id);
            setSelectionModel(allSelectableIds);
            setSelectedRows(data.filter(row => row.status === 'CONVERSION_SUCCESS'));
        }
        setSelectAll(!selectAll);
    };

    const isRowSelectable = (params) => {
        return params.row.rate > 0;
    };

    const handleRowClick = (params) => {
        const selectedRole = params.row.role;
        const roleData = data.filter(item => item.role === selectedRole && item.status === 'CONVERSION_SUCCESS');
        setModalData(roleData);
        setModalTitle(selectedRole);
        setOpenModal(true);
    };

    const handleExistingApps10DataLog = () => {
        dispatch(fetchExistingApps10DataLog());
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item lg={6} md={2} sm={12} xs={12}>
                    <TextField
                        id="458a3fc3-14aa-4702-89f3-a94838fe40cc"
                        size="small"
                        fullWidth
                        label="Search by Permission Set"
                        onChange={(e) => setFilter(e.target.value)}
                        onInput={e => e.target.value = e.target.value.toUpperCase()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search sx={{ color: "#000000" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormControl fullWidth size="small">
                        <Autocomplete
                            id="5c550df8-3c65-4ecf-b391-bc3447096e0d"
                            size="small"
                            options={permissionSetCategories.map((category) => category.name)}
                            value={categoryFilter}
                            onChange={(event, newValue) => setCategoryFilter(newValue)}
                            renderTags={(value, getTagProps) =>
                                value && (
                                    <Chip
                                        label={value}
                                        {...getTagProps({ index: 0 })}
                                        deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                                    />
                                )
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search by Category"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                    <Button
                        onClick={() => handleExistingApps10DataLog()}
                        fullWidth
                        disableElevation
                        variant="outlined"
                        disabled={!isLoggedIn && !(localStorage.getItem("conversionUserToken"))}
                    >
                        Load Previous Conversion Data
                    </Button>
                </Grid>
            </Grid>

            <DataGrid
                columns={columns}
                rows={filteredData}
                sx={{ marginTop: 1 }}
                autoHeight
                checkboxSelection
                pageSize={10}
                loading={loading === "loading"}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5]}
                onSelectionModelChange={handleSelectionChange}
                isRowSelectable={isRowSelectable}
                selectionModel={selectionModel}
                onSelectionCheckboxChange={handleSelectAllClick}
                onRowClick={handleRowClick}
            />

            <RoleModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                title={modalTitle}
                data={modalData}
            />
        </div>
    );
};

export default Apps10PermissionSetDataTable;
