import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.AUTH_USER;
let conversionBaseUrl = API_CONFIGURATIONS.CONVERSION_AUTH_USER;

const apiEndpoint = baseUrl;
const conversionApiEndpoint = conversionBaseUrl;

let config = {
    mode: "no-cors",
    headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000",
    },
};

export const userLogin = createAsyncThunk(
    "user/login",
    async({ username, password, applicationUrl, appVersion, authType }, { rejectWithValue }) => {
        try {

            localStorage.clear();

            const { data } = await axios.post(
                apiEndpoint, { username, password, appUrl: applicationUrl, appVersion, authType },
                config
            );
            if (data.token) {
                localStorage.setItem("userToken", data.token);
                localStorage.setItem("applicationUrl", applicationUrl);
                localStorage.setItem("appVersion", appVersion);
                axios.interceptors.request.use(
                    function(config) {
                        config.headers.xAccessToken = data.token;
                        return config;
                    },
                    function(error) {
                        return Promise.reject(error);
                    }
                );
                return data;
            } else {
                return rejectWithValue(data.error);
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const conversionUserLogin = createAsyncThunk(
    "user/conversionLogin",
    async({ conversionUsername, conversionPassword, conversionAppUrl, conversionAppVersion }, { rejectWithValue }) => {

        try {
            const { data } = await axios.post(
                conversionApiEndpoint, { conversionUsername: conversionUsername, conversionPassword: conversionPassword, conversionAppUrl: conversionAppUrl, conversionAppVersion: conversionAppVersion },
                config
            );
            if (data.token) {
                localStorage.setItem("conversionUserToken", data.token);
                localStorage.setItem("conversionAppUrl", conversionAppUrl);
                localStorage.setItem("conversionAppVersion", conversionAppVersion);
                axios.interceptors.request.use(
                    function(config) {
                        config.headers.xCloudAccessToken = data.token;
                        return config;
                    },
                    function(error) {
                        return Promise.reject(error);
                    }
                );
                 
                return data;
            } else {
                 
                return rejectWithValue(data.error);
            }
        } catch (error) {
             
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

const userToken = localStorage.getItem("userToken") || null;
const conversionUserToken = localStorage.getItem("conversionUserToken") || null;

export const initialState = {
    loading: false,
    userInfo: null,
    userToken,
    conversionUserInfo: null,
    conversionUserToken,
    error: null,
    success: false,
};

const authUserSlice = createSlice({
    name: "authUser",
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem("userToken");
            localStorage.removeItem("conversionUserToken");
            localStorage.removeItem("applicationUrl");
            localStorage.removeItem("appVersion");
            localStorage.removeItem("userCount");
            localStorage.removeItem("conversionAppUrl");
            localStorage.removeItem("conversionAppVersion");
            state.loading = false;
            state.userInfo = null;
            state.userToken = null;
            state.conversionUserInfo = null;
            state.conversionUserToken = null;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userInfo = payload;
                state.userToken = payload.token;
            })
            .addCase(userLogin.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(conversionUserLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(conversionUserLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.conversionUserInfo = payload;
                state.conversionUserToken = payload.token;
            })
            .addCase(conversionUserLogin.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });
    },
});

export const { logout } = authUserSlice.actions;
export default authUserSlice.reducer;