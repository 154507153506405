import { Grid, Button } from "@mui/material";
import { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchNavigatorToFunctionalAreas,
  selectAllNavigatorToFunctionalAreas,
  fetchMultiChildNodes,
  updateNavigatorToFunctionalAreas,
} from "../../../redux/reducers/navigatorToFunctionalAreasSlice";
import DataTable from "./DataTable";
import Legend from "./Legend"; 
import {
  fetchFunctionalAreas, 
  selectAllFunctionalAreas, 
} from "../../../redux/reducers/functionalAreasSlice";
import UpdateConfirmationDialog from "./UpdateConfirmationDialog";
import AdvancedFilter from "./AdvancedFilter";  

 
const NavigatorToFunctionalAreas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = useParams();
  const navigatorToFunctionalAreasStatus = useSelector((state) => state.navigatorToFunctionalAreas.status);
  const navigatorToFunctionalAreas = useSelector( selectAllNavigatorToFunctionalAreas );
  const functionalAreasStatus = useSelector( (state) => state.functionalAreas.status );
  const functionalAreas = useSelector(selectAllFunctionalAreas); 
  const [updatedData, setUpdatedData] = useState({});
  const [advancedFilter, setAdvancedFilter] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [collectFilteredNavData, setCollectFilteredNavData] = useState([]); 
  const [isDirectNavigation, setIsDirectNavigation] = useState(false);
  const [defaultPage, setDefaultPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [filter, setFilter] = useState(() => {
    const storedFilter = JSON.parse(localStorage.getItem("filter-NFA")) || {
      filterTextAreas: "",
      filterTextNavigators: "",
      activeAreaMappings: false,
      activeNavigatorMappings: false, 
    };
    return storedFilter;
  }); 

  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem("filter-NFA"));
    if (storedFilter) { 
      setAdvancedFilter(storedFilter);
      setFilter(storedFilter); 
      const previousPath = localStorage.getItem("previousPath");
      const currentPath = location.pathname;
      if (previousPath && previousPath !== currentPath) {
        setIsDirectNavigation(true);
      }
      localStorage.setItem("previousPath", currentPath);
    }else{
      const previousPath = localStorage.getItem("previousPath");
      const currentPath = location.pathname;
      if (previousPath && previousPath !== currentPath) {
        setIsDirectNavigation(true); 
      }
      localStorage.setItem("previousPath", currentPath);
    } 
  }, [location.pathname]);

  useEffect(() => { 
    if (isDirectNavigation) {
      localStorage.removeItem("filter-NFA");
      localStorage.removeItem("grantId-NFA");
      localStorage.removeItem("expandedIdList-NFA");
      localStorage.removeItem("autoExpandIdList-NFA");
      localStorage.removeItem("finalPageNumber-NFA");
      setIsDirectNavigation(false);
      setAdvancedFilter({});
      setFilter({
        filterTextAreas: "",
        filterTextNavigators: "",
        activeAreaMappings: false,
        activeNavigatorMappings: false, 
      });
      setCurrentPage(defaultPage);    
    }
    console.log("B");
  }, [isDirectNavigation]);

  useEffect(() => { 
    if (navigatorToFunctionalAreasStatus === "idle") {
      dispatch(fetchNavigatorToFunctionalAreas());
    }
    if (functionalAreasStatus === "idle") {
      dispatch(fetchFunctionalAreas());
    } 
    localStorage.setItem("renderComplete", JSON.stringify(false)); 
  });

  useEffect(() => {
    if (isRefreshing) {
      setIsRefreshing(false);
      window.location.reload();
    }
  }, [isRefreshing]);

  const onClickExpand = (expanded, record) => { 
    dispatch(fetchMultiChildNodes({ expanded, record })).then((res) => {
      const idArray = []; 
      if ( res.type === "navigatorToFunctionalAreas/fetchMultiChildNodes/fulfilled" && res.payload.data) { 
      }
    });
  };

  const onRefreshAutoExpand = (expanded, record) => {  
    dispatch(fetchMultiChildNodes({ expanded, record })).then((res) => {
      const idArray = []; 
      if ( res.type === "navigatorToFunctionalAreas/fetchMultiChildNodes/fulfilled" && res.payload.data) { 
      }
       
    });
  };
  
  const onCloseUpdateDialog = () => {
    setUpdatedData({}); 
    setIsRefreshing(true);
    
  };

  const onCancelUpdateDialog = () => {
    setUpdatedData({}); 
    //setIsRefreshing(true);
    
  };

  const onClickCell = (data, functionalArea, status, isSelecter) => {  

    const FAName = functionalArea.name;
    let stateNum = null;
    data.state.forEach((stateThing) => {
      if (stateThing.role == FAName) { 
        stateNum = stateThing.state;
        return; 
      }
    });
 
    if (stateNum == 1 || isSelecter == 1) {
      const updatedData = {
        id: data.id,
        navigatorEntryTitle: data.label,
        projection: data.projection,
        role: functionalArea.name,
        action: "remove",
        openConfirmation: true, 
        state: data.state,
        parentId: data.parentId,
      };

      setUpdatedData(updatedData);

    } else if (stateNum == 0 && isSelecter == null) {
      const updatedData = {
        id: data.id,
        navigatorEntryTitle: data.label,
        projection: data.projection,
        role: functionalArea.name,
        action: "select",
        openConfirmation: true, 
        state: data.state,
        data: data,
        functionalArea: functionalArea,
        parentId: data.parentId,
      };

      setUpdatedData(updatedData);

    } else if (stateNum == null || isSelecter == 10 || stateNum == 3) {
      const updatedData = {
        id: data.id,
        navigatorEntryTitle: data.label,
        projection: data.projection,
        role: functionalArea.name,
        action: status,
        openConfirmation: true, 
        state: data.state,
        parentId: data.parentId,
      };

      setUpdatedData(updatedData);
    }
  };

  const onClickFilter = (filter) => {
    if (name != undefined) {
      navigate(`/navigator-to-functional-areas`);
      if (filter.filterTextAreas == "") {
        filter.filterTextAreas = name;
      }
    }
    setAdvancedFilter(filter);
  };

  const clearFilter = () => {
    setAdvancedFilter({});
    if (name != undefined) {
      navigate(`/navigator-to-functional-areas`);
    }
  };

  const onClickSave = () => {
    let request = {};
    if (updatedData.action === "add") {
      request = {
        navGrantsAdd: [
          {
            id: updatedData.id, 
            functionalAreasId: updatedData.role,
          },
        ],
        navGrantsDel: [],
      };
    } else if (updatedData.action === "remove") {
      request = {
        navGrantsAdd: [],
        navGrantsDel: [
          {
            id: updatedData.id,
            functionalAreasId: updatedData.role, 
          },
        ],
      };
    }

    const grantNodeId = updatedData.id ;
    const grantNodeParentId = updatedData.parentId;

    localStorage.setItem("grantId-NFA", JSON.stringify({ grantNodeId ,grantNodeParentId}));
    localStorage.setItem("grantIdForFetch-NFA", JSON.stringify({ grantNodeId ,grantNodeParentId}));
   
    dispatch( updateNavigatorToFunctionalAreas({ request, onCloseUpdateDialog, }) ); 
  };
 
  const loading =  functionalAreasStatus === "loading" || navigatorToFunctionalAreasStatus === "loading";

  return (
    <Grid container spacing={2}>
      {/* need to develop this later */}
      <UpdateConfirmationDialog
        updatedData={updatedData} 
        closeDialog={onCloseUpdateDialog}
        onSave={onClickSave}
        onClick={onClickCell}
        onCancelUpdateDialog={onCancelUpdateDialog}
      />

      <Grid item lg={9} md={9} sm={9} xs={9} sx={{ textAlign: "left" }}>
        <h2 className="page-title">Navigator - Functional Areas Matrix</h2>
      </Grid>

      <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: "left" }}>
        <Link className="custom-link" to={"/functional-area/new"}>
          <Button
            id="c1d38b56-c590-47c4-b9fe-056c1ba739a1"
            fullWidth
            disableElevation
            variant="contained"
            startIcon={<AddIcon />}
          >
            Functional Area
          </Button>
        </Link>
      </Grid>

      <Grid item lg={12}>
        <AdvancedFilter
          onFilter={onClickFilter}
          onClearFilter={clearFilter}
          setCollectFilteredNavData={setCollectFilteredNavData}
          isDirectNavigation={isDirectNavigation}
          filter={filter}
          setFilter={setFilter}
        />
      </Grid>

      <Grid item lg={12}>
        <Grid container columns={36}>
          <Grid item lg={36} className="pt-text-x-axis">
            Functional Areas
          </Grid>
          <Grid item lg={1} className="pt-text-y-axis">
            Navigator Entries
          </Grid>
          <Grid item lg={35}>
            <DataTable
              data={navigatorToFunctionalAreas}
              status={navigatorToFunctionalAreasStatus}
              functionalAreas={functionalAreas}
              filters={advancedFilter}
              onExpandRow={onClickExpand}
              onCellClicked={onClickCell}
              loading={loading}
              setCollectFilteredNavData={setCollectFilteredNavData}
              collectFilteredNavData={collectFilteredNavData}
              onRefreshAutoExpand={onRefreshAutoExpand}
              setIsDirectNavigation={setIsDirectNavigation}
              isDirectNavigation={isDirectNavigation}
              defaultPage={defaultPage}
              setDefaultPage={setDefaultPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Grid>
        </Grid>
      </Grid> 
    </Grid>
  );
};

export default NavigatorToFunctionalAreas;
