import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const RoleModal = ({ open, onClose, title, data }) => {
  const apps10Columns = [
    { field: "role", headerName: "Permission Set", flex: 2 },
    { field: "roleType", headerName: "Permission Set Type", flex: 2 },
    { field: "pressObject", headerName: "Presentation Object", flex: 2 },
    { field: "projection", headerName: "Projection", flex: 2 },
    { field: "grantType", headerName: "Grant Type", flex: 1 },
    { field: "status", headerName: "Status", flex: 2 },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ position: "relative", zIndex: 1, background: "#fff" }}>
        {title}
        <Button
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          X
        </Button>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "600px",  
          overflow: "hidden",  
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflowY: "auto", 
            height: "100%",
          }}
        >
          <DataGrid
            columns={apps10Columns}
            rows={data}
            autoHeight={false}
            pagination={false} 
            sx={{ 
                "& .MuiDataGrid-footerContainer": {
                display: "none",  
                },
            }}
            />

        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RoleModal;
