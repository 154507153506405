import { Fab, Grid, Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchUsersToEndUserRoleMapping,
  selectAllUsersToEndUserRole,
  updateMappingMatrix,
} from "../../redux/reducers/usersToEndUserRoleSlice";
import {
  fetchEndUserRoles,
  selectAllEndUserRoles,
} from "../../redux/reducers/endUserRoleSlice";
import {
  fetchUserGroups,
  selectAllUserGroups,
} from "../../redux/reducers/userGroupSlice";
import {
  fetchPermissionSetCategories,
  selectAllPermissionSetCategories,
} from "../../redux/reducers/permissionSetCategorySlice";
import { Save, Close } from "@mui/icons-material";
import UpdateConfirmationDialog from "./UpdateConfirmationDialog";
import AdvancedFilter from "./AdvancedFilter";
import DataTable from "./DataTable";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const UserToEndUserRoles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = useParams();

  const usersToEndUserRoles = useSelector(selectAllUsersToEndUserRole);
  const permissionSetCategories = useSelector(selectAllPermissionSetCategories);
  const endUserRoles = useSelector(selectAllEndUserRoles);
  const userGroups = useSelector(selectAllUserGroups);
  const usersToEndUserRoleStatus = useSelector(
    (state) => state.usersToEndUserRole.status
  );
  const endUserRolesStatus = useSelector((state) => state.endUserRoles.status);
  const permissionSetCategoryStatus = useSelector(
    (state) => state.permissionSetCategories.status
  );
  const userGroupStatus = useSelector((state) => state.userGroups.status);
  const [advancedFilter, setAdvancedFilter] = useState({});
  const [updatedData, setUpdatedData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem("previousPath", currentPath);
  }, [location.pathname]);

  useEffect(() => {
    if (usersToEndUserRoleStatus === "idle") {
      dispatch(fetchUsersToEndUserRoleMapping());
    }
    if (endUserRolesStatus === "idle") {
      dispatch(fetchEndUserRoles());
    }
    if (permissionSetCategoryStatus === "idle") {
      dispatch(fetchPermissionSetCategories());
    }
    if (userGroupStatus === "idle") {
      dispatch(fetchUserGroups());
    }
  }, [
    usersToEndUserRoleStatus,
    endUserRolesStatus,
    permissionSetCategoryStatus,
    userGroupStatus,
    dispatch,
  ]);

  const onClickFilter = (filter) => {
    if (name != undefined) {
      navigate(`/users-to-end-user-roles`);
      if (filter.filterTextRoles == "") {
        filter.filterTextRoles = name;
      }
    }
    setAdvancedFilter(filter);
  };

  // useEffect(() => {
  //   const storedFilter = JSON.parse(localStorage.getItem("filter"));
  //   if (storedFilter) {
  //     setAdvancedFilter(storedFilter);
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem("filter", JSON.stringify(advancedFilter));
  // }, [advancedFilter]);

  const onClickCell = (data, endUserRole, type) => {
    let updatingIndex = null;
    let dataToUpdate = updatedData.find((mapping, index) => {
      updatingIndex = index;
      return mapping.user === data.user;
    });

    if (type === "add") {
      if (dataToUpdate) {
        const permissionIndex = dataToUpdate.userGrantsAdd.indexOf(
          endUserRole.name
        );
        const isInAddList = permissionIndex !== -1;
        const isInDelList = dataToUpdate.userGrantsDel.includes(
          endUserRole.name
        );

        if (isInDelList) {
          dataToUpdate.userGrantsDel.splice(
            dataToUpdate.userGrantsDel.indexOf(endUserRole.name),
            1
          );
        } else if (!isInAddList) {
          dataToUpdate.userGrantsAdd.push(endUserRole.name);
        } else {
          dataToUpdate.userGrantsAdd.splice(permissionIndex, 1);
        }

        if (
          dataToUpdate.userGrantsAdd.length === 0 &&
          dataToUpdate.userGrantsDel.length === 0
        ) {
          const updatedState = [...updatedData];
          updatedState.splice(updatingIndex, 1);
          setUpdatedData(updatedState);
        } else {
          const updatedState = [...updatedData];
          updatedState[updatingIndex] = dataToUpdate;
          setUpdatedData(updatedState);
        }
      } else {
        setUpdatedData((prevState) => [
          {
            user: data.user,
            userGrantsAdd: [endUserRole.name],
            userGrantsDel: [],
          },
          ...prevState,
        ]);
      }
    } else {
      if (dataToUpdate) {
        const permissionIndex = dataToUpdate.userGrantsDel.indexOf(
          endUserRole.name
        );
        const isInAddList = dataToUpdate.userGrantsAdd.includes(
          endUserRole.name
        );
        const isInDelList = permissionIndex !== -1;

        if (isInAddList) {
          dataToUpdate.userGrantsAdd.splice(
            dataToUpdate.userGrantsAdd.indexOf(endUserRole.name),
            1
          );
        } else if (!isInDelList) {
          dataToUpdate.userGrantsDel.push(endUserRole.name);
        } else {
          dataToUpdate.userGrantsDel.splice(permissionIndex, 1);
        }

        if (
          dataToUpdate.userGrantsAdd.length === 0 &&
          dataToUpdate.userGrantsDel.length === 0
        ) {
          const updatedState = [...updatedData];
          updatedState.splice(updatingIndex, 1);
          setUpdatedData(updatedState);
        } else {
          const updatedState = [...updatedData];
          updatedState[updatingIndex] = dataToUpdate;
          setUpdatedData(updatedState);
        }
      } else {
        setUpdatedData((prevState) => [
          {
            user: data.user,
            userGrantsDel: [endUserRole.name],
            userGrantsAdd: [],
          },
          ...prevState,
        ]);
      }
    }

    dispatch(updateMappingMatrix({ data, endUserRole, type }));
  };

  const onClose = () => {
    setUpdatedData([]);
    setOpenConfirmation(false);
  };

  const onCancel = () => {
    window.location.reload();
    setUpdatedData([]);
    setOpenConfirmation(false);
  };

  const clearFilter = () => {
    setAdvancedFilter({});
    if (name != undefined) {
      navigate(`/users-to-end-user-roles`);
    }
  };

  const handleSave = () => {
    setUpdatedData([]);
  };

  const handleCancel = () => {
    setUpdatedData([]);
    setOpenConfirmation(false);
  };

  const checkDataValidity = (data) => {
    for (const item of data) {
      if (item.userGrantsAdd.length !== 0 || item.userGrantsDel.length !== 0) {
        return true; // If any object has non-empty arrays, return true
      }
    }
    return false; // If all objects have empty arrays, return false
  };

  const loading =
    usersToEndUserRoleStatus === "loading" || endUserRolesStatus === "loading";

  return (
    <Grid container spacing={2}>
      <UpdateConfirmationDialog
        setOpenConfirmation={setOpenConfirmation}
        openConfirmation={openConfirmation}
        type={"save"}
        updatedData={updatedData}
        handleClose={onClose}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleCancelCell={onCancel}
      />
      <Grid item lg={12} sx={{ textAlign: "left" }}>
        <h2 className="page-title">User - End-User Roles Matrix</h2>
      </Grid>
      <Grid item lg={12}>
        <AdvancedFilter
          onFilter={onClickFilter}
          permissionSetCategories={permissionSetCategories}
          onClearFilter={clearFilter}
          userGroups={userGroups}
          data={usersToEndUserRoles}
          endUserRoles={endUserRoles}
        />
      </Grid>
      <Grid item lg={12}>
        <Grid container columns={36}>
          <Grid item lg={36} className="pt-text-x-axis">
            End-User Roles
          </Grid>
          <Grid item lg={1} className="pt-text-y-axis">
            Users
          </Grid>
          <Grid item lg={35}>
            <DataTable
              data={usersToEndUserRoles}
              endUserRoles={endUserRoles}
              filters={advancedFilter}
              loading={loading}
              onClickCell={onClickCell}
            />
          </Grid>
        </Grid>
      </Grid>
      {updatedData &&
        updatedData.length > 0 &&
        checkDataValidity(updatedData) && (
          <Stack
            direction="row"
            spacing={2}
            style={{ position: "fixed", right: 20, bottom: 20, zIndex: 1000 }}
          >
            <Fab
              id="18bbea18-2092-479d-a0d7-d99aff7cd1dc"
              variant="extended"
              onClick={() => onCancel()}
            >
              <Close sx={{ mr: 1 }} />
              Cancel
            </Fab>
            <Fab
              id="ce04b9f1-23b7-4a3c-9680-43d984e76285"
              variant="extended"
              color="primary"
              onClick={() => setOpenConfirmation(true)}
            >
              <Save sx={{ mr: 1 }} />
              Save
            </Fab>
          </Stack>
        )}
    </Grid>
  );
};

export default UserToEndUserRoles;
