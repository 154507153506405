import {
  Grid,
  Button,
  Autocomplete,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControlLabel,
  Switch,
  Chip,
  IconButton,
} from "@mui/material";
import { Search, FilterAlt, Close as CloseIcon } from "@mui/icons-material";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; 

const searchOptions = [
  { value: "users", label: "Users" },
  { value: "endUserRoles", label: "End-User Roles" },
];

const AdvancedFilter = ({
  onFilter,
  permissionSetCategories = [],
  onClearFilter,
  userGroups = [],
  data,
  endUserRoles,
}) => {
 
  const [filter, setFilter] = useState({
    filterTextUser: "",
    filterTextRoles: "",
    activeUserMappings: false,
    activeRolesMappings: false,
    users: [],
    userGroups: [],
    endUserCat: [],
  });

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const clear = () => {
    setFilter({
      filterTextUser: "",
      filterTextRoles: "",
      activeUserMappings: false,
      activeRolesMappings: false,
      users: [],
      userGroups: [],
      endUserCat: [],
    });
    onClearFilter();
  };

  const handleDeleteUser = (userToDelete) => () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      users: prevFilter.users.filter((user) => user !== userToDelete),
    }));
  };

  const handleDeleteUserGroup = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      userGroups: [],
    }));
  };

  const handleDeleteEndUserCat = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      endUserCat: [],
    }));
  };

  const onFilterUpdate = (type, value) => {
    switch (type) {
      // case 'filterType': {
      //     setFilter({ ...filter, filterType: value });
      //     return;
      // }
      case "filterTextUser": {
        setFilter({ ...filter, filterTextUser: value });
        return;
      }
      case "filterTextRoles": {
        setFilter({ ...filter, filterTextRoles: value });
        return;
      }
      case "activeUserMappings": {
        setFilter({ ...filter, activeUserMappings: value });
        return;
      }
      case "activeRolesMappings": {
        setFilter({ ...filter, activeRolesMappings: value });
        return;
      }
      case "users": {
        setFilter({ ...filter, users: value });
        return;
      }
      case "userGroups": {
        setFilter({ ...filter, userGroups: value });
        return;
      }
      case "endUserCat": {
        setFilter({ ...filter, endUserCat: value });
        return;
      }
      default: {
        console.log("No type defined");
      }
    }
  };

  console.log("data is", data, "\nend user roles is \n", endUserRoles);
  return (
    <Grid container spacing={2}>

      {/* <Grid item lg={3}>
        <TextField
          size="small"
          fullWidth
          label={"Search by Users"}
          value={filter?.filterTextUser}
          onChange={(e) => {
            //onFilterUpdate('filterType', 'users')
            onFilterUpdate("filterTextUser", e.target.value);
          }}
          onInput={toInputUppercase}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "#000000" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid> */}

      <Grid item lg={3}>
      <FormControl fullWidth size="small">
        <Autocomplete
          id="42d140e6-53b6-4c75-bcd5-bf64aedd97f9"        
          multiple
          size="small"
          options={data.map((users) => users.user)}
          value={filter.users}
          filterSelectedOptions
          onChange={(event, newValue) => {
            onFilterUpdate("users", newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search by Users" 
            />
          )}
        />
      </FormControl>
    </Grid>

      {/* <Grid item lg={3}>
        <FormControl fullWidth size="small">
          <InputLabel id="user-label">Search by Users</InputLabel>
          <Select
            multiple
            fullWidth
            label="Search by Users"
            labelId="user-label"
            input={<OutlinedInput label="Search by Users" />}
            value={filter.users}
            style={{ textAlign: "left" }}
            onChange={(e) => {
              onFilterUpdate("users", e.target.value);
              console.log(filter);
            }}
          >
            {data &&
              data.map((users) => (
                <MenuItem key={users.user} value={users.user}>
                  {users.user}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid> */}

      {/* <Grid item lg={3}>
        <FormControl fullWidth size="small">
          <InputLabel id="users-label">Users</InputLabel>
          <Select
            //multiple
            fullWidth
            label="Users"
            labelId="users-label"
            input={<OutlinedInput label="Users" />}
            value={filter.userGroups}
            style={{ textAlign: "left" }}
            onChange={(e) => onFilterUpdate("users", e.target.value)}
            //disabled={filter.filterType === searchOptions[1]?.label}
          >
            {userGroups &&
              userGroups.map((userGroup) => (
                <MenuItem key={userGroup} value={userGroup}>
                  {userGroup}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid> */}

        {/* <Grid item lg={3}>
          <FormControl fullWidth size="small">
            <InputLabel id="user-groups-label">User Groups</InputLabel>
              <Select
                //multiple
                fullWidth
                label="User Groups"
                labelId="user-groups-label"
                input={<OutlinedInput label="User Groups" />}
                value={filter.userGroups}
                style={{ textAlign: "left" }}
                onChange={(e) => onFilterUpdate("userGroups", e.target.value)}
                //disabled={filter.filterType === searchOptions[1]?.label}
                >
                {userGroups &&
                  userGroups.map((userGroup) => (
                    <MenuItem key={userGroup} value={userGroup}>
                      {userGroup}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
        </Grid> */}

      <Grid item lg={3}>
        <FormControl fullWidth size="small">
          <Autocomplete
            id="7e72e7c3-0d62-4198-9989-cc96038bc612"
            size="small"
            options={userGroups}
            value={filter.userGroups}
            onChange={(event, newValue) => {
              onFilterUpdate("userGroups", newValue);
            }}
            renderTags={(value, getTagProps) =>
              value && (
                <Chip
                  label={value}
                  {...getTagProps({ index: 0 })}
                  deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                  onDelete={handleDeleteUserGroup}
                />
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="User Groups"
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item lg={4} style={{ textAlign: "left" }}>
        <FormControlLabel
          onChange={(e) =>
            onFilterUpdate("activeUserMappings", e.target.checked)
          }
          id="9fdb61ba-106f-4c65-8fdb-f458f1a979e1"
          size="small"
          control={<Switch checked={filter.activeUserMappings} />}
          label="Active mappings for Users"
        />
      </Grid>
      <Grid item lg={2}>
        <Button
          id="48eee898-feff-44e7-8e83-77640dbb4552"
          onClick={() => clear()}
          fullWidth
          disableElevation
          variant="outlined"
          startIcon={<FilterAlt />}
        >
          Clear Filter
        </Button>
      </Grid>
      <Grid item lg={3}>
        <TextField
          id="1ed8212b-ec3d-49a0-8762-ac707d73f287"
          size="small"
          fullWidth
          label={"Search by End-User Roles"}
          value={filter?.filterTextRoles}
          onChange={(e) => {
            //onFilterUpdate('filterType', 'endUserRoles')
            onFilterUpdate("filterTextRoles", e.target.value);
          }}
          onInput={toInputUppercase}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "#000000" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {/* <Grid item lg={3}>
        <FormControl fullWidth size="small">
          <InputLabel id="func-cat-label">End-User Roles Category</InputLabel>
          <Select
            //multiple
            fullWidth
            label="End-User Category"
            labelId="func-cat-label"
            input={<OutlinedInput label="End-User Category" />}
            value={filter.endUserCat}
            style={{ textAlign: "left" }}
            onChange={(e) => onFilterUpdate("endUserCat", e.target.value)}
            //disabled={filter.filterType === searchOptions[0]?.label}
          >
            {permissionSetCategories &&
              permissionSetCategories.map((category) => (
                <MenuItem key={category.name} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid> */}

      <Grid item lg={3}>
        <FormControl fullWidth size="small">
          <Autocomplete
            id="26e4e862-774c-4032-8de4-9ef34543b0f6"
            size="small"
            options={permissionSetCategories.map((category) => category.name)}
            value={filter.endUserCat}
            onChange={(event, newValue) => {
              onFilterUpdate("endUserCat", newValue);
            }}
            renderTags={(value, getTagProps) =>
              value && (
                <Chip
                  label={value}
                  {...getTagProps({ index: 0 })}
                  deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                  onDelete={handleDeleteEndUserCat}
                />
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="End-User Roles Category"
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item lg={4} style={{ textAlign: "left" }}>
        <FormControlLabel
          onChange={(e) =>
            onFilterUpdate("activeRolesMappings", e.target.checked)
          }
          id="21fa828f-f322-408c-8180-739c1c1469f0"
          size="small"
          control={<Switch checked={filter.activeRolesMappings} />}
          label="Active mappings for End-User Roles"
        />
      </Grid>
      <Grid item lg={2}>
        <Button
          id="d8dac21b-b4f4-4067-85c0-f2b229ba2b18"
          onClick={() => onFilter(filter)}
          fullWidth
          disableElevation
          variant="contained"
          startIcon={<FilterAlt />}
        >
          Apply Filter
        </Button>
      </Grid>
    </Grid>
  );
};

export default AdvancedFilter;
