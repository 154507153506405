import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  CircularProgress,
  LinearProgress,
  Typography,
  Card,
  Checkbox,
  Chip,
  Menu,
  ListItemText,
} from "@mui/material";
import {
  selectAllUsers,
  fetchUsers,
} from "../../../../redux/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Search, ExitToApp } from "@mui/icons-material";
import { fetchSegregationOfDutiesUserAnalysisReportData } from "../../../../redux/reducers/functionalAreaConflictSlice";

import ExcelJS from "exceljs";

const SegregationView = () => {
  const dispatch = useDispatch();
  const functionalAreaConflictsStatus = useSelector(state => state.functionalAreaConflicts.status);

  const [navigatorId, setNavigatorId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const getAllNavDataHierarchy = () => {
    setExporting(true);
    setProgressValue(10);
     
    dispatch(fetchSegregationOfDutiesUserAnalysisReportData()).then(async (res) => {
        
      const segData = res.payload;

      let progress = 10;
      while (progress < 100) {
        await simulateAsyncOperation();
        progress += 10;
        setProgressValue(progress);
      }

      handleOnExport(segData);

      setProgressValue(100);
      setExporting(false);
    });
  };
 
  const navData = [
    {
      userId: "AM-VIM-04",
      conflicts: [
        {
          conflictName: "Conflict 1",
          conflictType: "NOTALLOWED",
          details: [
            "Functional Area: Functional Area 1",
            "Functional Area Projections: AnalyzeHeartbeatObservationsHandling, ExecuteandCompleteObservationsHandling, IotFalseObservationsHandling, IotObservationHandling, IotObservationsHandling",
            "Conflict Area: Functional Area 2",
            "Conflict Area Projections: ExecuteandCompleteObservationsHandling",
          ],
        },
        {
          conflictName: "Conflict 2",
          conflictType: "WARNING",
          details: [
            "Functional Area: Functional Area 2",
            "Functional Area Projections: ExecuteandCompleteObservationsHandling",
            "Conflict Area: Functional Area 3",
            "Conflict Area Projections: PermissionSetHandling",
          ],
        },
      ],
    },
    {
      userId: "SCOOPER",
      conflicts: [
        {
          conflictName: "Conflict 1",
          conflictType: "NOTALLOWED",
          details: [
            "Functional Area: Functional Area 1",
            "Functional Area Projections: AnalyzeHeartbeatObservationsHandling, ExecuteandCompleteObservationsHandling, IotFalseObservationsHandling, IotObservationHandling, IotObservationsHandling",
            "Conflict Area: Functional Area 2",
            "Conflict Area Projections: ExecuteandCompleteObservationsHandling",
          ],
        },
        {
          conflictName: "Conflict 2",
          conflictType: "WARNING",
          details: [
            "Functional Area: Functional Area 2",
            "Functional Area Projections: ExecuteandCompleteObservationsHandling",
            "Conflict Area: Functional Area 3",
            "Conflict Area Projections: PermissionSetHandling",
          ],
        },
      ],
    },
    {
      userId: "IFS_IOT_GATEWAY",
      conflicts: [],
    },
    {
      userId: "IFSADMIN",
      conflicts: [
        {
          conflictName: "Conflict 2",
          conflictType: "WARNING",
          details: [
            "Functional Area: Functional Area 2",
            "Functional Area Projections: ",
            "Conflict Area: Functional Area 3",
            "Conflict Area Projections: PermissionSetHandling",
          ],
        },
      ],
    },
    {
      userId: "IFSBRES",
      conflicts: [],
    },
  ];

  const handleOnExport = async (navData) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Segregation of Duties Analysis Report");

    // Define styles
    const styles = {
      green: {
        fill: { type: "pattern", pattern: "solid", fgColor: { argb: "70CB3D" } },
        font: { color: { argb: "000000" } }, // Black font for green
      },
      red: {
        fill: { type: "pattern", pattern: "solid", fgColor: { argb: "BB010E" } },
        font: { color: { argb: "FFFFFF" } }, // White font for red
      },
      orange: {
        fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FCAC36" } },
        font: { color: { argb: "000000" } }, // Black font for orange
      },
      bold: {
        font: { bold: true }, // Bold text style
      },
      mediumBorder: {
        top: { style: "medium" },
        left: { style: "medium" },
        bottom: { style: "medium" },
        right: { style: "medium" },
      },
    };

    // Set the title row
    const titleRow = sheet.addRow(["Segregation of Duties Analysis Report"]);
    titleRow.eachCell((cell) => {
      cell.style = styles.bold; // Apply bold style to the title
    });

    // Set the header row
    const headerRow = sheet.addRow([
      "User",
      "Conflict",
      "Conflict Type",
      "Functional Area", // D column
      "Conflict Area", // E column
    ]);

    // Apply bold style and medium border to the header
    headerRow.eachCell((cell) => {
      cell.style = { ...styles.bold, border: styles.mediumBorder };
    });

    // Maintain a set of unique records
    const uniqueRecords = new Set();

    // Iterate through navData to build the report structure
    navData?.forEach((user) => {
      if (user.conflicts.length === 0) {
        // No conflicts case - Green color for No Conflict
        const noConflictRow = [user.userId, "No Conflict", "", "", ""];
        const row = sheet.addRow(noConflictRow);

        // Style the cells for No Conflict and add medium borders around the row
        row.eachCell((cell) => {
          cell.style = { ...styles.green, border: styles.mediumBorder };
        });
      } else {
        // Handle users with conflicts
        user.conflicts.forEach((conflict) => {
          let functionalArea = "";
          let functionalAreaProjection = "";
          let conflictArea = "";
          let conflictAreaProjection = "";

          // Extract details from the conflict details array
          conflict.details.forEach((detail) => {
            if (detail.startsWith("Functional Area:")) {
              functionalArea = detail.split(":")[1].trim();
            } else if (detail.startsWith("Functional Area Projections:")) {
              functionalAreaProjection = detail.split(":")[1].trim();
            } else if (detail.startsWith("Conflict Area:")) {
              conflictArea = detail.split(":")[1].trim();
            } else if (detail.startsWith("Conflict Area Projections:")) {
              conflictAreaProjection = detail.split(":")[1].trim();
            }
          });

          let conflictName = conflict.conflictName || "";
          let conflictType = conflict.conflictType || "";
          let detailsText = "";
          let conflictAreaText = ""; // New variable for E column

          if (functionalAreaProjection && conflictAreaProjection) {
            // Full conflict: Both projections present
            detailsText = functionalArea; // Fill D column
            conflictAreaText = conflictArea; // Fill E column
          } else if (functionalAreaProjection || conflictAreaProjection) {
            // Partial conflict: Only one projection present
            conflictName = "Partial"; // Mark as Partial conflict
            conflictType = ""; // No conflict type for partial
            detailsText = functionalAreaProjection
              ? functionalArea
              : conflictAreaProjection
              ? conflictArea
              : ""; // Fill D column
            conflictAreaText = ""; // Fill E column
          }

          // Prepare the row data, including repeating userId
          const conflictRow = [
            user.userId, // Now repeat userId for each conflict
            conflictName, // Conflict name or Partial
            conflictType, // Conflict type
            detailsText, // Functional Area in D column
            conflictAreaText, // Conflict Area in E column
          ];

          // Check if the row is a duplicate
          const recordKey = `${user.userId}-${conflictName}-${detailsText}`;
          if (!uniqueRecords.has(recordKey)) {
            uniqueRecords.add(recordKey); // Add to set
            const row = sheet.addRow(conflictRow);

            // Apply color and border based on conflict type
            if (conflictType === "NOTALLOWED" || conflictType === "WARNING") {
              row.getCell(2).style = { ...styles.red, border: styles.mediumBorder };
              row.getCell(3).style = { ...styles.red, border: styles.mediumBorder };
              row.getCell(4).style = { ...styles.red, border: styles.mediumBorder };
              row.getCell(5).style = { ...styles.red, border: styles.mediumBorder };

              // Set User ID cell to red
              row.getCell(1).style = { ...styles.red, border: styles.mediumBorder };
            } else {
              if (conflictName === "Partial") {
                // Mark User ID cell as orange for partial conflicts
                row.getCell(1).style = {
                  ...styles.orange,
                  border: styles.mediumBorder,
                };
                row.getCell(2).style = {
                  ...styles.orange,
                  border: styles.mediumBorder,
                };
                row.getCell(3).style = {
                  ...styles.orange,
                  border: styles.mediumBorder,
                };
                row.getCell(4).style = {
                  ...styles.orange,
                  border: styles.mediumBorder,
                };
                row.getCell(5).style = {
                  ...styles.orange,
                  border: styles.mediumBorder,
                };
              } else {
                // Normal case for no conflicts
                row.getCell(1).style = {
                  ...styles.green,
                  border: styles.mediumBorder,
                };
              }
            }

            row.eachCell((cell) => {
              cell.font =
                conflictType === "NOTALLOWED" || conflictType === "WARNING"
                  ? { color: { argb: "FFFFFF" } } // Set font color to white for red cells
                  : { color: { argb: "000000" } }; // Set font color to black for non-red cells

              cell.border = styles.mediumBorder; // Apply medium borders for all cells
            });
          }
        });
      }
    });

    sheet.columns.forEach((column) => {
      const maxLength = column.values.reduce((max, value) => {
        return Math.max(max, value ? value.toString().length : 0);
      }, 0);
      column.width = maxLength < 10 ? 10 : maxLength; // Minimum width for readability
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "Segregation_of_Duties_Analysis_Report.xlsx";
    link.click();
  };

 
  const simulateAsyncOperation = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  return (
    <Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
        <h2 className="page-title">Segregation of Duties Analysis Report.</h2>
      </Grid>
      <br />
      <br />
      <Card sx={{ backgroundColor: "#D3D3D3", padding: "16px" }}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={8} sm={12} xs={12}>
            <InputLabel sx={{ color: "black", textAlign: "left" }}>
              ✔ User Analysis
            </InputLabel>
          </Grid>
          <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
            <Button
              fullWidth
              onClick={() => {
                getAllNavDataHierarchy();
                //handleOnExport(navData);
              }}
              disableElevation
              variant="contained"
              startIcon={<ExitToApp />} 
            >
              Export
            </Button>
          </Grid>
          <Grid item lg={7} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
            {exporting && (
              <div style={{ width: "100%", marginTop: "20px", height: "20%" }}>
                <LinearProgress
                  variant="determinate"
                  value={progressValue > 100 ? 100 : progressValue}
                  sx={{ height: 8 }}
                />
                <Typography variant="body2">{`${parseInt(
                  progressValue > 100 ? 100 : progressValue + ""
                )}%`}</Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
      {loading && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40vh" }}>
          <CircularProgress />
        </div>
      )}
    </Grid>
  );
};

export default SegregationView;
