import React from "react";
import CheckCircle from "@mui/icons-material/CheckCircle";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";

const Legend = () => {
  return (
    <div className="legend-container">
      <h3 className="legend-title">Legend:</h3>

      <div className="legend-item">
        <CheckCircle style={{ color: "#8a54ba" }} />
        <span className="legend-text">
          Navigator level <strong>completely</strong> granted to Functional Area
        </span>
      </div>

      <div className="legend-item">
        <RadioButtonChecked style={{ color: "#8a54ba" }} />
        <span className="legend-text">
          Navigator level <strong>partially</strong> granted to Functional Area
        </span>
      </div>
    </div>
  );
};

export default Legend;
