import { Stack, IconButton, Grid, Chip } from "@mui/material";
import { ModeEdit, ContentCopy, Delete } from "@mui/icons-material";
import DataTable from "./DataTable";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchFunctionalAreaConflicts,
  addNewFunctionalAreaConflict,
  updateFunctionalAreaConflict,
  deleteFunctionalAreaConflict,
  selectFunctionalAreaConflictByName,
  selectAllFunctionalAreaConflicts,
  selectFunctionalAreaConflictsById,
  fetchAssignedConflicts,
} from "../../../redux/reducers/functionalAreaConflictSlice";
import Swal from "sweetalert2";

const ConflictView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const functionalAreaConflicts = useSelector(selectAllFunctionalAreaConflicts);
  const functionalAreaConflictsStatus = useSelector(
    (state) => state.functionalAreaConflicts.status
  );
  const [deleteActive, setDeleteActive] = useState();

  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem("previousPath", currentPath);
  }, [location.pathname]);

  useEffect(() => {
    if (functionalAreaConflictsStatus === "idle") {
      dispatch(fetchFunctionalAreaConflicts());
    }
  }, [functionalAreaConflictsStatus, dispatch]);

  const deleteArea = async (id) => {
    Swal.fire({
      title: "Delete Functional Area Conflict",
      text: "Are you sure you want to delete this Functional Area Conflict?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  const onDeleteConfirm = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Delete Functional Area Conflict",
        text: "Are you sure you want to delete this Functional Area Conflict?",
        icon: "warning",
        iconColor: "red",
        showCancelButton: true,
        cancelButtonText: "CANCEL",
        confirmButtonText: "CONFIRM DELETION",
        confirmButtonColor: "red",
      });

      if (result.isConfirmed) {
        await dispatch(deleteFunctionalAreaConflict({ id }));
        Swal.fire(
          "Deleted",
          "The Functional Area Conflict has been deleted.",
          "success"
        );
        dispatch(fetchFunctionalAreaConflicts());
      } else {
        Swal.fire(
          "Deletion Cancelled",
          "The Functional Area Conflict was not deleted.",
          "info"
        );
        dispatch(fetchFunctionalAreaConflicts());
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        "Error",
        "An error occurred while processing the request.",
        "error"
      );
      dispatch(fetchFunctionalAreaConflicts());
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 2 },
    { field: "functionalArea", headerName: "Functional Area", flex: 2 },
    { field: "conflictArea", headerName: "Conflict Area", flex: 2 },
    {
      field: "conflictType",
      headerName: "Conflict Type",
      flex: 2,
      renderCell: (params) => {
        const conflictType = params.value;

        let color = "default";
        if (conflictType === "Not Allowed") {
          color = "error";
        } else if (conflictType === "Warning") {
          color = "warning";
        }

        return (
          <Chip
            label={conflictType}
            color={color}
            //variant="outlined"
          />
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (data) => {
        return (
          <div>
            <Stack direction="row" spacing={1}>
              {/* <Link className="custom-link" to={"/functional-area-conflict/duplicate/" + data.id}>
                                <IconButton color='primary' size="medium" sx={{ borderRadius: 2, border: '1px solid' }} variant="outlined"><ContentCopy sx={{ fontSize: 20 }} /></IconButton>
                            </Link> */}
              <Link
                className="custom-link"
                to={"/functional-area-conflict/" + data.id}
              >
                <IconButton
                  color="warning"
                  size="small"
                  sx={{ borderRadius: 1, border: "1px solid" }}
                  variant="outlined"
                >
                  <ModeEdit sx={{ fontSize: 16 }} />
                </IconButton>
              </Link>
              <IconButton
                onClick={() => deleteArea(data)}
                color="error"
                size="small"
                sx={{ borderRadius: 1, border: "1px solid" }}
              >
                <Delete sx={{ fontSize: 16 }} />
              </IconButton>
            </Stack>
          </div>
        );
      },
    },
  ];

  return (
    <Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
        <h2 className="page-title">Functional Area Conflicts</h2>
      </Grid>
      <DataTable
        columns={columns}
        data={functionalAreaConflicts}
        loading={functionalAreaConflictsStatus}
      />
    </Grid>
  );
};

export default ConflictView;
