import { Grid, Button } from "@mui/material";
import { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchNavigatorToFunctionalPermissionSets,
  selectAllNavigatorToFunctionalPermissionSets,
  fetchMultiChildNodes,
  fetchNavigatorHierarchy,
  updateNavigatorToFunctionalPermissionSets,
  updateBulkNavigatorToFunctionalPermissionSets,
} from "../../../redux/reducers/navigatorToFunctionalPermissionSetsMultiSlice";
import DataTable from "./DataTable";
import Legend from "./Legend";
import {
  fetchFunctionalPermissionSets,
  selectAllFunctionalPermissionSets,
} from "../../../redux/reducers/functionalPermissionSetSlice";
import UpdateConfirmationDialog from "./UpdateConfirmationDialog";
import AdvancedFilter from "./AdvancedFilter";
import {
  fetchPermissionSetCategories,
  selectAllPermissionSetCategories,
} from "../../../redux/reducers/permissionSetCategorySlice";
import { fetchNavigatorConsolidations } from "../../../redux/reducers/navigatorConsolidationSlice"; 

 
const NavigatorToFunctionalPermissionSetsMulti = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = useParams();
  const navigatorToFunctionalPermissionSetsStatus = useSelector((state) => state.navigatorToFunctionalPermissionSetsMulti.status);
  const navigatorToFunctionalPermissionSets = useSelector( selectAllNavigatorToFunctionalPermissionSets );
  const functionalPermissionSetStatus = useSelector( (state) => state.functionalPermissionSets.status );
  const functionalPermissionSets = useSelector( selectAllFunctionalPermissionSets );
  const permissionSetCategories = useSelector(selectAllPermissionSetCategories);
  const permissionSetCategoryStatus = useSelector( (state) => state.permissionSetCategories.status );
  const [updatedData, setUpdatedData] = useState({});
  const [advancedFilter, setAdvancedFilter] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [collectFilteredNavData, setCollectFilteredNavData] = useState([]); 
  const [isDirectNavigation, setIsDirectNavigation] = useState(false);
  const [defaultPage, setDefaultPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [filter, setFilter] = useState(() => {
    const storedFilter = JSON.parse(localStorage.getItem("filter")) || {
      filterTextPermissions: "",
      filterTextNavigators: "",
      activePermissionMappings: false,
      activeNavigatorMappings: false,
      functionalCat: [],
    };
    return storedFilter;
  });

  // useEffect(() => { 
  //   const handleNavigation = (event) => {  
  //     setIsDirectNavigation(event.currentTarget === window);
  //   };

  //   window.addEventListener("click", handleNavigation);

  //   return () => {
  //     window.removeEventListener("click", handleNavigation);
  //   };
  // }, []);

  // useEffect(() => {
  //   const storedFilter = JSON.parse(localStorage.getItem("filter"));
  //   if (storedFilter) {
  //     setAdvancedFilter(storedFilter);
  //     setFilter(storedFilter);
  //   }
  // }, []);
 
  // useEffect(() => {
  //   if (isDirectNavigation) { 
  //     localStorage.removeItem("filter"); 
  //     setAdvancedFilter({});
  //     setFilter({
  //       filterTextPermissions: "",
  //       filterTextNavigators: "",
  //       activePermissionMappings: false,
  //       activeNavigatorMappings: false,
  //       functionalCat: [],
  //     });
  //   }
  // }, [isDirectNavigation]);

  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem("filter"));
    if (storedFilter) { 
      setAdvancedFilter(storedFilter);
      setFilter(storedFilter);
      // Check if the navigation is direct by comparing current and previous paths
      const previousPath = localStorage.getItem("previousPath");
      const currentPath = location.pathname;
      if (previousPath && previousPath !== currentPath) {
        setIsDirectNavigation(true);
      }
      localStorage.setItem("previousPath", currentPath);
    }else{
      const previousPath = localStorage.getItem("previousPath");
      const currentPath = location.pathname;
      if (previousPath && previousPath !== currentPath) {
        setIsDirectNavigation(true);
        //setCurrentPage(defaultPage);
      }
      localStorage.setItem("previousPath", currentPath);
    }
    console.log("A");
  }, [location.pathname]);

  useEffect(() => {
    // Clear local storage if the navigation is direct
    if (isDirectNavigation) {
      localStorage.removeItem("filter");
      localStorage.removeItem("grantId");
      localStorage.removeItem("expandedIdList");
      localStorage.removeItem("autoExpandIdList");
      localStorage.removeItem("finalPageNumber");
      setIsDirectNavigation(false);
      setAdvancedFilter({});
      setFilter({
            filterTextPermissions: "",
            filterTextNavigators: "",
            activePermissionMappings: false,
            activeNavigatorMappings: false,
            functionalCat: [],
          });
      setCurrentPage(defaultPage);    
    }
    console.log("B");
  }, [isDirectNavigation]);

  useEffect(() => { 
    if (navigatorToFunctionalPermissionSetsStatus === "idle") {
      dispatch(fetchNavigatorToFunctionalPermissionSets());
    }
    if (functionalPermissionSetStatus === "idle") {
      dispatch(fetchFunctionalPermissionSets());
    }
    if (permissionSetCategoryStatus === "idle") {
      dispatch(fetchPermissionSetCategories());
    }
    localStorage.setItem("renderComplete", JSON.stringify(false)); 
  });

  useEffect(() => {
    if (isRefreshing) {
      setIsRefreshing(false);
      window.location.reload();
    }
  }, [isRefreshing]);

  const onClickExpand = (expanded, record) => { 
    dispatch(fetchMultiChildNodes({ expanded, record })).then((res) => {
      const idArray = []; 
      if ( res.type === "navigatorToFunctionalPermissionSets/fetchMultiChildNodes/fulfilled" && res.payload.data) { 
      }
    });
  };

  const onRefreshAutoExpand = (expanded, record) => { 
    localStorage.setItem("childList", JSON.stringify(false));
    dispatch(fetchMultiChildNodes({ expanded, record })).then((res) => {
      const idArray = []; 
      if ( res.type === "navigatorToFunctionalPermissionSets/fetchMultiChildNodes/fulfilled" && res.payload.data) { 
      }
       
    });
  };

  const getAllNavDataHierarchy = (record) => {
    dispatch(fetchNavigatorHierarchy({ record })).then((res) => {
      const navData = res.payload.data; 
    });
  };

  const onCloseUpdateDialog = () => {
    setUpdatedData({});
    //dispatch(fetchNavigatorToFunctionalPermissionSets());
    setIsRefreshing(true);
    
  };

  const onCancelUpdateDialog = () => {
    setUpdatedData({});
    //dispatch(fetchNavigatorToFunctionalPermissionSets());
    //setIsRefreshing(true);
    
  };

  const onClickCell = (data, functionalPermissionSet, status, isSelecter) => { 
    console.log("isSelecter is", isSelecter);

    const fPSName = functionalPermissionSet.name;
    let stateNum = null;
    data.state.forEach((stateThing) => {
      if (stateThing.role == fPSName) {
        console.log("state number is", stateThing.state);
        stateNum = stateThing.state;
        return; 
      }
    });
 
    if (stateNum == 1 || isSelecter == 1) {
      const updatedData = {
        id: data.id,
        navigatorEntryTitle: data.label,
        projection: data.projection,
        role: functionalPermissionSet.name,
        action: "remove",
        openConfirmation: true,
        permissionType: null,
        state: data.state,
        parentId: data.parentId,
      };

      setUpdatedData(updatedData);
    } else if (stateNum == 0 && isSelecter == null) {
      const updatedData = {
        id: data.id,
        navigatorEntryTitle: data.label,
        projection: data.projection,
        role: functionalPermissionSet.name,
        action: "select",
        openConfirmation: true,
        permissionType: null,
        state: data.state,
        data: data,
        functionalPermissionSet: functionalPermissionSet,
        parentId: data.parentId,
      };

      setUpdatedData(updatedData);
    } else if (stateNum == null || isSelecter == 10 || stateNum == 3) {
      const updatedData = {
        id: data.id,
        navigatorEntryTitle: data.label,
        projection: data.projection,
        role: functionalPermissionSet.name,
        action: status,
        openConfirmation: true,
        permissionType: null,
        state: data.state,
        parentId: data.parentId,
      };

      setUpdatedData(updatedData);
    }
  };

  const onClickFilter = (filter) => {
    if (name != undefined) {
      navigate(`/navigator-to-functional-permission-sets`);
      if (filter.filterTextPermissions == "") {
        filter.filterTextPermissions = name;
      }
    }
    setAdvancedFilter(filter);
  };

  const clearFilter = () => {
    setAdvancedFilter({});
    if (name != undefined) {
      navigate(`/navigator-to-functional-permission-sets`);
    }
  };

  const onClickSave = () => {
    let request = {};
    if (updatedData.action === "add") {
      request = {
        navGrantsAdd: [
          {
            id: updatedData.id,
            //projection: updatedData.projection,
            role: updatedData.role,
            grantType: updatedData.permissionType,
          },
        ],
        navGrantsDel: [],
      };
    } else if (updatedData.action === "remove") {
      request = {
        navGrantsAdd: [],
        navGrantsDel: [
          {
            id: updatedData.id,
            //projection: updatedData.projection,
            role: updatedData.role,
            grantType: "",
          },
        ],
      };
    }

    const grantNodeId = updatedData.id ;
    const grantNodeParentId = updatedData.parentId;

    localStorage.setItem("grantId", JSON.stringify({ grantNodeId ,grantNodeParentId}));
    localStorage.setItem("grantIdForFetch", JSON.stringify({ grantNodeId ,grantNodeParentId}));
 
    ////debugger
    //dispatch(updateNavigatorToFunctionalPermissionSets({request,onCloseUpdateDialog,}));
    dispatch( updateBulkNavigatorToFunctionalPermissionSets({ request, onCloseUpdateDialog, }) ); 
  };

  const onPermissionTypeUpdate = (type) => {
    setUpdatedData({
      ...updatedData,
      permissionType: type,
    });
  };

  const loading =
    permissionSetCategoryStatus === "loading" ||
    functionalPermissionSetStatus === "loading" ||
    navigatorToFunctionalPermissionSetsStatus === "loading";

  return (
    <Grid container spacing={2}>
      <UpdateConfirmationDialog
        updatedData={updatedData}
        updatePermissionType={onPermissionTypeUpdate}
        closeDialog={onCloseUpdateDialog}
        onSave={onClickSave}
        onClick={onClickCell}
        onCancelUpdateDialog={onCancelUpdateDialog}
      />
      <Grid item lg={9} md={9} sm={9} xs={9} sx={{ textAlign: "left" }}>
        <h2 className="page-title">
          Navigator - Functional Permission Sets Matrix
        </h2>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: "left" }}>
        <Link className="custom-link" to={"/functional-permission-sets/new"}>
          <Button
            id="c1d38b56-c590-47c4-b9fe-056c1ba739a1"
            fullWidth
            disableElevation
            variant="contained"
            startIcon={<AddIcon />}
          >
            Functional Permission Set
          </Button>
        </Link>
      </Grid>
      <Grid item lg={12}>
        <AdvancedFilter
          onFilter={onClickFilter}
          permissionSetCategories={permissionSetCategories}
          onClearFilter={clearFilter}
          setCollectFilteredNavData={setCollectFilteredNavData}
          isDirectNavigation={isDirectNavigation}
          filter={filter}
          setFilter={setFilter}
        />
      </Grid>

      {/* 
        Temporarily commented out the Legend component due to a change in requirements.
        We decided to remove the legend from the UI for now until further review.
      */}
      {/* <Grid item lg={12}>
        <Legend />
      </Grid> */}

      <Grid item lg={12}>
        <Grid container columns={36}>
          <Grid item lg={36} className="pt-text-x-axis">
            Functional Permission Sets
          </Grid>
          <Grid item lg={1} className="pt-text-y-axis">
            Navigator Entries
          </Grid>
          <Grid item lg={35}>
            <DataTable
              data={navigatorToFunctionalPermissionSets}
              status={navigatorToFunctionalPermissionSetsStatus}
              functionalPermissionSets={functionalPermissionSets}
              filters={advancedFilter}
              onExpandRow={onClickExpand}
              onCellClicked={onClickCell}
              loading={loading}
              getAllNavDataHierarchy={getAllNavDataHierarchy}
              setCollectFilteredNavData={setCollectFilteredNavData}
              collectFilteredNavData={collectFilteredNavData}
              onRefreshAutoExpand={onRefreshAutoExpand}
              setIsDirectNavigation={setIsDirectNavigation}
              isDirectNavigation={isDirectNavigation}
              defaultPage={defaultPage}
              setDefaultPage={setDefaultPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item lg={12}>
        <Legend />
      </Grid> */}
    </Grid>
  );
};

export default NavigatorToFunctionalPermissionSetsMulti;
