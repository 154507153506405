import { Routes, Route, Navigate } from "react-router-dom";
import NoPage from "./pages/NoPage";
import PermissionUpdate from "./pages/FunctionalPermissionSets/Update";
import PermissionsView from "./pages/FunctionalPermissionSets/View";
import EndUserUpdate from "./pages/EndUserRoles/Update";
import EndUserView from "./pages/EndUserRoles/View";
import FunctionalToEndUserListView from "./pages/FunctionalToEndUser/List/View";
import PermissionSetCategoryView from "./pages/PermissionSetCategory/View";
import PermissionSetCategoryUpdate from "./pages/PermissionSetCategory/Update";
import FunctionalToEndUserMatrixView from "./pages/FunctionalToEndUser/Matrix/View";
import UserToEndUserRoles from "./pages/UserToEndUserRoles";
import NavigatorToFunctionalPermissionSets from "./pages/NavigatorFunctionalPermissionSets/View";
import NavigatorToFunctionalPermissionSetsMulti from "./pages/NavigatorFunctionalPermissionSetsMulti/View";
import SignIn from "./pages/SignIn";
import ReportsDashbord from "./pages/Reports";
import UserListView from "./pages/Reports/Users/UserListView/users-index";
import PermissionListView from "./pages/Reports/Permissions/PermissionListView/nfps-index";
import EndUserRoleBreakDownListView from "./pages/Reports/Permissions/PermissionListView/eurb-index";
import ProtectedRoute from "./ProtectedRoute";
import Templates from "./pages/Templates";
import Apps10ToAurenaUpdate from "./pages/Apps10ToAurena/Update"
import Apps10ToAurenaView from "./pages/Apps10ToAurena/View";
import SegregationView from "./pages/Reports/Users/SegregationView/seg-index";
import Apps10ToAurenaSignIn from "./pages/Apps10ToAurena/View/Apps10ToAurenaSignIn"
import AreaView from "./pages/FunctionalAreas/View";
import AreaUpdate from "./pages/FunctionalAreas/Update";
import NavigatorFunctionalAreas from "./pages/NavigatorFunctionalAreas/View";
import ConflictView from "./pages/FunctionalAreaConflicts/View";
import ConflictUpdate from "./pages/FunctionalAreaConflicts/Update";

export const renderRoutes = (routes = []) => {
  return (
    <Routes>
      <Route path={"/"} element={<Navigate to={"/sign-in"} />} />
      <Route path={"/sign-in"} element={<SignIn />} />
      <Route element={<ProtectedRoute />}>
        <Route path={"/functional-permission-sets"} element={<PermissionsView />} />
        <Route path={"/functional-permission-sets/:permissionName"} element={<PermissionUpdate />} />
        <Route path={"/functional-permission-sets/:action/:permissionName"} element={<PermissionUpdate />} />
        <Route path={"/end-user-roles"} element={<EndUserView />} />
        <Route path={"/end-user-roles/:permissionName"} element={<EndUserUpdate />} />
        <Route path={"/end-user-roles/:action/:permissionName"} element={<EndUserUpdate />} /> 
        <Route path={"/functional-permission-to-end-user"} element={<FunctionalToEndUserMatrixView />} />
        <Route  path={"/functional-permission-to-end-user/:type/:name"} element={<FunctionalToEndUserMatrixView />} />
        <Route path={"/404"} element={<NoPage />} />
        <Route path={"*"} element={<NoPage />} />
        <Route path={"/permission-set-categories"} element={<PermissionSetCategoryView />} />
        <Route path={"/permission-set-categories/:categoryId"} element={<PermissionSetCategoryUpdate />} />
        <Route path={"/permission-set-categories/:action/:categoryId"} element={<PermissionSetCategoryUpdate />} />
        <Route path={"/users-to-end-user-roles"} element={<UserToEndUserRoles />} />
        <Route path={"/users-to-end-user-roles/:type/:name"} element={<UserToEndUserRoles />}  /> 
        <Route  path={"/navigator-to-functional-permission-sets"} element={<NavigatorToFunctionalPermissionSetsMulti />}/>
        <Route path={"/navigator-to-functional-permission-sets/:type/:name"} element={<NavigatorToFunctionalPermissionSetsMulti />}/>
        <Route path={"/reports"} element={<ReportsDashbord />} />
        <Route path={"/reports/users"} element={<UserListView />} />
        <Route path={"/reports/users/sod/suppliers"} element={<SegregationView />} />
        <Route path={"/reports/permissions/nfps"} element={<PermissionListView />} />
        <Route path={"/reports/permissions/eurb"} element={<EndUserRoleBreakDownListView />} />
        <Route path={"/predefined-templates"} element={<Templates />} />
        
        {/* Apps10 To Aurena Conversion */}
        <Route path={"apps10-to-aurena/:permissionName"} element={<Apps10ToAurenaUpdate />} />
        <Route path={"apps10-to-aurena/:action/:permissionName"} element={<Apps10ToAurenaUpdate />} />
        <Route path={"apps10-to-aurena"} element={<Apps10ToAurenaView />} />
        <Route path={"apps10-to-aurena/sign-in"} element={Apps10ToAurenaSignIn}/>

        {/* Functional Areas */}
        <Route path={"/functional-area"} element={<AreaView />} />
        <Route path={"/functional-area/:areaName"} element={<AreaUpdate />} />
        <Route path={"/functional-area/:action/:areaName"} element={<AreaUpdate />} />

        {/* Navigator - Functional Areas */} 
        <Route path={"/navigator-to-functional-areas"} element={<NavigatorFunctionalAreas />}/>
        <Route path={"/navigator-to-functional-areas/:type/:name"} element={<NavigatorFunctionalAreas />}/>

        {/* Functional Area Conflicts */}
        <Route path={"/functional-area-conflict"} element={<ConflictView />} />
        <Route path={"/functional-area-conflict/:areaName"} element={<ConflictUpdate />} />
        <Route path={"/functional-area-conflict/:action/:areaName"} element={<ConflictUpdate />} />

      </Route>
    </Routes>
  );
};

const routes = [
  {
    id: "nvitm001",
    title: "Permission Set Categories",
    target: "permission-set-categories",
    disabled: false,
  },
  {
    id: "nvitm002",
    title: "Functional Permission Sets",
    target: "functional-permission-sets",
  },
  {
    id: "nvitm003",
    title: "Navigator to Functional Permission Sets",
    target: "navigator-to-functional-permission-sets",
  },
  {
    id: "nvitm004",
    title: "End-User Roles",
    target: "end-user-roles",
  },
  {
    id: "nvitm005",
    title: "Functional Permission Sets End-User Roles",
    target: "functional-permission-to-end-user",
  },
  {
    id: "nvitm006",
    title: "Users to End-User Roles",
    target: "users-to-end-user-roles",
  },
  // Temporarily commented ---
  // {
  //   id: "nvitm007",
  //   title: "Functional Areas",
  //   target: "functional-area",
  // },
  // {
  //   id: "nvitm008",
  //   title: "Navigator to Functional Areas",
  //   target: "navigator-to-functional-areas",
  // },
  // {
  //   id: "nvitm009",
  //   title: "Functional Area Conflicts",
  //   target: "functional-area-conflict",
  // },
  {
    id: "nvitm010",
    title: "Reporting",
    target: "reports",
  },
  // Temporarily commented ---
  // {
  //   id: "nvitm011",
  //   title: "APPS10 to Aurena",
  //   target: "apps10-to-aurena",
  // },
  {
    id: "nvitm0012",
    title: "Predefined Templates",
    target: "predefined-templates",
  },
];

export default routes;
