import { Pagination, Table } from "antd";
import {
  Grid,
  Button,
  Autocomplete,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  CheckCircle,
  Cancel,
  ArrowDropDown,
  RadioButtonChecked,
} from "@mui/icons-material";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SecurityIcon from "@mui/icons-material/Security";
import { Tooltip } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  fetchNavigatorToFunctionalPermissionSets,
  selectAllNavigatorToFunctionalPermissionSets,
  fetchMultiChildNodes,
  fetchNavigatorHierarchy,
  updateNavigatorToFunctionalPermissionSets,
  updateBulkNavigatorToFunctionalPermissionSets,
} from "../../../redux/reducers/navigatorToFunctionalPermissionSetsMultiSlice";

const styles = {
  readOnly: {
    textAlign: "center",
  },
  fullGrant: {
    textAlign: "center",
  },
  customGrant: {
    textAlign: "center",
  },
};

const DataTable = ({
  data,
  functionalAreas,
  filters,
  onExpandRow,
  onCellClicked,
  loading = false,
  onRefreshAutoExpand,
  defaultPage,
  currentPage,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pageNo = localStorage.getItem("finalPageNumber-NFA");

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [processedIds, setProcessedIds] = useState(new Set());
  const [refreshed, setRefreshed] = useState(false);
  const [tableLoadingWithGrant, setTableLoadingWithGrant] = useState(false);
  const [checkGrantRemove, setCheckGrantRemove] = useState(false);
  const [expandRows, setExpandRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [expandedIds, setExpandedIds] = useState([]);
  const [rowIdsToAutoExpand, setRowIdsToAutoExpand] = useState([]);
  const { name } = useParams();
  const [finalPageNumber, setFinalPageNumber] = useState(
    pageNo ? pageNo : defaultPage
  );

  if (name != undefined) {
    filters.filterTextAreas = name;
  }

  const [height, setHeight] = useState(0);
  const ref = useRef(this);

  const updateHeight = () => {
    if (ref.current) {
      const newHeight = ref.current.clientWidth;
      setHeight(newHeight);
    }
  };

  useLayoutEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    const idArray = [];
    dataSource
      ?.slice((page - 1) * pageSize, page * pageSize)
      .forEach((data) => idArray.push(data.id));
  }, [page]);

  const renderColumns = () => {
    let filteredData = functionalAreas;

    if (filters.activeAreaMappings) {
      let filteredRoles = data
        .map((item) =>
          item.state
            .filter((stateItem) => stateItem.state !== "3")
            .map((roleItem) => roleItem.role)
        )
        .flat();

      if (filters.filterTextNavigators && filters.filterTextNavigators !== "") {
        let filteredDataWithFilterTextNavigators = data.filter((item) =>
          item.label.toUpperCase().includes(filters.filterTextNavigators)
        );
        filteredRoles = filteredDataWithFilterTextNavigators
          .map((item) =>
            item.state
              .filter((stateItem) => stateItem.state !== "3")
              .map((roleItem) => roleItem.role)
          )
          .flat();
      }

      filteredData = filteredData.filter((functionalArea) =>
        filteredRoles.includes(functionalArea.name)
      );
    }

    if (filters.filterTextAreas && filters.filterTextAreas !== "") {
      filteredData = filteredData.filter((data) =>
        data.name.toUpperCase().includes(filters.filterTextAreas)
      );
    }

    const columns = [
      {
        title: "",
        dataIndex: "label",
        key: "label",
        fixed: "left",
        className: "normal-header",
        render: (a, data) => {
          return {
            children: <div className="scrollable">{data?.label}</div>,
            props: {
              style: {
                minWidth: "500px",
                width: "500px",
                maxHeight: "40px",
                height: "40px",
              },
            },
          };
        },
      },
    ];

    filteredData.forEach((functionalArea) => {
      columns.push({
        title: (
          <Tooltip title={functionalArea.description} arrow placement="top">
            <div className="inner-box">{functionalArea.name}</div>
          </Tooltip>
        ),
        dataIndex: "areas",
        key: functionalArea.id,
        render: (navigatorRoles, data) => {
          const state = data.state;
          const type = data.type;
          const hasState = Array.isArray(state);
          const hasType = Array.isArray(type);

          const hasState3 =
            hasState &&
            state.some(
              (role) =>
                role.role === functionalArea.name &&
                role.state === "3" &&
                role.id === data.id
            );

          const hasCompleteGrant =
            hasState &&
            state.some(
              (role) =>
                role.role === functionalArea.name &&
                role.state === "1" &&
                role.id === data.id
            );
          const hasNotCompleteGrant =
            hasState &&
            state.some(
              (role) =>
                role.role === functionalArea.name &&
                role.state === "0" &&
                role.id === data.id
            );

          const status =
            navigatorRoles &&
            navigatorRoles.find((item) => item.role === functionalArea.name);

          let circleColor = "#8a54ba";
          let radioButtonColor = "#8a54ba";

          let iconComponent;

          if (hasCompleteGrant && !hasState3) {
            iconComponent = <CheckCircle style={{ color: circleColor }} />;
          } else if (hasNotCompleteGrant && hasType) {
            iconComponent = (
              <>
                <div style={{ alignItems: "center", textAlign: "center" }}>
                  <RadioButtonChecked
                    style={{
                      color: radioButtonColor,
                      fontSize: "13px",
                      textAlign: "center",
                    }}
                  />
                </div>
              </>
            );
          }

          return {
            props: {
              style: { minWidth: "40px", width: "40px", paddingLeft: "5px" },
              onClick: async () => {
                onCellClicked(data, functionalArea, status ? "remove" : "add");
                localStorage.setItem("finalPageNumber-NFA", finalPageNumber);
              },
            },
            children: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {iconComponent}
              </div>
            ),
          };
        },
        className: "hover",
        width: 40,
      });
    });

    if (columns.length * 40 + 500 < height) {
      columns.push({
        title: "",
        render: () => {
          return {
            props: {
              style: { minWidth: 1 },
            },
          };
        },
      });
    }

    localStorage.setItem("renderComplete", JSON.stringify(true));
    return columns;
  };

  const getFilteredData = () => {
    let filteredData = data;

    if (filters.activeNavigatorMappings) {
      filteredData = filteredData.filter((data) => data.state.length > 0);

      if (filters.filterTextAreas && filters.filterTextAreas.length > 0) {
        filteredData = filteredData.filter((item) =>
          item.state.some(
            (stateItem) =>
              stateItem.role.includes(filters.filterTextAreas) &&
              stateItem.state != "3"
          )
        );
      }

      filteredData = filteredData.filter((item) =>
        item.state.some((node) =>
          functionalAreas.some(
            (permission) =>
              permission.name.trim() === node.role.trim() && node.state != "3"
          )
        )
      );
    }

    if (filters.filterTextNavigators && filters.filterTextNavigators !== "") {
      filteredData = filteredData.filter((data) =>
        data?.label.toUpperCase().includes(filters.filterTextNavigators)
      );
    }

    return filteredData;
  };

  const dataSource = getFilteredData();

  const dataColomns = renderColumns();

  const totalItems = dataSource.length;

  const defaultFirstItemIndex = (defaultPage - 1) * 10;
  const defaultPageItems = dataSource.slice(
    defaultFirstItemIndex,
    defaultFirstItemIndex + 10
  );
  const [displayedItems, setDisplayedItems] = useState(defaultPageItems);

  useEffect(() => {
    const grantIdData = JSON.parse(localStorage.getItem("grantId-NFA"));
    const expandedIdListData = JSON.parse(
      localStorage.getItem("expandedIdList-NFA")
    );

    if (expandedIdListData) {
      setExpandedIds(expandedIdListData);
    }

    if (grantIdData) {
      const storedFinalPageNumber = localStorage.getItem("finalPageNumber-NFA");
      const defaultPageAfterGrant = storedFinalPageNumber
        ? parseInt(storedFinalPageNumber)
        : 1;
      setCurrentPage(defaultPageAfterGrant);

      setCheckGrantRemove(true);
      const { grantNodeParentId, grantNodeId } = grantIdData;

      const autoExpandIdList = [];
      const visitedNodes = new Set();

      const findNodeParentId = (nodeId) => {
        const foundObject = expandedIdListData?.find(
          (item) => item.nodeId === nodeId
        );
        if (foundObject) {
          // Check if the node ID is already visited
          if (!visitedNodes.has(foundObject.nodeId)) {
            autoExpandIdList.unshift(foundObject.nodeId);
            visitedNodes.add(foundObject.nodeId);
          }

          const parentId =
            foundObject.nodeParentId === "-2" ||
            foundObject.nodeParentId === "-4"
              ? foundObject.nodeId
              : foundObject.nodeParentId;

          if (parentId === nodeId) {
            autoExpandIdList.push(parentId);
            return;
          }

          // Check if the parent ID is already visited
          if (!visitedNodes.has(parentId)) {
            autoExpandIdList.push(parentId);
            visitedNodes.add(parentId);
          }

          findNodeParentId(parentId);
        }
      };

      const recentGrantRemoveParentId =
        grantNodeParentId === "-2" || grantNodeParentId === "-4"
          ? grantNodeId
          : grantNodeParentId;

      findNodeParentId(recentGrantRemoveParentId);

      autoExpandIdList.reverse();

      if (autoExpandIdList.length > 1) {
        autoExpandIdList.shift();
      }

      setRowIdsToAutoExpand(autoExpandIdList);
      loading = true;
      localStorage.setItem(
        "autoExpandIdList-NFA",
        JSON.stringify(autoExpandIdList)
      );
      loading = false;
    } else {
      console.error("Grant ID data not found in local storage");
    }
    console.log("D");
  }, []);

  const autoExpandIdListFromLocalStorage = JSON.parse(
    localStorage.getItem("autoExpandIdList-NFA")
  );

  const handleExpandedIdList = (record) => {
    if (record) {
      const nodeId = record.id;
      const nodeParentId = record.parentId;

      const isDuplicate = expandedIds?.some((item) => item.nodeId === nodeId);

      if (!isDuplicate) {
        const updatedExpandedIds = [...expandedIds, { nodeId, nodeParentId }];
        localStorage.setItem(
          "expandedIdList-NFA",
          JSON.stringify(updatedExpandedIds)
        );
        setExpandedIds(updatedExpandedIds);
      }
    } else {
      console.error("Null record passed to handleExpandedIdList function");
    }
  };

  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      autoExpandIdListFromLocalStorage &&
      autoExpandIdListFromLocalStorage.length > 0
    ) {
      loading = true;
      const apiPromises = autoExpandIdListFromLocalStorage.map(
        async (autoExpandId) => {
          const recordToExpand = data.find(
            (record) => record.id === autoExpandId
          );

          if (!recordToExpand) {
            await processServingIds(autoExpandId);
          } else {
            await handleRefreshAutoExpand(recordToExpand);
          }
        }
      );
      loading = false;
    }
  }, [data, autoExpandIdListFromLocalStorage]);

  const processServingIds = (autoExpandId) => {
    const remainingIds = [...autoExpandIdListFromLocalStorage];
    const index = remainingIds.indexOf(autoExpandId);
    if (index !== -1) {
      const servingIds = remainingIds?.slice(0, index + 1);
      const firstServingId = servingIds[0];
      const recordToServe = data?.find(
        (record) => record.id === firstServingId
      );
      if (!recordToServe) {
        handleRefreshAutoExpand(null);
        return;
      }
      if (servingIds.length > 1 && recordToServe && recordToServe.type) {
        let nextRecord = { type: recordToServe.type };
        for (let i = 1; i < servingIds.length; i++) {
          const idToFind = servingIds[i];
          nextRecord = nextRecord.type.find((record) => record.id === idToFind);
          if (
            !nextRecord ||
            nextRecord.type[0] === "menu" ||
            nextRecord.type[0] === null
          )
            break;
        }
        if (nextRecord) {
          handleRefreshAutoExpand(nextRecord);
        }
      }
    }
  };

  const handleRefreshAutoExpand = (record) => {
    if (record === null) {
      return null;
    }

    if (!refreshed || !processedIds.has(record.id)) {
      onRefreshAutoExpand(true, record);
      setProcessedIds(new Set([...processedIds, record.id]));
      setRefreshed(true);
    }
  };

  const handleFinalPageChange = (page) => {
    setFinalPageNumber(page);
    setCurrentPage(page);
  };

  //Initial row height
  const baseRowHeight = 41;

  let totalHeightForTable;

  // Function to dynamically calculate table height
  function updateTableHeight() {
    const zoomLevel = 1 / window.devicePixelRatio;
    const viewportHeight = window.innerHeight;
    const headerHeight = 250;
    const availableHeight = viewportHeight - headerHeight;

    // Dynamically calculate row height based on zoom level
    const rowHeight = baseRowHeight * zoomLevel;

    // Calculate the total height for the table to fill the space
    totalHeightForTable = availableHeight;

    console.log({
      zoomLevel,
      rowHeight,
      availableHeight,
      totalHeightForTable,
    });

    // Update table's height dynamically
    const table = document.querySelector(
      "#d6cc0496-b10e-40e1-b09d-afed903f7f2d"
    );
    if (table) {
      table.style.height = `${totalHeightForTable}px`;
    }
  }

  // Call the function initially and on resize
  window.addEventListener("resize", updateTableHeight);
  updateTableHeight();

  return (
    <div ref={ref} className="permission-matrix" style={{ textAlign: "right" }}>
      <Table
        id="d6cc0496-b10e-40e1-b09d-afed903f7f2d"
        key={data}
        loading={loading}
        columns={renderColumns()}
        dataSource={dataSource}
        bordered
        size="small"
        indentSize={10}
        pagination={false}
        style={{
          width: "calc(100vw - 400px)",
          marginBottom: -55,
          overflowY: "visible",
        }}
        scroll={{
          x: true,
          y: false,
        }}
        sticky={{ offsetHeader: 100 }}
        rowKey={"id"}
        childrenColumnName="type"
        defaultExpandedRowKeys={autoExpandIdListFromLocalStorage}
        onExpand={(expanded, record) => {
          onExpandRow(expanded, record);
          handleExpandedIdList(record);
        }}
      />
    </div>
  );
};

export default DataTable;
