import { Search, FilterAlt, Close as CloseIcon } from "@mui/icons-material";
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Autocomplete,
  Chip,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPermissionSetCategories,
  selectAllPermissionSetCategories,
} from "../../../redux/reducers/permissionSetCategorySlice";

// const getFilteredData = (data, filter, catfilter) => {
//     return data?.filter(dataItem => {
//         if (filter && filter.length > 0) {
//             return dataItem.name && dataItem.name.toLowerCase().includes(filter.toLowerCase());
//         } else if (catfilter && catfilter.length > 0) {
//             return dataItem.category && dataItem.category.toLowerCase().includes(catfilter[0].toLowerCase());
//         } else {
//             return true;
//         }
//     });
// }

const DataTable = ({ columns, data, loading }) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const permissionSetCategories = useSelector(selectAllPermissionSetCategories);
  const permissionSetCategoryStatus = useSelector(
    (state) => state.permissionSetCategories.status
  );
  // const [filter, setFilter] = useState("");
  // const [catfilter, setCatFilter] = useState([]);
  const [filter, setFilter] = useState({
    filterTextPermissions: "",
    functionalCat: [],
  });
  const [advancedFilter, setAdvancedFilter] = useState({});

  // Function to calculate the page size based on available height
  const calculatePageSize = () => {
    const rowHeight = 52; // typical row height for DataGrid
    const headerHeight = 56; // height of DataGrid header
    const searchBarHeight = 64; // height of the search bar and button row
    const padding = 143; // extra padding
    const availableHeight =
      window.innerHeight - headerHeight - searchBarHeight - padding;
    return Math.floor(availableHeight / rowHeight);
  };
  useEffect(() => {
    if (permissionSetCategoryStatus === "idle") {
      dispatch(fetchPermissionSetCategories());
    }
    const handleResize = () => {
      setPageSize(calculatePageSize());
    };

    // Set initial page size
    handleResize();

    // Adjust page size on window resize
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getFilteredData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    if (filter?.functionalCat && filter?.functionalCat.length > 0) {
      data = data?.filter((v) => v.category?.includes(filter?.functionalCat));
    }

    if (filter?.filterTextPermissions && filter?.filterTextPermissions !== "") {
      data = data?.filter((v) =>
        v.name.includes(filter?.filterTextPermissions)
      );
    }

    return data;
  };

  const filteredData = getFilteredData(data);

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const clear = () => {
    setFilter({ filterTextPermissions: "", functionalCat: [] });
    setAdvancedFilter({});
  };

  const onFilterUpdate = (type, value) => {
    switch (type) {
      case "filterTextPermissions": {
        setFilter({ ...filter, filterTextPermissions: value });
        return;
      }
      case "functionalCat": {
        setFilter({ ...filter, functionalCat: value });
        return;
      }
      default: {
        console.log("No type defined");
      }
    }
  };

  const onClickFilter = (filter) => {
    getFilteredData(data);
  };

  const handleDeleteFunctionalCat = (category) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      functionalCat: prevFilter.functionalCat.filter((cat) => cat !== category),
    }));
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
            id="6193b3a7-75ca-43cf-83de-2cf1dc0bb3d0"
            size="small"
            fullWidth
            value={filter.filterTextPermissions}
            label="Search by Name"
            onChange={(e) =>
              onFilterUpdate("filterTextPermissions", e.target.value)
            }
            onInput={toInputUppercase}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "#000000" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* <Grid item lg={5} md={4} sm={12} xs={12}>
                    <Autocomplete
                        id="category"
                        size="small"
                        options={permissionSetCategories.map((option) => option.name)}
                        onChange={(event, value) => {
                        setCatFilter(value ? [value] : []);
                        setFilter('');
                        }}
                        value={catfilter[0] || null}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search by Category"
                            InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            }}
                        />
                        )}
                    />
                </Grid> */}

        {/* <Grid item lg={4}>
                <FormControl fullWidth size='small'>
                    <InputLabel id="func-cat-label"> Search by Category</InputLabel>
                    <Select
                        //multiple
                        fullWidth
                        label="Search by Category" 
                        value={filter.functionalCat}
                        style={{ textAlign: 'left' }}
                        onChange={(e) => onFilterUpdate('functionalCat', e.target.value)} 
                    >
                        {permissionSetCategories && permissionSetCategories.map((category) => (
                            <MenuItem
                                key={category.name}
                                value={category.name}
                            >
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid> */}

        <Grid item lg={4}>
          <FormControl fullWidth size="small">
            <Autocomplete
              id="69a31255-9aec-470b-a121-3cc39e7b78d4"
              size="small"
              options={permissionSetCategories.map((category) => category.name)}
              value={filter.functionalCat}
              onChange={(event, newValue) => {
                onFilterUpdate("functionalCat", newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option}
                    label={option}
                    {...getTagProps({ index })}
                    deleteIcon={
                      <CloseIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={() => handleDeleteFunctionalCat(option)}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Search by Category" />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <Button
            id="7767fc6c-4b8c-4829-ade1-4bb41a856b6c"
            onClick={() => clear()}
            fullWidth
            disableElevation
            variant="outlined"
            startIcon={<FilterAlt />}
          >
            Clear Filter
          </Button>
        </Grid>
        <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
          <Link className="custom-link" to={"/end-user-roles/new"}>
            <Button
              id="143081cb-c3e9-43fe-a73d-dc9dba7808b0"
              fullWidth
              disableElevation
              variant="contained"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Link>
        </Grid>
      </Grid>

      {/* <DataGrid
        id="0d933a4f-f467-43c9-b09b-60d6246f670f"
        columns={columns}
        rows={filteredData}
        sx={{ marginTop: 1 }}
        autoHeight
        checkboxSelection={false}
        pageSize={10}
        loading={loading === "loading"}
        getRowId={(row) => row.name}
        rowsPerPageOptions={[5]}
      /> */}

      <Box sx={{ marginTop: 0, height: "calc(100vh - 200px)" }}>
        <DataGrid
          columns={columns}
          rows={filteredData}
          checkboxSelection={false}
          hideFooterPagination
          loading={loading === "loading"}
          getRowId={(row) => row.name}
          disableColumnMenu
          headerHeight={56}
          rowHeight={52}
          sx={{
            border: "none",
          }}
        />
      </Box>
    </div>
  );
};
export default DataTable;
