import { Search } from '@mui/icons-material';
import { Grid, TextField, InputAdornment, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import { fetchExistingCloudDataLog } from "../../../redux/reducers/app10ConversionSlice";
import { useSelector, useDispatch } from "react-redux";

const getFilteredData = (data, filter) => {
    return (filter && filter.length > 0) ?
        data.filter(data => data.role && data.role.length > 0 && data.role.toLowerCase().includes(filter.toLowerCase())) : data;
}

const CloudDataTable = ({ columns, data=[], loading,isLoggedIn }) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState();

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const filteredData = getFilteredData(data, filter);

    console.log("FILTERING ", data, filter, filteredData)

    const handleExistingCloudDataLog = () => {
      dispatch(fetchExistingCloudDataLog());
    };
    
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item lg={10} md={2} sm={12} xs={12}>
                    <TextField
                        id='1aa6f349-a49d-48cb-880d-002bc2ebe8c5'
                        size='small'
                        fullWidth
                        label='Search by Permission Set'
                        onChange={(e) => setFilter(e.target.value)}
                        onInput={toInputUppercase}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search sx={{ color: '#000000' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid> 
                <Grid item lg={2} md={2} sm={12} xs={12}>
                    <Button 
                        onClick={() => handleExistingCloudDataLog()}
                        fullWidth
                        disableElevation
                        variant="outlined" 
                        disabled={!isLoggedIn && !(localStorage.getItem("conversionUserToken"))}
                        >
                        Load Previous Cloud Data
                    </Button>
                </Grid>
            </Grid>

            <DataGrid
                columns={columns}
                rows={filteredData}
                sx={{ marginTop: 1 }}
                autoHeight
                checkboxSelection={false}
                pageSize={10}
                loading={loading==='loading'}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5]}
            />
        </div>
    )
}
export default CloudDataTable;