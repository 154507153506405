import * as React from "react";
import {
  Divider,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Toolbar,
  Typography,
  Tooltip,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import routes from "../../routes";
import { renderRoutes } from "../../routes";
import SignIn from "../../pages/SignIn";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/authUserSlice";
import { fetchUserCount } from "../../redux/reducers/userSlice";
import {closeActionStatus,selectActionStatus,} from "../../redux/reducers/actionStatusSlice";
import ActionStatusDialog from "../../components/ActionStatusDialog";
import logo from "../../static/images/logo-colored-white.png"; 
import { useEffect } from "react";

// Import the new icons
import EndUserRolesIcon from "../../static/images/End-User-Roles.png";
import FunctionalPermissionEndUserRolesIcon from "../../static/images/Funtional-permision-End-user-roles.png";
import FunctionalPermissionSetsIcon from "../../static/images/Funtional-Permission-Sets.png";
import NavigatorFunctionalPermissionSetsIcon from "../../static/images/Navigator-Funtional-Permission-Sets.png";
import PermissionSetCategoriesIcon from "../../static/images/Permission-set-Catagories.png";
import PredifindTemplateIcon from "../../static/images/Predifind-template.png";
import ReportingIcon from "../../static/images/Reporting.png";
import UsersEndUserRolesIcon from "../../static/images/Users-End-User-Roles.png";
import Apps10ToAurenaIcon from "../../static/images/Apps10-to-Aurena.png";
import FunctionalAreasIcon from "../../static/images/Functional-Areas.png";
import FunctionalAreasConflictIcon from "../../static/images/Conflict.png";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#323a46",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppLayout = () => {
  const [open, setOpen] = React.useState(true);
  const [userCount, setUserCount] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const { userInfo, userToken } = useSelector((state) => state.authUser);
  const actionStatus = useSelector((state) => selectActionStatus(state));

  const applicationUrl = localStorage.getItem("applicationUrl") ? localStorage.getItem("applicationUrl") : null;

  const appVersion = localStorage.getItem("appVersion") ? localStorage.getItem("appVersion") : null;

    useEffect(() => {
    const utoken = localStorage.getItem("userToken");
    const fetchUsersCount = async () => {
      setLoading(true);
      try { 
        await dispatch(fetchUserCount()); 
        await new Promise((resolve) => setTimeout(resolve, 1000)); 
        
        const count = localStorage.getItem("userCount") || 0;
        
        setUserCount(count);
      } catch (error) {
        console.error("Failed to fetch user count:", error);
      } finally {
        setLoading(false);
      }
    };

    if ((location.pathname !== "/sign-in") && utoken && appVersion !="APPS10") {
      fetchUsersCount();
    }
  }, [dispatch, location.pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch({ type: "USER_LOGOUT" });
  };

const getUpdatedRoutes = () => {
    const updatedRoutes = [...routes]; 

    if (appVersion === 'APPS10') {
      updatedRoutes.splice(updatedRoutes.length - 1, 0, {
        id: "nvitm009",
        title: "APPS10 to Aurena",
        target: "apps10-to-aurena",
      });
    }

    if (appVersion === 'CLOUD') {
      updatedRoutes.splice(updatedRoutes.length - 2, 0, {
        id: "nvitm007",
        title: "Functional Areas",
        target: "functional-area",
      },
      {
        id: "nvitm008",
        title: "Navigator to Functional Areas",
        target: "navigator-to-functional-areas",
      },
      {
        id: "nvitm009",
        title: "Functional Area Conflicts",
        target: "functional-area-conflict",
      },
    );
    }

    return updatedRoutes;
  };

  const renderNavItems = () => {
    const items = [];
    const updatedRoutes = getUpdatedRoutes(); 

    updatedRoutes.forEach((item, index) => {
      const iconMap = {
        "Permission Set Categories": PermissionSetCategoriesIcon,
        "Functional Permission Sets": FunctionalPermissionSetsIcon,
        "Navigator to Functional Permission Sets": NavigatorFunctionalPermissionSetsIcon,
        "End-User Roles": EndUserRolesIcon,
        "Functional Permission Sets End-User Roles": FunctionalPermissionEndUserRolesIcon,
        "Users to End-User Roles": UsersEndUserRolesIcon,
        "Reporting": ReportingIcon,
        "Predefined Templates": PredifindTemplateIcon,
        "APPS10 to Aurena": Apps10ToAurenaIcon,
        "Functional Areas": FunctionalAreasIcon,
        "Navigator to Functional Areas": NavigatorFunctionalPermissionSetsIcon,
        "Functional Area Conflicts": FunctionalAreasConflictIcon,
      };
      const itemContent = (
        <ListItemButton disabled={item.disabled} key={item.id}>
          <ListItemIcon>
            <img
              src={iconMap[item.title]}
              alt={item.title}
              style={{ height: "24px", width: "24px" }}
            />
          </ListItemIcon>
          <ListItemText sx={{ color: "#ffffff" }} primary={item.title} />
        </ListItemButton>
      );
      items.push(
        <ListItem
          disablePadding
          key={item.id}
          selected={location.pathname.split("/").includes(item.target)}
        >
          {item.disabled ? (
            <span className="custom-link">{itemContent}</span>
          ) : (
            <Link className="custom-link" to={item.target}>
              {itemContent}
            </Link>
          )}
        </ListItem>
      );
    });
    return items;
  };

  const onCloseActionStatus = () => {
    dispatch(closeActionStatus());
  };

  return location.pathname === "/sign-in" ? (
    <SignIn />
  ) : (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ActionStatusDialog
        actionStatus={actionStatus}
        handleClose={onCloseActionStatus}
      />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            <>
              <img src={logo} height="30px" alt="logo" />
            </>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => console.log("Nav Clicked")}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            IFS Security Expert
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0, marginRight: 1 }}>
            <Button
              onClick={() => handleLogout()}
              variant="contained"
              startIcon={<LogoutIcon />}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        classes={{ paper: "custom-drawer-paper" }}
        PaperProps={{ sx: { whiteSpace: open ? "pre-wrap" : "no-wrap" } }}
      >
        <DrawerHeader>
          {open && (
            <>
              <Box sx={{ pl: "1px" }}>
                {"  "}
                <img src={logo} height="30px" alt="logo" />
              </Box>
            </>
          )}
          <IconButton onClick={handleDrawerClose} sx={{ color: "#ffffff" }}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>{renderNavItems()}</List>
        {open && (
          <Box
            sx={{
              position: "relative",
              marginTop: "auto",
              bottom: "20px",
              left: "20px",
              color: "#ffffff",
            }}
          >
            <Typography sx={{ textAlign: "left", fontSize: "small" }}>
              <b>IFS Version:</b> {appVersion}
            </Typography>
            <Typography
              sx={{ textAlign: "left", left: "20px", fontSize: "small" }}
            >
              <b>{applicationUrl}</b>
            </Typography>
            <Typography sx={{ textAlign: "left", fontSize: "small" }}>
              <b>Full User Count:</b> {(localStorage.getItem("userCount")) ? (localStorage.getItem("userCount")) : (loading ? "Loading" :  userCount)}
            </Typography>
          </Box>
        )}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {renderRoutes()}
      </Box>
    </Box>
  );
};

export default AppLayout;
