import { Grid } from "@mui/material";
import { Tooltip } from "@mui/material";
import { Table } from "antd";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

const DataTable = ({
  functionalPermissionSets = [],
  loading,
  data = [],
  filters,
  onClickCell,
}) => {
  const navigate = useNavigate();

  const { name, type } = useParams();
  if (name != undefined) {
    if (type == "functionalPermissionSets") {
      filters.filterTextPermissions = name;
    } else {
      filters.filterTextRoles = name;
    }
  }

  const [height, setHeight] = useState(0);
  const ref = useRef(this);

  const updateHeight = () => {
    if (ref.current) {
      const newHeight = ref.current.clientWidth;
      setHeight(newHeight);
    }
  };

  useLayoutEffect(() => {
    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const renderColumnsA = () => {
    const filteredColumns = data;

    const columns = [
      {
        title: "",
        dataIndex: "name",
        className: "normal-header",
        key: "name",
        fixed: "left",
        render: (e, data) => {
          const tooltipTitle = data.description ? `${data.description}` : null;

          return {
            children: tooltipTitle ? (
              <Tooltip title={tooltipTitle} arrow placement="top">
                <div>{data.name}</div>
              </Tooltip>
            ) : (
              <div>{data.name}</div>
            ),
            props: {
              style: {
                minWidth: "150px",
                width: "150px",
                maxHeight: "40px",
                height: "40px",
              },
              onClick: () => {
                data?.name &&
                  navigate(
                    `/navigator-to-functional-permission-sets/functionalPermissions/${data?.name}`
                  );
              },
            },
          };
        },
      },
    ];

    filteredColumns.forEach((columnData) => {
      columns.push({
        title: (
          <Tooltip title={columnData.description} arrow placement="top">
            <div className="inner-box">{columnData.endUserRole}</div>
          </Tooltip>
        ),
        dataIndex: "endUserRole",
        key: "endUserRole",
        category: columnData?.category,
        fpsGrants: columnData?.functionalPermissionSets,
        render: (endUserRole, data) => {
          const status =
            columnData?.functionalPermissionSets?.find(
              (value) => value.name === data.name
            ) !== undefined;
          return {
            props: {
              style: {
                backgroundColor: status && "#06B6A2",
                textAlign: "left",
                maxWidth: "40px",
                minWidth: "40px",
                width: "40px",
                minHeight: "40px",
                paddingTop: "5px",
                paddingBottom: "0px",
              },
              onClick: () =>
                onClickCell(columnData, data, status ? "remove" : "add"),
            },
            children: status && <CheckCircle />,
          };
        },
        className: "hover",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              column?.title &&
                navigate(
                  `/users-to-end-user-roles/endUserRoles/${column?.title?.props?.children?.props?.children}`
                );
            },
          };
        },

        width: 40,
      });
    });

    if (columns.length * 40 + 200 < height) {
      columns.push({
        title: "",
        fixed: "left",
        render: () => {
          return {
            props: {
              style: { minWidth: 1 },
            },
          };
        },
      });
    }

    return columns;
  };

  const columns = renderColumnsA();

  const getFilteredColumns = () => {
    let filteredData = columns;

    if (filters.filterTextRoles && filters.filterTextRoles !== "") {
      filteredData = filteredData.filter((column, index) => {
        if (index === 0) return true;

        let titleText;
        if (React.isValidElement(column.title) && column.title.props) {
          titleText = column?.title?.props?.children?.props?.children;
        } else if (typeof column.title === "string") {
          titleText = column.title;
        } else {
          return false;
        }

        return (
          titleText.includes(filters.filterTextRoles) ||
          titleText === "" ||
          titleText === "Actions"
        );
      });
    }

    if (filters.endUserCat && filters.endUserCat.length > 0) {
      filteredData = filteredData.filter(
        (column) =>
          column?.title === "" || filters.endUserCat.includes(column?.category)
      );
    }

    if (filters.activeRolesMappings) {
      const validRoleNames = new Set(
        functionalPermissionSets.map((row) => row.name)
      );

      if (
        filters.filterTextPermissions &&
        filters.filterTextPermissions !== ""
      ) {
        filteredData = filteredData.filter((column) => {
          if (column?.title === "") return true;

          return column?.fpsGrants?.some((grant) =>
            grant.name.includes(filters.filterTextPermissions)
          );
        });
      } else {
        filteredData = filteredData.filter((column) => {
          if (column?.title === "") return true;

          return column?.fpsGrants?.some((grant) =>
            validRoleNames.has(grant.name)
          );
        });
      }
    }

    if (filteredData.length * 40 + 250 < height) {
      filteredData.push({
        title: "",
        render: () => {
          return {
            props: {
              style: { minWidth: 1 },
            },
          };
        },
      });
    }

    return filteredData;
  };

  const getFilteredRows = (filteredColumns) => {
    let filteredData = functionalPermissionSets;

    if (filters.filterTextPermissions && filters.filterTextPermissions !== "") {
      filteredData = filteredData.filter((column) =>
        column.name.includes(filters.filterTextPermissions)
      );
    }

    if (filters.functionalCat && filters.functionalCat.length > 0) {
      filteredData = filteredData.filter((data) =>
        filters.functionalCat.includes(data.category)
      );
    }

    if (filters.activePermissionMappings) {
      const availableFPSets = new Set();

      filteredColumns.forEach((column) => {
        if (column.fpsGrants && Array.isArray(column.fpsGrants)) {
          column.fpsGrants.forEach((grant) => {
            availableFPSets.add(grant.name);
          });
        }
      });

      filteredData = filteredData.filter((fps) =>
        availableFPSets.has(fps.name)
      );

      let filteredRoles = data
        .map((item) =>
          item.functionalPermissionSets.map((roleItem) => roleItem.name)
        )
        .flat();

      filteredData = filteredData.filter((endUserRole) =>
        filteredRoles.includes(endUserRole.name)
      );
    }

    return filteredData;
  };

  // Initial row height
  const baseRowHeight = 41;

  function updateTableHeight() {
    const zoomLevel = 1 / window.devicePixelRatio;
    const viewportHeight = window.innerHeight;
    const headerHeight = 380;
    const availableHeight = viewportHeight - headerHeight;

    const rowHeight = baseRowHeight * zoomLevel;
    const rowsToFit = Math.floor(availableHeight / rowHeight);
    const totalHeightForTable = rowsToFit * rowHeight;

    console.log({
      zoomLevel,
      rowHeight,
      viewportHeight,
      availableHeight,
      rowsToFit,
      totalHeightForTable,
    });

    return totalHeightForTable;
  }

  const filteredColumns = getFilteredColumns();
  const filteredRows = getFilteredRows(filteredColumns);

  window.addEventListener("resize", updateTableHeight);
  updateTableHeight();

  return (
    <div ref={ref} className="permission-matrix" style={{ textAlign: "right" }}>
      <Table
        id="90c120ce-a280-467a-b6c2-b20e7658d05b"
        loading={loading}
        columns={filteredRows.length > 0 && filteredColumns}
        size="small"
        dataSource={filteredColumns.length > 2 && filteredRows}
        bordered
        pagination={false}
        style={{
          width: "calc(100vw - 400px)",
          overflowY: "visible",
        }}
        scroll={{
          x: true,
          y: false,
        }}
        sticky={{ offsetHeader: 100 }}
      />
    </div>
  );
};

export default DataTable;
