import {
    Alert,
    Autocomplete,
    Button,
    Grid,
    LinearProgress,
    Snackbar,
    Stack,
    TextField,
    FormHelperText,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import { useNavigate, useParams } from "react-router-dom";
  import { useSelector, useDispatch } from "react-redux";
  import {
    selectAllPermissionSetCategories,
    fetchPermissionSetCategories,
  } from "../../../redux/reducers/permissionSetCategorySlice";
  import {
    addNewFunctionalPermissionSet,
    fetchFunctionalPermissionSets,
    selectFunctionalPermissionSetByName,
    updateFunctionalPermissionSet,
  } from "../../../redux/reducers/functionalPermissionSetSlice";
  
  const Apps10ToAurenaUpdate = () => {};
  export default Apps10ToAurenaUpdate