
//---Local
//const BASE_URL = "https://localhost:44352";
//---Test
//const BASE_URL = "http://20.79.179.162:8021";
//---Sotax & Regofix
//const BASE_URL = "http://20.52.3.185:8021";
//---Tomra
//const BASE_URL = "https://tomra.backend.bitzify.com";
//---ZR
//const BASE_URL = "http://20.52.3.185:8025";
//const BASE_URL = "https://zr.backend.bitzify.com";
//---HIMA
//const BASE_URL = "http://20.52.3.185:8027";
const BASE_URL = "https://hima.backend.bitzify.com";
//---GAVAZZI
//const BASE_URL = "http://20.52.3.185:8029"; 


export const API_CONFIGURATIONS = {
  //Auth
  AUTH_USER: `${BASE_URL}/Login`,
  CONVERSION_AUTH_USER: `${BASE_URL}/Login/conversion`,

  //Category
  CATEGORY: `${BASE_URL}/Category`,

  //End-User-Roles
  END_USER_ROLES: `${BASE_URL}/EndUserPermissionSet`,
  ASSIGNED_END_USER_ROLES: `${BASE_URL}/EndUserPermissionSet/AssignedUsers?role=`,

  //Fuctional Permission Sets
  FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/FunctionalPermissionSet`,
  ASSIGNED_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/FunctionalPermissionSet/AssignEndUserRoles?role=`,

  //Function-End-User-Roles
  FUNCTION_TO_END_USER_ROLES: `${BASE_URL}/PermissionSetMapping`,

  //End-User-Fuction
  END_USER_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToFuncMapping`,

  //Consolidations
  NAVIGATOR_CONSOLIDATIONS: `${BASE_URL}/PermissionSetMapping`,
  GRANTED_NAVIGATOR_CONSOLIDATIONS: `${BASE_URL}/PermissionSetMapping/GetGrantedPermissions`,

  //Navigator
  GET_PARENT_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping?parent=`,
  UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping`,
  BULK_UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/NavigatorEntries`,
  GET_PARENT_HIERARCHY_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/NavigatorHierarchy?parent=`,
  GET_PARENT_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES: `${BASE_URL}/EndUserToNavMapping/NavigatorEndUserHierarchy`,
  GET_OMITED_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES: `${BASE_URL}/EndUserToNavMapping/OmitedNavigatorEndUserHierarchy`,
  //Navigator
  GET_PARENT_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping?parent=`,
  UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping`,
  BULK_UPDATE_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/NavigatorEntries`,
  GET_PARENT_HIERARCHY_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/NavigatorHierarchy?parent=`,
  GET_PARENT_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES: `${BASE_URL}/EndUserToNavMapping/NavigatorEndUserHierarchy`,
  GET_OMITED_HIERARCHY_NAVIGATOR_TO_END_USER_ROLES: `${BASE_URL}/EndUserToNavMapping/OmitedNavigatorEndUserHierarchy`,
  GET_OMITED_HIERARCHY_NAVIGATOR_TO_FUNCTIONAL_PERMISSION_SETS: `${BASE_URL}/EndUserToNavMapping/OmitedNavigatorHierarchy`,

  //User-Group
  USER_GROUPS: `${BASE_URL}/UserGroup`,

  //User
  USER: `${BASE_URL}/User`,
  USER_COUNT: `${BASE_URL}/User/UserCount`,

  //User-End-User
  USER_TO_END_USER_ROLES: `${BASE_URL}/UserGrantMapping`,

  //APP10 To Aurena Conversion
  APPS10_CONVERSION: `${BASE_URL}/Apps10Conversion`,
  APPS10_DATA_PUSH_TO_CLOUD: `${BASE_URL}/Apps10Conversion/SyncDataOnCloud`,
  EXISTING_APPS10_DATA_LOG: `${BASE_URL}/Apps10Conversion/existingApps10DataLog`,
  EXISTING_CLOUD_DATA_LOG: `${BASE_URL}/Apps10Conversion/existingCloudDataLog`,

  //Functional Areas
  FUNCTIONAL_AREAS: `${BASE_URL}/FunctionalAreas`,
  ASSIGNED_CONFLICTS: `${BASE_URL}/FunctionalAreas/AssignConflicts?area=`,
  //Navigator-Functional Areas
  GET_PARENT_NAVIGATOR_TO_FUNCTIONAL_AREAS: `${BASE_URL}/FunctionalAreasToNavMapping?parent=`,
  UPDATE_NAVIGATOR_TO_FUNCTIONAL_AREAS: `${BASE_URL}/FunctionalAreasToNavMapping`,

  //Functional Area Conflicts
  FUNCTIONAL_AREA_CONFLICTS: `${BASE_URL}/FunctionalAreaConflict`,
  SEG_DUTIES: `${BASE_URL}/FunctionalAreaConflict/SegDuties`,
};