import { Search, Close as CloseIcon } from "@mui/icons-material";
import { Grid, TextField, InputAdornment, Button ,Chip, Autocomplete, FormControl } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { fetchExistingApps10DataLog } from "../../../redux/reducers/app10ConversionSlice";
import { useSelector, useDispatch } from "react-redux";

// const getFilteredData = (data, filter) => {
//     return (filter && filter.length > 0) ?
//         data.filter(data => data.status && data.status.length > 0 && data.status.toLowerCase().includes(filter.toLowerCase())) : data;
// }

const Apps10DataTable = ({ columns, data = [], loading, setSelectedRows,isLoggedIn ,permissionSetCategories, functionalPermissionSets, endUserRoles}) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState(""); 
    const [selectAll, setSelectAll] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }; 

    const getFilteredData = (data, filter, categoryFilter) => { 
        let filteredData = data;

        filteredData = filteredData.map(item => {
            const funRole = functionalPermissionSets;
            const endRole = endUserRoles;
            const combinedRoles = [...funRole, ...endRole];
  
            const category = combinedRoles.find((role) => role.name === item.role)?.category;
   
            return {
              ...item,
              category: category,
            };
        });
     
        if (filter && filter.length > 0) {
            filteredData = filteredData.filter(item => 
                item.role && item.role.toLowerCase().includes(filter.toLowerCase())
            );
        }

        if (categoryFilter && categoryFilter.length > 0) {  
           filteredData = filteredData.filter((role) => role.category === categoryFilter); 
        } 
 
        return filteredData;
  
    }

    const filteredData = getFilteredData(data, filter, categoryFilter);
 
    // Near future will set as Enums to 'CONVERSION_SUCCESS.' ---
    const selectableRowIds = filteredData.filter(row => row.status === 'CONVERSION_SUCCESS').map(row => row.id);

    const handleSelectionChange = (newSelectionModel) => {
        // Filter out IDs that are not selectable ---
        const filteredSelectionModel = newSelectionModel.filter(id => selectableRowIds.includes(id));

        setSelectionModel(filteredSelectionModel);

        // Check if all selectable rows are selected ---
        const allRowsSelected = filteredSelectionModel.length === selectableRowIds.length;
        setSelectAll(allRowsSelected);

        const selectedRowData = filteredData.filter(row => filteredSelectionModel.includes(row.id));
        setSelectedRows(selectedRowData);
    };

    const handleSelectAllClick = () => {
        if (selectAll) {
            setSelectionModel([]);
        } else {
            setSelectionModel(selectableRowIds);
        }
        setSelectAll(!selectAll);
        const selectedRowData = filteredData.filter(row => selectableRowIds.includes(row.id));
        setSelectedRows(selectAll ? [] : selectedRowData);
    };

    const isRowSelectable = (params) => {
        // Near future will set as Enums to 'CONVERSION_SUCCESS.' ---
        return params.row.status === 'CONVERSION_SUCCESS';
    };

    const handleExistingApps10DataLog = () => { 
        dispatch(fetchExistingApps10DataLog());  
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item lg={6} md={2} sm={12} xs={12}>
                    <TextField
                        id="458a3fc3-14aa-4702-89f3-a94838fe40cc"
                        size="small"
                        fullWidth
                        label="Search by Permission Set"
                        onChange={(e) => setFilter(e.target.value)}
                        onInput={toInputUppercase}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search sx={{ color: "#000000" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item lg={4}>
                    <FormControl fullWidth size="small">
                        <Autocomplete
                            id="5c550df8-3c65-4ecf-b391-bc3447096e0d"
                            size="small"
                            options={permissionSetCategories.map((category) => category.name)}
                            value={categoryFilter}
                            onChange={(event, newValue) => setCategoryFilter(newValue)}
                            renderTags={(value, getTagProps) =>
                                value && (
                                    <Chip
                                        label={value}
                                        {...getTagProps({ index: 0 })}
                                        deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                                    />
                                )
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search by Category"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                    <Button 
                        onClick={() => handleExistingApps10DataLog()}
                        fullWidth
                        disableElevation
                        variant="outlined" 
                        disabled={!isLoggedIn && !(localStorage.getItem("conversionUserToken"))}
                        >
                        Load Previous Apps10 Data
                    </Button>
                </Grid>
            </Grid>

            <DataGrid
                columns={columns}
                rows={filteredData}
                sx={{ marginTop: 1 }}
                autoHeight
                //checkboxSelection
                pageSize={10}
                loading={loading === "loading"}
                getRowId={(row) => row.id}
                rowsPerPageOptions={[5]}
                onSelectionModelChange={handleSelectionChange}
                isRowSelectable={isRowSelectable}
                selectionModel={selectionModel}
                onSelectionCheckboxChange={handleSelectAllClick} 
            />
        </div>
    );
}

export default Apps10DataTable;
