import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import axios from 'axios';
import swal from "sweetalert2";
import { API_CONFIGURATIONS } from "../apiConfigurations";

let baseUrl = API_CONFIGURATIONS.FUNCTIONAL_AREAS;
let conflictUrl = API_CONFIGURATIONS.ASSIGNED_CONFLICTS;
const apiEndpoint = baseUrl;
const accessToken = localStorage.getItem("userToken");

let config = {
    mode: 'no-cors',
    headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'xAccessToken': accessToken
    }
}

export const initialState = {
    functionalAreas: [],
    status: 'idle',
    error: null
}

export const fetchFunctionalAreas = createAsyncThunk('functionalAreas/fetchFunctionalAreas', async() => {
    const response = await axios.get(apiEndpoint, config)
    return response.data;
})

export const addNewFunctionalArea = createAsyncThunk('functionalAreas/addNewFunctionalArea', async({ data, showStatus }) => {
    try {
        const response = await axios.post(apiEndpoint, data, config);
        if (response.status === 200) {
            showStatus({ open: true, severity: "success", message: "Functional Area added successfully" })
            return response.data;
        } else {
            showStatus({ open: true, severity: "error", message: "Functional Area adding failed" })
        }
    } catch (error) {
        swal.fire({
            title: 'This functional area already exists',
            text: "The functional area you're trying to create already exists in the system. Please choose a different name for your functional area.",
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d33',
        });

        throw error;
    }
});

export const updateFunctionalArea = createAsyncThunk('functionalAreas/updateFunctionalArea', async({ data, showStatus }) => {
    const response = await axios.put(apiEndpoint, data, config);
    if (response.status === 200) {
        showStatus({ open: true, severity: "success", message: "Functional Area updated successfully" })
    } else {
        showStatus({ open: true, severity: "error", message: "Functional Area update failed" })
    }
    return response.data;
})

export const deleteFunctionalArea = createAsyncThunk('functionalAreas/deleteFunctionalArea', async({ id, showStatus }) => {
    const response = await axios.delete(`${apiEndpoint}/?FunctionalAreaId=${id}`, config);
    if (response.status === 200) {
        showStatus({ open: true, severity: "success", message: "Fuctional Area deleted successfully" })
    } else {
        showStatus({ open: true, severity: "error", message: "Fuctional Area delete failed" })
    }
    return response.data;
})

export const fetchAssignedConflicts = createAsyncThunk('functionalAreas/fetchAssignedConflicts', async(record) => {
    const childUrl = conflictUrl + record;
    const response = await axios.get(childUrl, config);

    return response.data;
})

export const selectFunctionalAreaByName = (state, areaName) => {
    const area = state.functionalAreas.functionalAreas.find(area => area.name === areaName);
    return area
}

const functionalAreasSlice = createSlice({
    name: 'functionalAreas',
    initialState,
    reducers: {
        addFunctionalAreas: {
            reducer(state, action) {
                state.functionalAreas.push(action.payload);
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFunctionalAreas.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchFunctionalAreas.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.functionalAreas = action.payload
            })
            .addCase(fetchFunctionalAreas.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addNewFunctionalArea.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(addNewFunctionalArea.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.functionalAreas = state.functionalAreas
                    .concat(...action.payload)
                    .sort((a, b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
            })
            .addCase(addNewFunctionalArea.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateFunctionalArea.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateFunctionalArea.fulfilled, (state, action) => {
                state.status = 'succeeded'
                const areas = [...current(state.functionalAreas)]
                const index = areas.findIndex(perm => perm.name === action.payload[0].name);
                areas[index] = action.payload[0];
                state.functionalAreas = areas;
            })
            .addCase(updateFunctionalArea.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deleteFunctionalArea.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(deleteFunctionalArea.fulfilled, (state, action) => {
                state.status = 'succeeded'
            })
            .addCase(deleteFunctionalArea.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            }).addCase(fetchAssignedConflicts.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchAssignedConflicts.fulfilled, (state, action) => {
                state.status = 'succeeded' 
            })
            .addCase(fetchAssignedConflicts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
});

export default functionalAreasSlice.reducer

export const selectAllFunctionalAreas = state => state.functionalAreas.functionalAreas;

export const selectFunctionalAreasById = (state, id) =>
    state.functionalAreas.functionalAreas.find(functionalArea => functionalArea.id === id);