import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  CircularProgress,
  LinearProgress,
  Typography,
  Card,
  Checkbox,
  Chip, 
  Menu, 
  ListItemText,
} from "@mui/material";
import {
  selectAllUsers,
  fetchUsers,
} from "../../../../redux/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Search, ExitToApp } from "@mui/icons-material";
import {
  fetchNavigatorToFunctionalPermissionSets,
  selectAllNavigatorToFunctionalPermissionSets,
  fetchNavigatorHierarchy,
  fetchNavigatorEndUserBreakdownHierarchy,
  fetchOmitedNavigatorEndUserBreakdownHierarchy,
} from "../../../../redux/reducers/navigatorToFunctionalPermissionSetsMultiSlice";
import {
  fetchFunctionalPermissionSets,
  selectAllFunctionalPermissionSets,
} from "../../../../redux/reducers/functionalPermissionSetSlice";
import {
  fetchEndUserRoles,
  selectAllEndUserRoles,
} from "../../../../redux/reducers/endUserRoleSlice";
import ExcelJS from "exceljs";
var XLSX = require("xlsx");

const EndUserRoleBreakDownListView = () => {
  const dispatch = useDispatch();
  const navigatorToFunctionalPermissionSetsStatus = useSelector(
    (state) => state.navigatorToFunctionalPermissionSetsMulti.status
  );
  const navigatorToFunctionalPermissionSets = useSelector(
    selectAllNavigatorToFunctionalPermissionSets
  );
  const functionalPermissionSetStatus = useSelector(
    (state) => state.functionalPermissionSets.status
  );
  const functionalPermissionSets = useSelector(
    selectAllFunctionalPermissionSets
  );
  const endUserRolesStatus = useSelector((state) => state.endUserRoles.status);
  const endUserRoles = useSelector(selectAllEndUserRoles);

  const [navigatorId, setNavigatorId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [omitNonGrantedAreas, setOmitNonGrantedAreas] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        dispatch(fetchNavigatorToFunctionalPermissionSets()),
        dispatch(fetchFunctionalPermissionSets()),
        dispatch(fetchEndUserRoles()),
      ]);
      setLoading(false);
    };

    if (
      navigatorToFunctionalPermissionSetsStatus === "idle" ||
      functionalPermissionSetStatus === "idle" ||
      endUserRolesStatus === "idle"
    ) {
      fetchData();
    }
  }, [
    navigatorToFunctionalPermissionSetsStatus,
    functionalPermissionSetStatus,
    endUserRolesStatus,
    dispatch,
  ]);

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const simulateAsyncOperation = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  const getAllNavDataHierarchy = (record) => {
    setExporting(true);
    setProgressValue(10);
    if (omitNonGrantedAreas) {
      dispatch(fetchOmitedNavigatorEndUserBreakdownHierarchy(record)).then(
        async (res) => {
          const navchildData = res.payload;

          let progress = 10;
          while (progress < 100) {
            await simulateAsyncOperation();
            progress += 10;
            setProgressValue(progress);
          }

          handleOnExport(navchildData);

          setProgressValue(100);
          setExporting(false);
        }
      );
    } else {
      dispatch(fetchNavigatorEndUserBreakdownHierarchy(record)).then(
        async (res) => {
          const navchildData = res.payload;

          let progress = 10;
          while (progress < 100) {
            await simulateAsyncOperation();
            progress += 10;
            setProgressValue(progress);
          }

          handleOnExport(navchildData);

          setProgressValue(100);
          setExporting(false);
        }
      );
    }
  };

  const handleOnExport = async (navData) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("End-User Role Breakdown Report");

    const dataRows = [];
    const colorData = []; // Array to store color information

    // Create a palette of light purple and pink shades
    const levelColors = [
      "FFEDD2FA", // Light Pinkish Purple
      "FFDBA3FA", // Pink Shade 1 (brighter pink)
      "FFE8C5FF", // Pink Shade 2 (soft lavender)
      "FFD7C8F9", // Light Purple 1 (medium purple)
      "FFC5A8FA", // Light Purple 2 (brighter lavender)
      "FFEABFFF", // Light Pinkish Purple (slightly bolder)
      "FFBFA1FB", // Stronger Purple
      "FFD59FFF", // Light Purple Pink Mix (higher contrast)
    ];


    const processItem = (item, level = 0) => {
      const row = [item.label];
      const colors = []; // Array to store colors for this row

      navigatorId.forEach((navId) => {
        const state = item?.state?.find(
          (state) =>
            state?.grantee === navId &&
            state?.id === item.id &&
            (state?.state === "1" || state?.state === "0")
        );

        let grantType = "";
        let grantColor = "";

        if (state) {
          grantType = state.state === "0" ? "◉" : state.state === "1" ? "✓" : "";
          grantColor =
            state.stateColor === "F" ? "RW" : state.stateColor === "R" ? "R" : "";
        }

        row.push(grantType); // Push grantType to the row
        colors.push(grantColor); // Store grantColor in the colors array
      });

      dataRows.push({ level, row });
      colorData.push(colors); // Store colors for this row

      if (item.child && item.child.length > 0) {
        item.child.forEach((childItem) => {
          processItem(childItem, level + 1);
        });
      }
    };

    navData.forEach((item) => processItem(item));

    // Set title cell
    const titleCell = sheet.getCell("A1");
    titleCell.value = "Permission Matrix - Navigator <--> End User Roles";
    titleCell.alignment = {
      horizontal: "left",
      vertical: "middle",
    };
    titleCell.font = {
      size: 10,
      bold: true,
      name: "Arial",
      color: { argb: "FF000000" },
    };

    // Set header row
    const headerRow = sheet.addRow(["Navigator Structure", ...navigatorId]);
    headerRow.height = 200;

    const firstHeaderCell = headerRow.getCell(1);
    firstHeaderCell.alignment = {
      vertical: "top",
      horizontal: "left",
    };
    firstHeaderCell.font = {
      bold: true,
    };
    firstHeaderCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE0E0E0" },
    };
    firstHeaderCell.border = {
      top: { style: "thin", color: { argb: "FF000000" } },
      bottom: { style: "thin", color: { argb: "FF000000" } },
      left: { style: "thin", color: { argb: "FF000000" } },
      right: { style: "thin", color: { argb: "FF000000" } },
    };

    navigatorId.forEach((navId, index) => {
      const navigatorIdHeaderCell = headerRow.getCell(index + 2);
      navigatorIdHeaderCell.alignment = {
        textRotation: 90,
        vertical: "bottom",
        horizontal: "center",
      };
      navigatorIdHeaderCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFAB89C4" },
        //fgColor: { argb: "FF9BBB59" },
      };
      navigatorIdHeaderCell.font = {
        bold: false,
      };
      navigatorIdHeaderCell.border = {
        top: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
        left: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
      sheet.getColumn(index + 2).width = 5;
    });

    // Add data rows with indentation and dynamic color coding for hierarchical levels
    dataRows.forEach(({ level, row }, rowIndex) => {
      const indentedRow = row.map((cell, index) => {
        if (index === 0) {
          return " ".repeat(level * 4) + cell;
        }
        return cell; // No need to filter cells here
      });

      const excelRow = sheet.addRow(indentedRow);

      // Apply the dynamic purple-pink mix color based on the level
      const rowColor = levelColors[level % levelColors.length]; // Rotate through the colors, ensuring no repetition in the same navigator

      excelRow.eachCell((cell, colNumber) => {
        if (colNumber === 1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: rowColor },
          };
        }
      });

      // Collapse/expand grouping based on levels
      if (level > 0) {
        sheet.getRow(rowIndex + 3).outlineLevel = level;
        sheet.getRow(rowIndex + 3).hidden = level > 0; // Initially collapse all except level 0
      }
    });

    // Apply icons and background colors
    for (let r = 3; r <= dataRows.length + 2; r++) {
      navigatorId.forEach((navId, index) => {
        const cellRef = sheet.getCell(r, index + 2);
        const grantType = dataRows[r - 3].row[index * 2 + 1];
        const grantColor = colorData[r - 3][index]; // Get the color for this cell

        if (grantType === "◉" || grantType === "✓") {
          cellRef.value = grantType;
          cellRef.alignment = { horizontal: "center", vertical: "middle" };
          cellRef.font = {
            color: { argb: "FFFFFFFF" }, // Set icon color to white
          };
          cellRef.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: grantColor === "R" ? "FF4461D4" : "FF000000", // Use the color logic you specified
            },
          };
          cellRef.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
        } else {
          cellRef.value = ""; // Ensure no unintended values
        }
      });
    }

    // Add borders between levels
    sheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: { style: "thin" },
        };
      });
    });

    // Enable grouping and collapse/expand in Excel
    sheet.properties.outlineLevelRow = 2; // Allow collapse/expand to level 2
    sheet.getColumn(1).width = 60;

    // Generate and download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "End_User_Role_Breakdown.xlsx";
    link.click();
  };

  //with level styles
  // const handleOnExport = async (navData) => {
  //   const workbook = new ExcelJS.Workbook();
  //   const sheet = workbook.addWorksheet("End-User Role Breakdown");

  //   const dataRows = [];
  //   const colorData = []; // Array to store color information

  //   const processItem = (item, level = 0) => {
  //     const row = [item.label];
  //     const colors = []; // Array to store colors for this row

  //     navigatorId.forEach((navId) => {
  //       const state = item?.state?.find(
  //         (state) =>
  //           state?.grantee === navId &&
  //           state?.id === item.id &&
  //           (state?.state === "1" || state?.state === "0")
  //       );

  //       let grantType = "";
  //       let grantColor = "";

  //       if (state) {
  //         grantType =
  //           state.state === "0" ? "◉" : state.state === "1" ? "✓" : "";
  //         grantColor =
  //           state.stateColor === "F"
  //             ? "RW"
  //             : state.stateColor === "R"
  //             ? "R"
  //             : "";
  //       }

  //       row.push(grantType); // Push grantType to the row
  //       colors.push(grantColor); // Store grantColor in the colors array
  //     });

  //     dataRows.push({ level, row });
  //     colorData.push(colors); // Store colors for this row

  //     if (item.child && item.child.length > 0) {
  //       item.child.forEach((childItem) => {
  //         processItem(childItem, level + 1);
  //       });
  //     }
  //   };

  //   navData.forEach((item) => processItem(item));

  //   // Set title cell
  //   const titleCell = sheet.getCell("A1");
  //   titleCell.value = "Permission Matrix - Navigator <--> End User Roles";
  //   titleCell.alignment = {
  //     horizontal: "left",
  //     vertical: "middle",
  //   };
  //   titleCell.font = {
  //     size: 10,
  //     bold: true,
  //     name: "Arial",
  //     color: { argb: "FF000000" },
  //   };

  //   // Set header row
  //   const headerRow = sheet.addRow(["Navigator Structure", ...navigatorId]);
  //   headerRow.height = 200;

  //   const firstHeaderCell = headerRow.getCell(1);
  //   firstHeaderCell.alignment = {
  //     vertical: "top",
  //     horizontal: "left",
  //   };
  //   firstHeaderCell.font = {
  //     bold: true,
  //   };
  //   firstHeaderCell.fill = {
  //     type: "pattern",
  //     pattern: "solid",
  //     fgColor: { argb: "FFE0E0E0" },
  //   };
  //   firstHeaderCell.border = {
  //     top: { style: "thin", color: { argb: "FF000000" } },
  //     bottom: { style: "thin", color: { argb: "FF000000" } },
  //     left: { style: "thin", color: { argb: "FF000000" } },
  //     right: { style: "thin", color: { argb: "FF000000" } },
  //   };

  //   navigatorId.forEach((navId, index) => {
  //     const navigatorIdHeaderCell = headerRow.getCell(index + 2);
  //     navigatorIdHeaderCell.alignment = {
  //       textRotation: 90,
  //       vertical: "bottom",
  //       horizontal: "center",
  //     };
  //     navigatorIdHeaderCell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FF9BBB59" },
  //     };
  //     navigatorIdHeaderCell.font = {
  //       bold: false,
  //     };
  //     navigatorIdHeaderCell.border = {
  //       top: { style: "thin", color: { argb: "FF000000" } },
  //       bottom: { style: "thin", color: { argb: "FF000000" } },
  //       left: { style: "thin", color: { argb: "FF000000" } },
  //       right: { style: "thin", color: { argb: "FF000000" } },
  //     };
  //     sheet.getColumn(index + 2).width = 5;
  //   });

  //   // Add data rows with indentation and color coding for hierarchical levels
  //   dataRows.forEach(({ level, row }, rowIndex) => {
  //     const indentedRow = row.map((cell, index) => {
  //       if (index === 0) {
  //         return " ".repeat(level * 4) + cell;
  //       }
  //       return cell; // No need to filter cells here
  //     });

  //     const excelRow = sheet.addRow(indentedRow);

  //     // Apply color coding for different levels
  //     const rowColor =
  //       level === 0
  //         ? "FFFFCC99" // Level 0 (Parent)
  //         : level === 1
  //         ? "FFCCFFCC" // Level 1 (Child)
  //         : level === 2
  //         ? "FF99CCFF" // Level 2 (Grandchild)
  //         : "FFE6E6E6"; // Default color for deeper levels

  //     excelRow.eachCell((cell, colNumber) => {
  //       if (colNumber === 1) {
  //         cell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: rowColor },
  //         };
  //       }
  //     });

  //     // Collapse/expand grouping based on levels
  //     if (level > 0) {
  //       sheet.getRow(rowIndex + 3).outlineLevel = level;
  //       sheet.getRow(rowIndex + 3).hidden = level > 0; // Initially collapse all except level 0
  //     }
  //   });

  //   // Apply icons and background colors
  //   for (let r = 3; r <= dataRows.length + 2; r++) {
  //     navigatorId.forEach((navId, index) => {
  //       const cellRef = sheet.getCell(r, index + 2);
  //       const grantType = dataRows[r - 3].row[index * 2 + 1];
  //       const grantColor = colorData[r - 3][index]; // Get the color for this cell

  //       if (grantType === "◉" || grantType === "✓") {
  //         cellRef.value = grantType;
  //         cellRef.alignment = { horizontal: "center", vertical: "middle" };
  //         cellRef.font = {
  //           color: { argb: "FFFFFFFF" }, // Set icon color to white
  //         };
  //         cellRef.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: {
  //             argb: grantColor === "R" ? "FF4461D4" : "FF000000", // Use the color logic you specified
  //           },
  //         };
  //         cellRef.border = {
  //           top: { style: "thin", color: { argb: "FF000000" } },
  //           bottom: { style: "thin", color: { argb: "FF000000" } },
  //           left: { style: "thin", color: { argb: "FF000000" } },
  //           right: { style: "thin", color: { argb: "FF000000" } },
  //         };
  //       } else {
  //         cellRef.value = ""; // Ensure no unintended values
  //       }
  //     });
  //   }

  //   // Enable grouping and collapse/expand in Excel
  //   sheet.properties.outlineLevelRow = 2; // Allow collapse/expand to level 2
  //   sheet.getColumn(1).width = 60;

  //   // Generate and download the Excel file
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   const blob = new Blob([buffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });
  //   const url = URL.createObjectURL(blob);

  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "End_User_Role_Breakdown.xlsx";
  //   link.click();
  // };

  //without level styles
  // const handleOnExport = async (navData) => {
//   const workbook = new ExcelJS.Workbook();
//   const sheet = workbook.addWorksheet("End-User Role Breakdown");

//   const dataRows = [];
//   const colorData = []; // Array to store color information

//   const processItem = (item, level = 0) => {
//     const row = [item.label];
//     const colors = []; // Array to store colors for this row

//     navigatorId.forEach((navId) => {
//       const state = item?.state?.find(
//         (state) =>
//           state?.grantee === navId &&
//           state?.id === item.id &&
//           (state?.state === "1" || state?.state === "0")
//       );

//       let grantType = "";
//       let grantColor = "";

//       if (state) {
//         grantType = state.state === "0" ? "◉" : state.state === "1" ? "✓" : "";
//         grantColor =
//           state.stateColor === "F" ? "RW" : state.stateColor === "R" ? "R" : "";
//       }

//       row.push(grantType); // Push grantType to the row
//       colors.push(grantColor); // Store grantColor in the colors array
//     });

//     dataRows.push({ level, row });
//     colorData.push(colors); // Store colors for this row

//     if (item.child && item.child.length > 0) {
//       item.child.forEach((childItem) => {
//         processItem(childItem, level + 1);
//       });
//     }
//   };

//   navData.forEach((item) => processItem(item));

//   // Set title cell
//   const titleCell = sheet.getCell("A1");
//   titleCell.value = "Permission Matrix - Navigator <--> End User Roles";
//   titleCell.alignment = {
//     horizontal: "left",
//     vertical: "middle",
//   };
//   titleCell.font = {
//     size: 10,
//     bold: true,
//     name: "Arial",
//     color: { argb: "FF000000" },
//   };

//   // Set header row
//   const headerRow = sheet.addRow(["Navigator Structure", ...navigatorId]);
//   headerRow.height = 200;

//   const firstHeaderCell = headerRow.getCell(1);
//   firstHeaderCell.alignment = {
//     vertical: "top",
//     horizontal: "left",
//   };
//   firstHeaderCell.font = {
//     bold: true,
//   };
//   firstHeaderCell.fill = {
//     type: "pattern",
//     pattern: "solid",
//     fgColor: { argb: "FFE0E0E0" },
//   };
//   firstHeaderCell.border = {
//     top: { style: "thin", color: { argb: "FF000000" } },
//     bottom: { style: "thin", color: { argb: "FF000000" } },
//     left: { style: "thin", color: { argb: "FF000000" } },
//     right: { style: "thin", color: { argb: "FF000000" } },
//   };

//   navigatorId.forEach((navId, index) => {
//     const navigatorIdHeaderCell = headerRow.getCell(index + 2);
//     navigatorIdHeaderCell.alignment = {
//       textRotation: 90,
//       vertical: "bottom",
//       horizontal: "center",
//     };
//     navigatorIdHeaderCell.fill = {
//       type: "pattern",
//       pattern: "solid",
//       fgColor: { argb: "FF9BBB59" },
//     };
//     navigatorIdHeaderCell.font = {
//       bold: false,
//     };
//     navigatorIdHeaderCell.border = {
//       top: { style: "thin", color: { argb: "FF000000" } },
//       bottom: { style: "thin", color: { argb: "FF000000" } },
//       left: { style: "thin", color: { argb: "FF000000" } },
//       right: { style: "thin", color: { argb: "FF000000" } },
//     };
//     sheet.getColumn(index + 2).width = 5;
//   });

//   // Add data rows with indentation
//   dataRows.forEach(({ level, row }) => {
//     const indentedRow = row.map((cell, index) => {
//       if (index === 0) {
//         return " ".repeat(level * 4) + cell;
//       }
//       return cell; // No need to filter cells here
//     });
//     sheet.addRow(indentedRow);
//   });

//   // Apply icons and background colors
//   for (let r = 3; r <= dataRows.length + 2; r++) {
//     navigatorId.forEach((navId, index) => {
//       const cellRef = sheet.getCell(r, index + 2);
//       const grantType = dataRows[r - 3].row[index * 2 + 1];
//       const grantColor = colorData[r - 3][index]; // Get the color for this cell

//       if (grantType === "◉" || grantType === "✓") {
//         cellRef.value = grantType;
//         cellRef.alignment = { horizontal: "center", vertical: "middle" };
//         cellRef.font = {
//           color: { argb: "FFFFFFFF" }, // Set icon color to white
//         };
//         cellRef.fill = {
//           type: "pattern",
//           pattern: "solid",
//           fgColor: {
//             argb: grantColor === "R" ? "FF4461D4" : "FF000000", // Use the color logic you specified
//           },
//         };
//         cellRef.border = {
//           top: { style: "thin", color: { argb: "FF000000" } },
//           bottom: { style: "thin", color: { argb: "FF000000" } },
//           left: { style: "thin", color: { argb: "FF000000" } },
//           right: { style: "thin", color: { argb: "FF000000" } },
//         };
//       } else {
//         cellRef.value = ""; // Ensure no unintended values
//         // cellRef.fill = {
//         //   type: "pattern",
//         //   pattern: "solid",
//         //   fgColor: { argb: "FFFFFFFF" }, // Default background color
//         // };
//       }
//     });
//   }

//   sheet.getColumn(1).width = 60;

//   // Generate and download the Excel file
//   const buffer = await workbook.xlsx.writeBuffer();
//   const blob = new Blob([buffer], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });
//   const url = URL.createObjectURL(blob);

//   const link = document.createElement("a");
//   link.href = url;
//   link.download = "End_User_Role_Breakdown.xlsx";
//   link.click();
// };


  return (
    <Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
        <h2 className="page-title">End-User Roles Breakdown Reports.</h2>
      </Grid>
      <br></br>
      <br></br>
      <Grid container spacing={2}>
        {/* <Grid item lg={4}>
          <FormControl fullWidth size="small">
            <InputLabel id="func-cat-label">End-User Role</InputLabel>
            <Select
              //multiple
              fullWidth
              label="Reports by Navigator Entries"
              labelId="func-cat-label"
              input={<OutlinedInput label="Reports by Navigator Entries" />}
              style={{ textAlign: "left" }}
              onChange={(e) => {
                setNavigatorId(e.target.value);
              }}
            >
              {endUserRoles &&
                endUserRoles.map((category) => (
                  <MenuItem key={category.name} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item lg={8}>
          <FormControl fullWidth size="small">
            <InputLabel id="func-cat-label">End-User Roles</InputLabel>
            <Select
              id="afeca31d-4b6a-47d2-909e-44becd4606eb"
              fullWidth
              label="Reports by Navigator Entries"
              labelId="func-cat-label"
              input={<OutlinedInput label="Reports by Navigator Entries" />}
              style={{ textAlign: "left" }}
              multiple
              value={navigatorId}
              onChange={(e) => {
                setNavigatorId(e.target.value);
              }}
              renderValue={(selected) => {
                return (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        style={{ margin: "2px" }}
                      />
                    ))}
                  </div>
                );
              }}
              MenuComponent={(props) => (
                <Menu
                  elevation={0}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: Math.min(
                        200,
                        Math.ceil(navigatorId.length / 4) * 40
                      ), 
                    },
                  }}
                  {...props}
                />
              )}
            >
              {endUserRoles &&
                endUserRoles.map((category) => (
                  <MenuItem key={category.name} value={category.name}>
                    <Checkbox checked={navigatorId.includes(category.name)} />
                    <ListItemText primary={category.name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={4} sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            id="6f1b5412-08f2-46bd-b98d-49403005f08d"
            name="omitNonGrantedAreas"
            onChange={(e) => {
              setOmitNonGrantedAreas(e.target.checked);
            }}
          />
          <InputLabel sx={{ color: "black", textAlign: "left", marginLeft: 1 }}>
            Omit Non-Granted Areas
          </InputLabel>
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <Card sx={{ backgroundColor: "#D3D3D3", padding: "16px" }}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={8} sm={12} xs={12}>
            <InputLabel sx={{ color: "black", textAlign: "left" }}>
              ✔ End-User Roles Breakdown
            </InputLabel>
          </Grid>
          <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
            <Button
              id="5df93265-eb56-4698-a265-0ce506ddb6d0"
              fullWidth
              onClick={() => {
                 getAllNavDataHierarchy(navigatorId);
                // handleOnExport();
                 
              }}
              disableElevation
              variant="contained"
              startIcon={<ExitToApp />}
              disabled={(navigatorId.length===0)}
            >
              Export
            </Button>
          </Grid>
          <Grid item lg={7} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
            {exporting && (
              <div style={{ width: "100%", marginTop: "20px", height: "20%" }}>
                <LinearProgress
                  id="fbab8491-404b-4e58-8eaa-b34e92bdc8da"
                  variant="determinate"
                  value={progressValue > 100 ? 100 : progressValue}
                  sx={{ height: 8 }}
                />
                <Typography variant="body2">{`${parseInt(
                  progressValue > 100 ? 100 : progressValue + ""
                )}%`}</Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Grid>
  );
};

export default EndUserRoleBreakDownListView;
