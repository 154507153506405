import * as React from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Backdrop,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { updateMappings } from "../../redux/reducers/usersToEndUserRoleSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

const UpdateConfirmationDialog = ({
  setOpenConfirmation,
  openConfirmation = false,
  handleConfirmationClose,
  type,
  updatedData = [],
  handleClose,
  handleCancel,
}) => {
  const dispatch = useDispatch();
  const [isUpdating, setUpdating] = useState(false);
  const [showSavePopUp, setSavePopUp] = useState(true);

  const handleCloseWithSave = () => {
    setUpdating(false);
    handleClose();
  };

  const handleCloseWithCancel = () => {
    setOpenConfirmation(false);
  };

  const renderChanges = () => {
    const structure = [];
    updatedData.length > 0 &&
      updatedData.map((data) => {
        structure.push(
          <h4 style={{ marginTop: 15, marginBottom: 5 }}>{data.user}</h4>
        );
        data.userGrantsAdd &&
          data.userGrantsAdd.forEach((permission) =>
            structure.push(
              <Chip label={permission} size="small" color="success" />
            )
          );
        data.userGrantsDel &&
          data.userGrantsDel.forEach((permission) =>
            structure.push(
              <Chip label={permission} size="small" color="error" />
            )
          );
      });
    return structure;
  };

  const onClickSave = async () => {
    // console.log("isUpdating is 1", isUpdating);
    // setUpdating(true);
    setSavePopUp(false);
    // console.log("isUpdating is 2", isUpdating);
    handleClose();
    dispatch(updateMappings({ data: updatedData, closePopup: handleCloseWithSave }));
    // setUpdating(false); // Set isUpdating to true to show the Backdrop
    // console.log("isUpdating is 3", isUpdating);
  };

  return isUpdating ? (
    <>
      <Dialog open={openConfirmation} onClose={handleConfirmationClose}>
        {/* ... rest of your Dialog content */}
      </Dialog>
      <Backdrop
        id="fb5731ef-4cf2-4d64-a0c6-5735f10a4ff3"
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
        open={isUpdating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  ) : (
    <Dialog open={openConfirmation} onClose={handleConfirmationClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <div style={{ padding: "0px 25px" }}>{renderChanges()}</div>
      <DialogContent>
        <DialogContentText>
          {type === "save"
            ? "You want to save the changes you made?"
            : "Abort the changes you made?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="3294d67d-d102-459e-a02b-a68542347cbe" onClick={() => handleCloseWithCancel()}>Cancel</Button>
        <Button id="80cd55a7-2643-4127-bcdc-33fb37edb857" onClick={() => onClickSave()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateConfirmationDialog;
