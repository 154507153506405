import { Search } from "@mui/icons-material";
import { Grid, TextField, InputAdornment, Button, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

const getFilteredData = (data, filter) => {
  return filter && filter.length > 0
    ? data.filter(
        (data) =>
          data.name &&
          data.name.length > 0 &&
          data.name.toLowerCase().includes(filter.toLowerCase())
      )
    : data;
};

const DataTable = ({ columns, data = [], loading }) => {
  const [filter, setFilter] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const filteredData = getFilteredData(data, filter);

  // Function to calculate the page size based on available height
  const calculatePageSize = () => {
    const rowHeight = 52; // typical row height for DataGrid
    const headerHeight = 56; // height of DataGrid header
    const searchBarHeight = 64; // height of the search bar and button row
    const padding = 143; // extra padding
    const availableHeight =
      window.innerHeight - headerHeight - searchBarHeight - padding;
    return Math.floor(availableHeight / rowHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      setPageSize(calculatePageSize());
    };

    // Set initial page size
    handleResize();

    // Adjust page size on window resize
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box sx={{ padding: "0 16px" }}>
      {" "}
      {/* No margin between navbar and table */}
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={10} md={8} sm={12} xs={12}>
          <TextField
            id="44df8d00-6751-4d58-8c93-f274a47f174a"
            size="small"
            fullWidth
            label="Search by Name"
            onChange={(e) => setFilter(e.target.value)}
            onInput={toInputUppercase}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "#000000" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={12} xs={12} sx={{ textAlign: "right" }}>
          <Link className="custom-link" to="/permission-set-categories/new">
            <Button
              id="cc9e0950-d00b-469a-a9c0-39b7a48fa3c2"
              fullWidth
              disableElevation
              variant="contained"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 0, height: "calc(100vh - 200px)" }}>
        <DataGrid
          columns={columns}
          rows={filteredData}
          checkboxSelection={false}
          hideFooterPagination
          loading={loading === "loading"}
          getRowId={(row) => row.objkey}
          disableColumnMenu
          headerHeight={56}
          rowHeight={52}
          disableVirtualization
          sx={{
            border: "none",
          }}
        />
      </Box>
    </Box>
  );
};

export default DataTable;
