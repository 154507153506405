import { Stack, IconButton, Grid } from "@mui/material";
import { ModeEdit, ContentCopy, Delete } from "@mui/icons-material";
import DataTable from "./DataTable";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchFunctionalAreas,
  addNewFunctionalArea,
  updateFunctionalArea,
  deleteFunctionalArea,
  selectFunctionalAreaByName,
  selectAllFunctionalAreas,
  selectFunctionalAreasById,
  fetchAssignedConflicts,
} from "../../../redux/reducers/functionalAreasSlice";
import Swal from "sweetalert2";

const AreaView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const functionalAreas = useSelector(selectAllFunctionalAreas);
  const functionalAreaStatus = useSelector(
    (state) => state.functionalAreas.status
  );
  const [deleteActive, setDeleteActive] = useState();

  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem("previousPath", currentPath);
  }, [location.pathname]);

  useEffect(() => {
    if (functionalAreaStatus === "idle") {
      dispatch(fetchFunctionalAreas());
    }
  }, [functionalAreaStatus, dispatch]);

  const deleteArea = async (id) => {
    Swal.fire({
      title: "Delete Functional Area",
      text: "Are you sure you want to delete this Functional Area?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteConfirm(id);
      }
    });
  };

  const onDeleteConfirm = async (id) => {
    try {
      const conflictAction = dispatch(fetchAssignedConflicts(id));
      const conflictAssigned = await conflictAction;

      if (conflictAssigned?.payload === true) {
        await Swal.fire({
          title: "Cannot Delete Functional Area",
          text: "The Functional Area has already been assigned to one or more Functional Area Conflicts. Deletion is not allowed.",
          icon: "warning",
          iconColor: "red",
          confirmButtonText: "OK",
          confirmButtonColor: "red",
        });
      } else {
        const result = await Swal.fire({
          title: "Delete Functional Area",
          text: "Are you sure you want to delete this Functional Area?",
          icon: "warning",
          iconColor: "red",
          showCancelButton: true,
          cancelButtonText: "CANCEL",
          confirmButtonText: "CONFIRM DELETION",
          confirmButtonColor: "red",
        });

        if (result.isConfirmed) {
          await dispatch(deleteFunctionalArea({ id }));
          Swal.fire(
            "Deleted",
            "The Functional Area has been deleted.",
            "success"
          );
          dispatch(fetchFunctionalAreas());
        } else {
          Swal.fire(
            "Deletion Cancelled",
            "The Functional Area was not deleted.",
            "info"
          );
          dispatch(fetchFunctionalAreas());
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        "Error",
        "An error occurred while processing the request.",
        "error"
      );
      dispatch(fetchFunctionalAreas());
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 2 },
    { field: "description", headerName: "Description", flex: 3 },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (data) => {
        return (
          <div>
            <Stack direction="row" spacing={1}>
              {/* <Link className="custom-link" to={"/functional-area/duplicate/" + data.id}>
                                <IconButton color='primary' size="medium" sx={{ borderRadius: 2, border: '1px solid' }} variant="outlined"><ContentCopy sx={{ fontSize: 20 }} /></IconButton>
                            </Link> */}
              <Link className="custom-link" to={"/functional-area/" + data.id}>
                <IconButton
                  color="warning"
                  size="small"
                  sx={{ borderRadius: 1, border: "1px solid" }}
                  variant="outlined"
                >
                  <ModeEdit sx={{ fontSize: 16 }} />
                </IconButton>
              </Link>
              <IconButton
                onClick={() => deleteArea(data.id)}
                color="error"
                size="small"
                sx={{ borderRadius: 1, border: "1px solid" }}
              >
                <Delete sx={{ fontSize: 16 }} />
              </IconButton>
            </Stack>
          </div>
        );
      },
    },
  ];

  return (
    <Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "left" }}>
        <h2 className="page-title">Functional Areas</h2>
      </Grid>
      <DataTable
        columns={columns}
        data={functionalAreas}
        loading={functionalAreaStatus}
      />
    </Grid>
  );
};

export default AreaView;
